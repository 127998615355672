import PropTypes from 'prop-types'
import {Button, Icon} from 'semantic-ui-react'
import React from 'react'
import styled from 'styled-components'
import validator from 'validator'
import i18n from '../../../../i18n'

const ButtonSearch = styled(Button)`
    &:hover{
        padding-right: 40px !important;
    }
    width: 300px;
    background-color: #021041 !important;
    color: white !important;
    font-size: 15px !important;
    padding: 17px !important;
    border-radius: 25px !important;
    padding-left: 0 !important;
    transition: all .2s ease 0s !important;
`

const IconSubmit = styled(Icon)`
    color: white;
    opacity: 1 !important;
`

const CustomerSupportInputSubmit = ({  onClick, inputEmail, inputPhone, inputId}) => (
        <ButtonSearch
            animated="fade"
            size="big"
            type="submit"
            className="button-search-customer-id"
            onClick={onClick}
            disabled={
                !(validator.isEmail(inputEmail)
                    || validator.isUUID(inputId))
                && (!inputPhone
                    ? true
                    : inputPhone.length <= 11
                    || inputPhone.length === 0)}
        >
            {i18n.t(('customerSupport.homePage.homeForm.inputButton'))}
            <Button.Content
                hidden
                animated="fade"
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '45px',
                    top: '22px'
                }}
            >
                <IconSubmit className="hide-button-arrow" name="long arrow alternate right" size="large" />
            </Button.Content>
        </ButtonSearch>

    )



CustomerSupportInputSubmit.propTypes = {
  inputEmail: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  inputPhone: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default CustomerSupportInputSubmit

