import React from 'react'
import { Button, Header, Modal, Transition, Message } from 'semantic-ui-react'

class DeleteUserModal extends React.Component {
    render() {
        return (
            <Transition visible={this.props.visible} animation="fade up" duration={350}>
                <Modal
                    size={'tiny'}
                    open={this.props.visible}
                >
                    <Header icon="pencil" content={'Delete user'} />
                    <Modal.Content >
                        {
                            !!(this.props.formError && this.props.formError.serverError) && (
                                <Message negative attached>
                                    <Message.Header>{this.props.formError.serverError}</Message.Header>
                                </Message>
                            )
                        }
                        <p>Delete user <b>{this.props.userEmail}</b> ?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="mini" onClick={() => this.props.closeModal()}>
                            Back
                        </Button>
                        <Button
                            color="red"
                            size="mini"
                            loading={this.props.loadingBtn}
                            type="submit"
                            onClick={() => this.props.onSubmit()}
                        >
                            Delete
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>
        )
    }
}

export default DeleteUserModal
