import React, { Component } from 'react'
import { Loader } from 'semantic-ui-react'

import StoreGraph from './D3-Simulation/storeGraph'

class DynamicGraph extends Component {
    render() {
        return this.props.levier ? (
            <StoreGraph {...this.props} graph={this.props.levier} />
        ) : (
            <Loader active />
        )
    }
}

export default DynamicGraph
