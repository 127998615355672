import React, { Component } from 'react'
import { Icon, Popup, Grid } from 'semantic-ui-react'
import Statfigures from './FiguresForStats/FiguresForStats'

import '../../statistics.scss'

class Statstab extends Component {
    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="sync alternate" />}
                                    content="Le taux de retour se défnit comme étant la probabilité de revenir pour un client étant allé 1
                  fois à ce magasin. Ici est donné le classement de la boutique."
                                    size="tiny"
                                    position="bottom center"
                                />
                            }
                            figure={this.props.store_data.return_rate.rank}
                            subfigure={`/${this.props.nb_magasins}`}
                            label="Taux de retour"
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="shopping basket" />}
                                    content="Le panier moyen se défnit comme étant la moyenne des achats des clients à cette boutique."
                                    size="tiny"
                                />
                            }
                            label={'Panier moyen'}
                            figure={`${Math.round(this.props.store_data.amount.value)}€`}
                            variation={this.props.store_data.amount.variation}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="exchange" />}
                                    content="L'interaction est la propension des clients de cette boutique à aller dans d'autres boutiques. Globalement, plus une boutique interagit, moins elle a de clients exclusifs. Ici est donné le classement."
                                    size="tiny"
                                />
                            }
                            figure={Math.round(
                                this.props.store_data.cross_sell_expectation.rank
                            )}
                            subfigure={`/${this.props.nb_magasins}`}
                            label={'Espérance de Cross-sell'}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="users" />}
                                    content="Nombre de visiteurs en moyenne par mois dans la boutique."
                                    size="tiny"
                                />
                            }
                            label={'VISITES AVEC ACHAT'}
                            figure={this.props.store_data.visits.value}
                            variation={this.props.store_data.visits.variation}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default Statstab
