import axios from 'axios'

// action
export const GET_CSV_FILE_REQUEST = 'GET_CSV_FILE_REQUEST'
export const GET_CSV_FILE_ERROR = 'GET_CSV_FILE_ERROR'
export const GET_DECRYPT_CUSTOMER_LIST_SUCCESS = 'GET_DECRYPT_CUSTOMER_LIST_SUCCESS'

const initialState = {
    isFetchingCsvFile: false,
    isFetchingDecryptFile: false,
    isReceiveDecryptFile: false,
    csvfile: null,
    queryString: '',
    error: null,
    decryptCustomer: null
}

export default (state = initialState, action = {}) => {
    const { payload, type } = action
    switch (type) {
    case GET_CSV_FILE_REQUEST: {
        return { ...state,
            isFetchingCsvFile: true,
            csvFile: payload
        }
    }
    case GET_CSV_FILE_ERROR: {
        return { ...state,
            error: payload.error,
            isFetchingCsvFile: false,
        }
    }
    case GET_DECRYPT_CUSTOMER_LIST_SUCCESS: {
        return { ...state,
            isFetchingDecryptFile: true,
            isReceiveDecryptFile: true,
            decryptCustomer: payload,
            queryString: payload.queryString,
        }
    }
    default:break
    }
    return state
}

// action creators
export const getCSVRequest = file => ({
    type: GET_CSV_FILE_REQUEST,
    payload: file
})

export const getDecryptCustomerCSVError = error => ({
    type: GET_CSV_FILE_ERROR,
    payload: error
})

export const getDecryptCustomerListSuccess = result => ({
    type: GET_DECRYPT_CUSTOMER_LIST_SUCCESS,
    payload: result
})


/*
* complex actions creators
* */


/**
 * Used to get CSV file decrypt customer
 *
 * @param {file}
 * @returns {Function}
 */
export const fetchDecryptCustomer = file => async (dispatch, getState) => {
    try {
        dispatch(getCSVRequest(file)) // We receive the CSV file
        const { accessToken } = getState().backUser
        const response = await axios.post(`${process.env.REACT_APP_TC_HOST}/backoffice/support/customer/decryptCustomers`, file, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        const { data: decryptCustomerResponse } = response
        return dispatch(getDecryptCustomerListSuccess(decryptCustomerResponse)) // We receive the response from the API, and we got all the information
    } catch (err) {
        dispatch(getDecryptCustomerCSVError(err)) // We got an error when we send the CSV file
        throw err
    }
}
