import React from 'react'
import 'react-phone-number-input/style.css'
import { connect } from 'react-redux'
import { Form, FormGroup, Header, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { parse, getCountryCallingCode } from 'libphonenumber-js'
import Phone from 'react-phone-number-input'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'
import validator from 'validator'
import i18next from 'i18next'
import {
    fetchInfoFromId,
    fetchCustomerByInfo,
    restoreState, deleteState
} from '../../../redux/customerInfo'
import { fetchPhoneCountries } from '../../../actions/supportClient'
import CustomerSupportDetailsHome from './CustomerSupportDetailsHome'
import i18n from '../../../i18n'

import BackOfficeHeader from '../../Layout/BackOfficeHeader'
import CustomerSupportInput from './Input/CustomerSupportInput'
import CustomerSupportInputSubmit from './Input/CustomerSupportInputSubmit'
import CustomerSupportErrorModal from './Modal/CustomerSupportErrorModal'

const PhoneInput = styled(Phone)`
    width: 100% !important
`

const FormField = styled(Form.Field)`
  width: 25% !important;
`

const DivContainerForm = styled.div`
   width: 100%;
   height: 55vh;
   display: flex;
   justify-content: space-around;
   align-items: center;
   flex-direction: column
`

const Label = styled.label`
    color: #021041 !important;
    text-align: left;
    font-size: 14px !important;
    font-weight: 900 !important;
    margin-bottom: 12px !important;
`

const FormCustomer = styled(Form)`
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

const FormGroupCust = styled(FormGroup)`
    width: 100%;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    margin: 0 !important;
`

const SpanForm = styled.span`
    font-size: 15px !important;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const DivSpan = styled.div`
    padding-top: 30px;
`

const HeaderCust = styled(Header)`
    font-weight: 900;
    font-size: 30px;
    color: #021041 !important
`

const DivHeaderShopp = styled.div`
    margin-top: 90px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
`

const DivShopp = styled.div`
    &:hover{
        cursor: pointer;
        box-shadow: 4px 0px 10px #3659b533;
        color: #021041
    }
    width: 250px;
    height: 50px;
    color: #00000073;
    box-shadow: 1px 0px 3px #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 15px 10px;
    font-size: 15px;
    transition: .2s ease all
`

const DivContainerShopp = styled.div`
    width: 100% ;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto
`

const HeaderP = styled.p`
    font-weight: 300;
    font-size: 17px !important;
    color: #00000073;
`

const DivContainerError = styled.div`
    width: 100%;
    height: 100%;
`

const NewSearch = styled.a`
    &:hover{
        color: #515B80;
        cursor: pointer
    }
        color: #202532;
        text-align: left;
        font-weight: bold;
        font-size: 13px;
        margin: 20px 0 0 0;
        align-self: flex-start;
`

const initialState = {
    inputEmail: '',
    inputPhone: '',
    inputId: '',
    isLoading: false,
    countryCode: '',
    multipleId: false,
    customerId: '',
    countryISOCode: [],
    searchInfo: {},
    error: false,
    value: '',
    originCountry: 'FR',
    errorMessage: null
}
class CustomerSupport extends React.Component {
    state = initialState

    componentWillMount() {
        this.setState({ isLoading: true })
        // We get the country for the input phone
        this.props.fetchPhoneCountries()
        .then(({ countries }) => {
            const countryISOCode = countries.map(country => country.code)
            this.setState({ countryISOCode, isLoading: false })
        })
        .catch(err => console.error(`Cannot access phone countries api ${err}`))
    }

    componentWillUnmount() {
        this.props.deleteState()
        this.cleanState()
    }

    //  If the user fill the input name we put it in the state
    handleInputEmailChange = (e) => { this.setState({ inputEmail: e.target.value }) }

    //  If the user fill the input Id we put it in the state
    handleInputId = (e) => { this.setState({ inputId: e.target.value }) }
    cleanState = () => {
        this.setState({
            inputEmail: '',
            inputPhone: '',
            inputId: ''
        })
    }
    handleSubmit = async () => {
        this.props.restoreState() // We do that to prevent an error dut to the new search customer button
        this.setState({ customerId: '' })
       try {
           const customerRequest = {}

           // If the customer has put a customer id
           if (this.state.inputId) {
               await this.props.fetchInfoFromId(this.state.inputId).catch((e) => {
                   e.message = 'error while searching customer by id'
                   throw e
               })
               this.setState({ customerId: this.state.inputId })
               return true
           }
           // If the customer has put a phone number
           if (this.state.inputPhone) {
               const { phone: phoneNumber, country } = parse(this.state.inputPhone)
               const countryCode = await getCountryCallingCode(country)
               customerRequest.phoneNumber = phoneNumber
               customerRequest.countryCode = countryCode
           // If the customer has put an email
           } else if (this.state.inputEmail) {
               customerRequest.email = this.state.inputEmail
           }

           await this.props.fetchCustomerByInfo({ ...customerRequest }).catch((e) => {
               e.message = i18next.t('common.errorMessage')
               throw e
           })

           // If we receive from the api more than one customer id
           if (this.props.customerInfo.customerIdList.length > 1) {
               this.setState({ multipleId: true })

           // If we receive just one customer id
           } else {
               this.setState({ customerId: this.props.customerInfo.customerIdList[0].id })
           }
       } catch (e) {
           if (e.response) {
               if (e.response.data.code === 'resourceNotFound') {
                   return this.setState({ error: true, errorMessage: i18next.t('customerSupport.errors.notFound') })
               }
               return this.setState({ error: true, errorMessage: e.response.data.message })
           }
           return this.setState({ error: true, errorMessage: e.message })
       }
    }

    // When the user click on an input it delete the data from all the others
    clearInputValue = ({ target: { name, value } }) => {
        if (name && value.length) {
            return
        }
        this.setState({ inputId: '', inputPhone: '', inputEmail: '' })
    }

    // This function close the error modal
    closeErrorModal = () => {
        this.setState({ error: false, originCountry: 'FR' })
    }

    render() {
        const { countryISOCode, error, customerId, inputEmail, inputId, inputPhone } = this.state
        const { customerInfo} = this.props
        return (
            <div
                className="customer-id-page"
                onKeyPress={(event) => {
                                if (event.key === 'Enter' && error) {
                                    this.closeErrorModal()
                                }
                            }
                        }
            >
                <DivContainerError onClick={() => this.setState({ error: null, originCountry: 'FR' })} >
                    <CustomerSupportErrorModal
                        openError={error}
                        errorMessage={this.state.errorMessage}
                        closeModal={this.closeErrorModal}

                    />
                </DivContainerError>
                {
                    customerId.length !== 0 && customerInfo && customerInfo.backHome
                        ?
                        <CustomerSupportDetailsHome cleanState={this.cleanState} customerId={customerId} />
                        :
                        // If we got multiple id then wd show the store's list
                        customerInfo.customerIdList && customerInfo.customerIdList.length
                            ?
                            <DivHeaderShopp>
                                <NewSearch
                                    style={{ display: 'block', margin: '0 45px' }}
                                    onClick={() => { this.props.deleteState() }}
                                >
                                    <Icon name="long arrow alternate left" />
                                    {i18n.t(('customerSupport.infoPage.header.newSearch'))}
                                </NewSearch>
                                <HeaderCust as="h2" attached="top" className="transactions-assignment">
                                    {i18n.t(('customerSupport.homePage.title'))}
                                </HeaderCust>
                                <HeaderP>{i18n.t(('customerSupport.homePage.multipleResults'))}</HeaderP>
                                <DivContainerShopp
                                    style={{ width: '100%' }}
                                >
                                    {
                                        customerInfo.customerIdList.map(list =>
                                            (
                                                <DivShopp
                                                    role="presentation"
                                                    key={list.id}
                                                    onClick={() => this.setState({ customerId: list.id })}
                                                >
                                                    <p key={list.id}>{list.ShoppingHub.name}</p>
                                                </DivShopp>
                                            )
                                        )

                                    }
                                </DivContainerShopp>
                            </DivHeaderShopp>
                            :
                            this.state.isLoading
                    ?
                                <Loader
                                    type="Oval"
                                    color="#1f2532"
                                    height="30"
                                    width="30"
                                />
                                :
                                (
                                    <DivContainerForm>

                                        <BackOfficeHeader
                                            title="Customer Support"
                                            subtitle={i18n.t(('customerSupport.homePage.textDesc'))}
                                        />

                                        <FormCustomer
                                            onSubmit={this.handleSubmit}
                                            onKeyPress={(event) => {
                                                    if (event.key === 'Enter' && !error && ((validator.isEmail(inputEmail) || validator.isUUID(inputId)) && (!inputPhone ? true : inputPhone.length <= 11 || inputPhone.length === 0))) {
                                                        this.handleSubmit()
                                                    }
                                                }
                                            }
                                        >
                                            <FormGroupCust>
                                                <CustomerSupportInput
                                                    type="text"
                                                    inputEmail
                                                    inputPhone
                                                    inputId
                                                    value={inputId}
                                                    onChange={this.handleInputId}
                                                    label={i18n.t(('customerSupport.homePage.homeForm.inputCustomer.title'))}
                                                    placeholder={i18n.t(('customerSupport.homePage.homeForm.inputCustomer.placeholder'))}
                                                    clearOtherInputValues={this.clearInputValue}
                                                    className={inputEmail.length !== 0 || inputPhone
                                                        ? 'disabledInput'
                                                        : null
                                                        }
                                                    name="inputId"
                                                />

                                                <DivSpan>
                                                    <SpanForm>{i18n.t(('common.or'))}</SpanForm>
                                                </DivSpan>

                                                <CustomerSupportInput
                                                    type="email"
                                                    inputEmail
                                                    inputPhone
                                                    inputId
                                                    value={inputEmail}
                                                    onChange={this.handleInputEmailChange}
                                                    label={i18n.t(('customerSupport.homePage.homeForm.inputEmail.title'))}
                                                    placeholder={i18n.t(('customerSupport.homePage.homeForm.inputEmail.placeholder'))}
                                                    clearOtherInputValues={this.clearInputValue}
                                                    className={inputId.length !== 0 || inputPhone
                                                        ? 'disabledInput'
                                                        : null
                                                    }
                                                    name="inputEmail"
                                                />

                                                <DivSpan>
                                                    <SpanForm>{i18n.t(('common.or'))}</SpanForm>
                                                </DivSpan>
                                                <FormField>
                                                    <Label>{i18n.t(('customerSupport.homePage.homeForm.inputPhone.title'))}</Label>
                                                    {
                                                        countryISOCode.length > 0 && (
                                                            <PhoneInput
                                                                placeholder={i18n.t(('customerSupport.homePage.homeForm.inputPhone.placeholder'))}
                                                                countries={countryISOCode}
                                                                className={(
                                                                    inputEmail.length !== 0 || inputId.length !== 0)
                                                                    ? 'disabledInput'
                                                                    : null
                                                                }
                                                                value={inputPhone}
                                                                country={this.state.originCountry}
                                                                onChange={inputPhones => this.setState({ inputPhone: inputPhones })}
                                                                onClick={e => this.clearInputValue(e)}
                                                                name="inputPhone"
                                                            />
                                                        )
                                                    }
                                                </FormField>
                                            </FormGroupCust>
                                        </FormCustomer>

                                        <CustomerSupportInputSubmit
                                            inputEmail={inputEmail}
                                            inputPhone={inputPhone}
                                            inputId={inputId}
                                            onClick={this.handleSubmit}
                                        />
                                    </DivContainerForm>
                                )
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    customerInfo: state.customerInfo
})

const mapDispatchToProps = dispatch => ({
    fetchInfoFromId: (...args) => dispatch(fetchInfoFromId(...args)),
    fetchCustomerByInfo: (...args) => dispatch(fetchCustomerByInfo(...args)),
    fetchPhoneCountries: (...args) => dispatch(fetchPhoneCountries(...args)),
    restoreState: (...args) => dispatch(restoreState(...args)),
    deleteState: (...args) => dispatch(deleteState(...args))
})

CustomerSupport.propTypes = {
  customerInfo: PropTypes.objectOf(PropTypes.string).isRequired,
  fetchCustomerByInfo: PropTypes.string.isRequired,
  fetchInfoFromId: PropTypes.func.isRequired,
  fetchPhoneCountries: PropTypes.func.isRequired,
  restoreState: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport)

