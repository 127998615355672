import React from 'react'
import { Button, Form, Header, Modal, Icon, Message, Popup,  Input, Label } from 'semantic-ui-react'
import { SingleDatePicker } from 'react-dates'
import Loader from 'react-loader-spinner'
import moment from 'moment'
import connect from 'react-redux/es/connect/connect'
import Magnifier from 'react-magnifier'
import styled from 'styled-components'
import TimeField from 'react-simple-timefield'

import { updateReceipt } from '../../redux/receipts'
import { getStores } from '../../redux/stores'

require('moment/locale/fr')

const RejectionButtonContainer = styled.div`
    padding: 0!important;
    display: flex!important;
    flex-direction: column!important;
    width: 20rem!important;
    background: white!important;
`

const RejectionButton = styled.p`
   padding: 10px;
   cursor: pointer;
   transition: .1s ease all;
   &:hover {
        background-color: #0000000f;;
    }
`

const initialState = {
    jumpToReceiptId: null,
    storeId: null,
    amount: null,
    date: null,
    time: '',
    storeName: null,
    submissionError: null,
    popupIsOpen: false,
    loadingBtn: false,
    validatingAmount: false,
    maxValidationAmount: 300,
}

class ManageReceiptModal extends React.Component {
    state = initialState
    componentWillReceiveProps(nextProps) {
        if (nextProps.receiptData) {
            this.setState({
                amount: nextProps.receiptData.amount,
                date: nextProps.receiptData.date,
                time: nextProps.receiptData.time,
                storeId: nextProps.receiptData.storeId,
                storeName: nextProps.receiptData.storeName,
                submissionError: null,
                isahitId: nextProps.receiptData.isahitId
            })
        }
    }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })

        if (name === 'storeName') {
            this.props.receiptData.storeList.forEach((store) => {
                if (value === store.name) {
                    this.setState({
                        storeId: store.id,
                        [name]: value
                    })
                }
            })
        }
    }
    handleTimeChange = (time) => {
        this.setState({ time })
    }
    updateReceipt = async (event) => {
        event.preventDefault()
        this.setState({ validatingAmount: false })
        try {
            this.setState({
                loadingBtn: true
            })
            const receiptId = this.props.receiptData.id
            const storeId = this.state.storeId
            const date = this.state.date.format('YYYY-MM-DD')
            const dateTime = moment(`${date} ${this.state.time}`, 'YYYY-MM-DD HH:mm').format()
            console.log(moment(dateTime) > moment())
            if(moment(dateTime) > moment()){
                alert('Date is in the future')
                throw({
                    code: 'invalidDate'
                })
            }

            this.props.getNextRecord()

            const time = this.state.time
            const amount = this.state.amount.toString().replace(/-/gi, '') // remove minus if exists
            // check amount

            await this.props.updateReceipt({
                id: receiptId,
                amount: -amount,
                status: 'validated',
                dateTime,
                time,
                storeId,
                backUserId: localStorage.backUserId
            })

            // this.setState({ ...initialState }) // clean state
            this.setState({
                loadingBtn: false
            })
            this.props.notify('success', '🚀   Receipt successfully validated !')
        } catch (err) {
            this.setState({
                loadingBtn: false
            })
            if (!err.response) { // network error
                return this.setState({ submissionError: 'An error has occurred', updateSuccess: false })
            }
            const { data: { code } } = err.response

            if (code === 'invalidDateTime' || code === 'invalidDate') {
                return this.setState({
                    submissionError: 'Invalid date',
                    updateSuccess: false
                })
            }
        }
    }

    rejectReceipt = async (reason) => {
       try {
           this.closePopup()
           const receiptId = this.props.receiptData.id
           this.props.getNextRecord()
           await this.props.updateReceipt({
               status: 'rejected',
               id: receiptId,
               rejectionReason: reason,
               backUserId: localStorage.backUserId
           })

           this.props.notify('error', '🚀   Receipt successfully rejected !')
           this.setState({ ...initialState })
       } catch (e) {
           if (!e.response) { // network error
               return this.setState({ submissionError: 'An error has occurred', updateSuccess: false })
           }
       }
    }
    closeModal() {
        this.props.closeModal('pending', this.props.receiptData.id)
        this.setState({ ...initialState })
    }
    closePopup = () => {
        this.setState({
            popupIsOpen: false
        })
    }
    openPopUp = () => {
        this.setState({
            popupIsOpen: true
        })
    }
    showValidateAmount = () => {
        this.setState({ validatingAmount: true })
    }
    amountManagementModal = () => (
        <Modal
            className="amount-validation-modal"
            size={'tiny'}
            open={this.state.validatingAmount}
            style={{
                textAlign: 'center',
                padding: '1.5rem 0rem 1.5rem 0rem',
                paddingLeft: '0rem',
                borderRadius: '9px'
            }}
        >
            <p style={{ borderBottom: '0px', fontSize: '16px', fontWeight: '900' }}>PAY ATTENTION</p>
            <div>
                <p>The amount of the ticket seems to be really high.
                    <br />Are you sure you want to validate it?</p>
            </div>
            <Modal.Actions style={{ borderTop: '0px', textAlign: 'center' }}>
                <Button
                    onClick={() => this.setState({ validatingAmount: false })}
                    basic
                    size="mini"
                    color="black"
                    style={{ marginRight: '2rem', fontWeight: '900' }}
                >
                    Modify
                </Button>
                <Button
                    color="blue"
                    size="mini"
                    onClick={this.updateReceipt}
                    positive
                    icon="checkmark"
                    labelPosition="right"
                    content="Validate"
                />
            </Modal.Actions>
        </Modal>)
    jumpToReceipt = () => {
        this.props.getNextRecord(this.state.jumpToReceiptId)
    }
    render() {
        let content
        const isDisabledButton = !this.state.storeId || !this.state.amount || !this.state.date

        if (this.props.receiptData) {
            content = (
                <div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        minHeight: '70vh',

                    }}
                    >
                        <div style={{
                            width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative'
                        }}
                        >
                            <Magnifier
                                src={this.props.receiptData.imageURI}
                                mgWidth={180}
                                mgHeight={180}
                                mgShape={'square'}
                                width={500}
                            />

                        </div>
                        <div style={{
                            width: '40%',
                            padding: '1.5rem',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        >
                            <Form onSubmit={this.handleSubmit} >
                                {
                                    this.state.submissionError && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-6rem',
                                            width: '100%',
                                            textAlign: 'center'
                                        }}
                                        >
                                            <Message negative attached>
                                                <Message.Header>Submission error</Message.Header>
                                                <p>
                                                    {this.state.submissionError}
                                                </p>
                                            </Message>
                                        </div>

                                    )


                                }
                                <p style={{
                                    marginBottom: '3rem',
                                    fontWeight: '800',
                                    color: '#0000005e'
                                }}
                                >Hub: <span style={{
                                    fontSize: '14px',
                                    marginLeft: '.5rem',
                                    textTransform: 'uppercase',
                                    fontWeight: 800,
                                    color: 'black'
                                }}
                                >{this.props.receiptData.hubName}</span></p>
                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <label>Purchase Date</label>
                                        <div style={{
                                            width: '100%'
                                        }}
                                        >
                                            <SingleDatePicker
                                                isOutsideRange={() => false}
                                                noBorder
                                                placeholder="Choose a date"
                                                numberOfMonths={1}
                                                date={this.state.date} // momentPropTypes.momentObj or null
                                                onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                                                focused={this.state.focused} // PropTypes.bool
                                                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Purchase Time</label>
                                        <TimeField
                                            value={this.state.time}
                                            onChange={this.handleTimeChange}
                                            style={{
                                                width: '100%'
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>


                                <Form.Group widths="equal">
                                    <Form.Field>
                                        <Form.Dropdown
                                            className={'receipt-store-input'}
                                            name="storeName"
                                            selection
                                            value={this.state.storeName}
                                            onChange={this.handleChange}
                                            label="Store"
                                            options={this.props.receiptData.storeList}
                                            placeholder="Choose a store"
                                            search
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input
                                        size="small"
                                        label={'Amount'}
                                        onChange={this.handleChange}
                                        type={'number'}
                                        defaultValue={this.props.receiptData.amount}
                                        value={this.state.amount}
                                        name="amount"
                                        className={'amount-receipt-input'}
                                        placeholder="Amount"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            )
        } else {
            content = (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    minHeight: '70vh',
                    alignItems: 'center'

                }}
                >
                    <Loader
                        type="Oval"
                        color="#0000000f"
                        height="60"
                        width="60"
                    />
                </div>
            )
        }

        return (
            <React.Fragment>
            <Modal
                size={'tiny'}
                open={this.props.visible}
                className={'receipt-management-modal'}
                style={{
                    width: '900px',
                    marginBottom: '3rem!important'
                }}
            >
                <Header icon="edit" content={`Manage receipt - Receipt left: ${this.props.receiptData ? this.props.receiptData.receiptsLeft : null}`} />
                <Modal.Content style={{
                    padding: '0!important'
                }}
                >
                    <Form  onSubmit={this.jumpToReceipt}>
                        <Form.Field inline>
                            <label>Jump to receipt</label>
                            <Input placeholder='Receipt Id'  onChange={(e) => this.setState({jumpToReceiptId: e.target.value})}/>
                            <Button type='submit'>Go</Button>
                            <b>Current Id: {this.props.receiptData ? this.props.receiptData.id : ''}</b>
                            {
                                this.props.receiptData && this.props.receiptData.isahitId ? (<Label color="teal" image>
                                    <Icon name="check"/>
                                    <Label.Detail>Isahit</Label.Detail>
                                </Label>) : null
                            }
                        </Form.Field>
                    </Form>
                        {content}
                </Modal.Content>
                <Modal.Actions>
                    <Button size="mini" onClick={() => this.closeModal()}>
                        Close
                    </Button>
                    <Popup
                        on="click"
                        open={this.state.popupIsOpen}
                        onClose={() => this.closePopup()}
                        style={{

                        }}
                        // pinned
                        trigger={
                            <Button size="mini" color="red" icon labelPosition="right" onClick={() => this.openPopUp()}>
                                Reject
                                <Icon name="caret down" />
                            </Button>
                        }
                    >
                        <RejectionButtonContainer>
                            <RejectionButton onClick={() => this.rejectReceipt('outOfMall')} >Out of mall</RejectionButton>
                            <RejectionButton onClick={() => this.rejectReceipt('notATicket')} >Not a ticket</RejectionButton>
                            <RejectionButton onClick={() => this.rejectReceipt('incomplete')} >incomplete</RejectionButton>
                            <RejectionButton onClick={() => this.rejectReceipt('notADebit')} >Not a debit</RejectionButton>
                            <RejectionButton onClick={() => this.rejectReceipt('storeNotFound')} >Store not in the list</RejectionButton>
                            <RejectionButton onClick={() => this.rejectReceipt('notInClientReferential')} >Not in client referential</RejectionButton>
                        </RejectionButtonContainer>
                    </Popup>
                    <Button
                        color="blue"
                        size="mini"
                        loading={this.state.loadingBtn}
                        disabled={isDisabledButton}
                        onClick={this.state.amount >= this.state.maxValidationAmount ? this.showValidateAmount : this.updateReceipt}
                        icon
                        labelPosition="right"
                    >
                        Validate
                        <Icon name="checkmark" />
                    </Button>
                </Modal.Actions>
            </Modal>
                {this.amountManagementModal()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    accessToken: state.backUser.accessToken,
    receipts: state.receipts,
})
const mapDispatchToProps = dispatch => ({
    updateReceipt: (...args) => dispatch(updateReceipt(...args)),
    getStores: (...args) => dispatch(getStores(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageReceiptModal)
