import React, { Component } from 'react'
import 'react-day-picker/lib/style.css'
import connect from 'react-redux/es/connect/connect'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import { Chart } from 'react-google-charts'
import './StatBoard.css'
import { fetchDailyStats, clearDailyStats } from '../../actions/stats'

function getArrayOfNames(array) {
    const resArray = []
    for (let i = 0; i < array.length; i++) {
        resArray.push(array[i].shortcut)
    }
    for (let i = 0; i < array.length; i++) {
        resArray.push(`Goal ${array[i].shortcut}`)
    }
    resArray.unshift('date')
    return resArray
}

function getArrayOfColor(array) {
    let res = []

    for (let i = 0; i < array.length; i++) {
        res.push(array[i].color)
    }
    res = res.concat(res)
    return res
}

function getArrayOfData(array, date) {
    let a = 0
    let b = 0
    let c = 0
    let d = 0
    let data = []
    const res = []
    const name = getArrayOfNames(array[array.length - 1])
    while (a < date.length) {
        if (d === 0) {
            data.push(date[a])
            d++
        } else {
            data.push(null)
            d--
        }
        while (b !== 5) {
            if (array[c].day.includes(date[a]) === true) {
                data.push(parseInt(array[c].nb_registration, 10))
                b++
            }
            c++
        }
        for (let i = 0; i < array[array.length - 1].length; i++) {
            data.push(array[array.length - 1][i].objective)
        }
        c = 0
        b = 0
        res.push(data)
        data = []
        a++
    }
    res.unshift(name)
    return (res)
}

function getDates(startDate, stopDate, type) {
    let format = ''
    if (type === 1) {
        format = 'DD-MM'
    } else {
        format = 'MM-DD'
    }
    const dateArray = []
    let currentDate = moment(startDate)
    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format(format))
        currentDate = moment(currentDate).add(1, 'days')
    }
    return dateArray
}

function formatDate() {
    let today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()

    if (dd < 10) {
        dd = `0${dd}`
    }
    if (mm < 10) {
        mm = `0${mm}`
    }

    today = `${yyyy.toString()}-${mm.toString()}-${dd.toString()}`
    return (today)
}

class DailyChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            datesRangeFrom: '',
            datesRangeTo: ''
        }
    }
    componentDidMount = async () => {
        await clearDailyStats()
        try {
            await this.props.fetchDailyStats('', '', true)
        } catch (e) {
            this.setState({ errors: 'serverError' })
        }
        if (this.props && this.props.customerDailyStats.stats) {
            this.setState({ data: getArrayOfData(this.props.customerDailyStats.stats, getDates(moment(formatDate()).subtract(1, 'month'), moment(formatDate())), 2) })
        }
    }

    handleChangeDateFrom = async (value) => {
        const newDate = moment(value)
        await this.setState({ datesRangeFrom: newDate.format('YYYY-MM-DD') })
        if (this.state.datesRangeTo) {
            try {
                localStorage.removeItem('dailyStats')
                await this.props.fetchDailyStats(this.state.datesRangeFrom, this.state.datesRangeTo, 'true')
                await this.setState({ data: getArrayOfData(this.props.customerDailyStats.stats, getDates(moment(this.state.datesRangeFrom), moment(this.state.datesRangeTo)), 2) })
            } catch (e) {
                this.setState({ errors: 'serverError' })
            }
        }
    }
    handleChangeDateTo = async (value) => {
        const newDate = moment(value)
        await this.setState({ datesRangeTo: newDate.format('YYYY-MM-DD') })
        if (this.state.datesRangeFrom) {
            try {
                localStorage.removeItem('dailyStats')
                await this.props.fetchDailyStats(this.state.datesRangeFrom, this.state.datesRangeTo, 'true')
                await this.setState({ data: getArrayOfData(this.props.customerDailyStats.stats, getDates(moment(this.state.datesRangeFrom), moment(this.state.datesRangeTo)), 2) })
            } catch (e) {
                this.setState({ errors: 'serverError' })
            }
        }
    }

    render() {
        let options = null
        if (this.props && this.props.customerDailyStats) {
            options = {
                animation: {
                    startup: true,
                    duration: 700,
                    easing: 'Out'
                },

                chartArea: {
                    width: '95%',
                    height: '85%',
                },
                legend: { position: 'top', textStyle: { color: '#ffffff', fontSize: 14 } },
                vAxis: { textColor: '#ffffff', viewWindowMode: 'explicit', gridlines: { count: 5 }, textStyle: { color: '#ffffff', fontSize: 14 } },
                hAxis: { textColor: '#ffffff' },
                lineWidth: 1.8,
                colors: getArrayOfColor(this.props.customerDailyStats.stats[this.props.customerDailyStats.stats.length - 1]),
                backgroundColor: '#242c3c'
            }
        }
        const chart = this.state && this.state.data && options ? (<Chart
            chartType="LineChart"
            height={'500px'}
            data={this.state.data}
            options={options}
        />) : null

        return (
            <div className="chart-div">
                <div className="DateInputDiv">
                    <DayPickerInput
                        placeholder="from"
                        value={this.state.datesRangeFrom}
                        onDayChange={this.handleChangeDateFrom}
                        inputProps={{
                            style: { width: 90 }
                        }}
                    />
                    {} {}
                    <DayPickerInput
                        placeholder="to"
                        value={this.state.datesRangeTo}
                        onDayChange={this.handleChangeDateTo}
                        inputProps={{
                            style: {
                                width: 90,
                            }
                        }}
                        dayPickerProps={{
                            todayButton: 'Today'
                        }}
                    />
                </div>
                <p className={'chartTitle'}>Customers with payment card per day</p>
                {chart}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerDailyStats: state.customerStats.customerDailyStats,
})

export default connect(mapStateToProps, {
    fetchDailyStats
})(DailyChart)
