// action types
import { GET_BACK_USERS, GET_BACK_USERS_SUCCESS, GET_BACK_USERS_FAILURE } from '../types'
// api calls
import api from '../api'

// Action Creators
export const requestBackUsers = () => ({ type: GET_BACK_USERS })
export const receiveBackUsers = backUsers => ({ type: GET_BACK_USERS_SUCCESS, backUsers })
export const receiveBackUsersFail = error => ({ type: GET_BACK_USERS_FAILURE, error })


export const fetchBackUsers = () => async (dispatch) => {
    try {
        dispatch(requestBackUsers())
        const data = await api.userManagement.fetchBackUsers()
        dispatch(receiveBackUsers(data))
    } catch (err) {
        dispatch(receiveBackUsersFail(err))
    }
}
