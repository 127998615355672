// action types
import { CUSTOMER_INFO_FETCHED, CUSTOMER_ID_FETCHED, COUNTRIES_FETCHED } from '../types'

// api calls
import api from '../api'

// Action Creators
export const customerInfoFetched = customerInfo => ({
    type: CUSTOMER_INFO_FETCHED,
    customerInfo
})

export const customerIdFetched = customerInfo => ({
    type: CUSTOMER_ID_FETCHED,
    customerInfo
})
export const countriesFetched = countries => ({
    type: COUNTRIES_FETCHED,
    countries
})


/**
 * This function returns a other function that will call our API,
 * Redux Thunk middleware allows you to write action creators that return a function instead of an action.
 * i first make api request, get data and dispatch action that will change via reducers to the redux store
 * @param phoneNumber
 */
export const fetchCustomerTransactions = customerId => dispatch => api.customer.fetchCustomerTransactions(customerId).then(customerInfo => dispatch(customerInfoFetched(customerInfo)))
export const fetchPhoneCountries = () => dispatch => api.customer.fetchPhoneCountries().then(countries => dispatch(countriesFetched(countries)))

/**
 * Clear the clearCustomerInfo state
 */
