import React from 'react'
import _ from 'lodash'
import { ToastContainer, toast, Flip } from 'react-toastify'
import Loader from 'react-loader-spinner'

import {
    Form,
    Label,
    Container,
    Button,
    Input,
    Grid,
    Select,
    Radio,
    Search
} from 'semantic-ui-react'
import api from '../../../api'

import { alpha2Countries, languageCodes } from '../../../utils'
import BackOfficeHeader from '../../Layout/BackOfficeHeader'

class HubCreationForm extends React.Component {
    constructor(props) {
        super(props)
        let id
        let form
        id = this.props.match.params.id
        form = {}
        this.state = {
            shoppingHubId: id,
            loading: true,
            form,
            results: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    notify = (type, message) => {
        toast[type](<div>
            <p>{message}</p>
        </div>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        })
    }

    async componentWillMount() {
        const hubOwners = await api.shoppingHubsOwners.fetchHubsOwnersList()
        const hubOwnersOptions = hubOwners.map((owner) => ({
                value: owner.id,
                text: owner.name
            }))
        const cities = await api.cities.fetchCitiesList()
        const citiesOptions = cities.map((city) => ({
                value: city.id,
                title: city.name
            }))
        const state = {}
        const shoppingHub = await api.shoppingHubs.fetchShoppingHub(this.state.shoppingHubId)
        const countriesOptions = alpha2Countries()
        const languageOptions = languageCodes()

        state.shoppingHubToUpdate = shoppingHub
        state.form = shoppingHub
        state.hubOwnersOptions = hubOwnersOptions
        state.source = citiesOptions
        state.results = []
        state.countriesOptions = countriesOptions
        state.languageOptions = languageOptions
        state.loading = false
        state.searchIsLoading = false
        state.searchValue = shoppingHub.cityId ? citiesOptions.filter(city => city.value === shoppingHub.cityId)[0].title : ''
        this.setState(state)
    }

    handleChange = (e, { name, value }) => {
        const { form } = this.state
        value = value ? value.toString() : ''
        form[name] = value
        this.setState({
            form
        })
    }

    handleChangeSwitch = (e, { name, value, checked }) => {
        const { form } = this.state
        value = checked
        form[name] = value
        this.setState({
            form
        })
    }

    async handleSubmit(event) {
        try {
            await api.shoppingHubs.update(this.state.form)
            this.notify('success', '🚀  hub updated')
            setTimeout(() => {
                window.location = '/hub-management'
            }, 3000)
        } catch (e) {
            this.notify('error', `🚀 ${e.message}`)
        }

        event.preventDefault()
    }


    handleResultSelect = (e, { result }) => {
        this.setState({ searchValue: result.title })
        const { form } = this.state
        form.cityId = result.value
        this.setState({
            form
        })
    }


    handleSearchChange = (e, { value }) => {
        const initialState = { searchIsLoading: false, results: [], searchValue: '' }
        this.setState({ searchIsLoading: true, searchValue: value })

        setTimeout(() => {
            if (this.state.searchValue.length < 1) return this.setState(initialState)

            const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i')
            const isMatch = result => re.test(result.title)

            this.setState({
                searchIsLoading: false,
                results: _.filter(this.state.source, isMatch)
            })
        }, 100)
    };

    render() {
        const { loading, hubOwnersOptions, countriesOptions, languageOptions, shoppingHubToUpdate, searchValue, results, searchIsLoading } = this.state

        return (
            <div>
                <BackOfficeHeader
                    title="Modify Shopping Hub"
                    cta="Save Hub →"
                    onClick={this.handleSubmit}
                />
            {
                !loading ? (
                    <Form className="big-form" onSubmit={this.handleSubmit}>
                    <Container textAlign="center">
                        <Grid>
                            <Grid.Row className="col-3 label-left">
                                <Form.Field>
                                    <Label>
                                        Hub Name
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.name ? shoppingHubToUpdate.name.toString() : ''}
                                        placeholder="Hub Name"
                                        onChange={this.handleChange}
                                        name="name"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Hub Owner
                                    </Label>
                                    <Select
                                        placeholder="Hub Owner"
                                        name="shoppingHubOwnerId"
                                        options={hubOwnersOptions}
                                        onChange={this.handleChange}
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.shoppingHubOwnerId ? shoppingHubToUpdate.shoppingHubOwnerId.toString() : ''}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        City
                                    </Label>
                                    <Search
                                        name="cityId"
                                        input={{ icon: 'search', iconPosition: 'left' }}
                                        loading={searchIsLoading}
                                        onResultSelect={this.handleResultSelect}
                                        onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                            leading: true,
                                        })}
                                        results={results}
                                        value={searchValue}
                                        {...this.props}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Baseline (main description)
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.baseline ? shoppingHubToUpdate.baseline.toString() : ''}
                                        placeholder="Baseline"
                                        onChange={this.handleChange}
                                        name="baseline"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Shortcut
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.shortcut ? shoppingHubToUpdate.shortcut.toString() : ''}
                                        placeholder="Shortcut"
                                        onChange={this.handleChange}
                                        name="shortcut"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Street Number
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.streetNumber ? shoppingHubToUpdate.streetNumber.toString() : ''}
                                        placeholder="Street Number"
                                        onChange={this.handleChange}
                                        name="streetNumber"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Street Name
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.streetName ? shoppingHubToUpdate.streetName.toString() : ''}
                                        placeholder="Street Name"
                                        onChange={this.handleChange}
                                        name="streetName"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Postal Code
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.postalCode ? shoppingHubToUpdate.postalCode.toString() : ''}
                                        placeholder="Postal Code"
                                        onChange={this.handleChange}
                                        name="postalCode"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        City
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.city ? shoppingHubToUpdate.city.toString() : ''}
                                        placeholder="City"
                                        onChange={this.handleChange}
                                        name="city"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Full Address
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.formattedAddress ? shoppingHubToUpdate.formattedAddress.toString() : ''}
                                        placeholder="Full Address"
                                        onChange={this.handleChange}
                                        name="formattedAddress"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Country
                                    </Label>
                                    <Select
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.country ? shoppingHubToUpdate.country.toString() : ''}
                                        placeholder="Country"
                                        options={countriesOptions}
                                        onChange={this.handleChange}
                                        name="country"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Longitude
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.longitude ? shoppingHubToUpdate.longitude.toString() : ''}
                                        placeholder="Longitude"
                                        onChange={this.handleChange}
                                        name="longitude"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Latitude
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.latitude ? shoppingHubToUpdate.latitude.toString() : ''}
                                        placeholder="Latitude"
                                        onChange={this.handleChange}
                                        name="latitude"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Mango pay user Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.mgUserId ? shoppingHubToUpdate.mgUserId.toString() : ''}
                                        placeholder="Mango pay user Id"
                                        onChange={this.handleChange}
                                        name="mgUserId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Mango pay wallet Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.mgWalletId ? shoppingHubToUpdate.mgWalletId.toString() : ''}
                                        placeholder="Mango pay wallet Id"
                                        onChange={this.handleChange}
                                        name="mgWalletId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Sms Sender Name
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.smsSenderName ? shoppingHubToUpdate.smsSenderName.toString() : ''}
                                        placeholder="Sms Sender Name"
                                        onChange={this.handleChange}
                                        name="smsSenderName"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Bank Credential Update URL
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.bankCredentialUpdateUrl ? shoppingHubToUpdate.bankCredentialUpdateUrl.toString() : ''}
                                        placeholder="Bank Credential Update URL"
                                        onChange={this.handleChange}
                                        name="bankCredentialUpdateUrl"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Iban Update Url
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.ibanUpdateUrl ? shoppingHubToUpdate.ibanUpdateUrl.toString() : ''}
                                        placeholder="Iban Update Url"
                                        onChange={this.handleChange}
                                        name="ibanUpdateUrl"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Registration Url
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.registrationUrl ? shoppingHubToUpdate.registrationUrl.toString() : ''}
                                        placeholder="Registration Url"
                                        onChange={this.handleChange}
                                        name="registrationUrl"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Account password Url
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.accountPasswordUrl ? shoppingHubToUpdate.accountPasswordUrl.toString() : ''}
                                        placeholder="Account password Url"
                                        onChange={this.handleChange}
                                        name="accountPasswordUrl"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Account password Template Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.accountPasswordTemplateId ? shoppingHubToUpdate.accountPasswordTemplateId.toString() : ''}
                                        placeholder="Account password Template Id"
                                        onChange={this.handleChange}
                                        name="accountPasswordTemplateId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Incomplete Registration Template Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.incompleteRegistrationTemplateId ? shoppingHubToUpdate.incompleteRegistrationTemplateId.toString() : ''}
                                        placeholder="Incomplete Registration Template Id"
                                        onChange={this.handleChange}
                                        name="incompleteRegistrationTemplateId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Refund Notification Template Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.refundNotificationTemplateId ? shoppingHubToUpdate.refundNotificationTemplateId.toString() : ''}
                                        placeholder="Refund Notification Template Id"
                                        onChange={this.handleChange}
                                        name="refundNotificationTemplateId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Program Name
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.programName ? shoppingHubToUpdate.programName.toString() : ''}
                                        placeholder="Program Name"
                                        onChange={this.handleChange}
                                        name="programName"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Recaptcha Site Key
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.recaptchaSiteKey ? shoppingHubToUpdate.recaptchaSiteKey.toString() : ''}
                                        placeholder="Recaptcha Site key"
                                        onChange={this.handleChange}
                                        name="recaptchaSiteKey"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Recaptcha Secret
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.recaptchaSecret ? shoppingHubToUpdate.recaptchaSecret.toString() : ''}
                                        placeholder="Recaptcha Secret"
                                        onChange={this.handleChange}
                                        name="recaptchaSecret"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Web Service Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.webServiceId ? shoppingHubToUpdate.webServiceId.toString() : ''}
                                        placeholder="Web Service Id"
                                        onChange={this.handleChange}
                                        name="webServiceId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Main Language
                                    </Label>
                                    <Select
                                        options={languageOptions}
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.mainLanguage ? shoppingHubToUpdate.mainLanguage.toString() : ''}
                                        placeholder="Main Language"
                                        onChange={this.handleChange}
                                        name="mainLanguage"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Google Place Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.googlePlaceId ? shoppingHubToUpdate.googlePlaceId.toString() : ''}
                                        placeholder="Google Place Id"
                                        onChange={this.handleChange}
                                        name="googlePlaceId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        External Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.externalId ? shoppingHubToUpdate.externalId.toString() : ''}
                                        placeholder="External Id"
                                        onChange={this.handleChange}
                                        name="externalId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Google Analytics Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.googleAnalyticsId ? shoppingHubToUpdate.googleAnalyticsId.toString() : ''}
                                        placeholder="Google Analytics Id"
                                        onChange={this.handleChange}
                                        name="googleAnalyticsId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Hotjar Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.hotjarId ? shoppingHubToUpdate.hotjarId.toString() : ''}
                                        placeholder="Hotjar Id"
                                        onChange={this.handleChange}
                                        name="hotjarId"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Favicon Url
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.favicon_url ? shoppingHubToUpdate.favicon_url.toString() : ''}
                                        placeholder="Favicon Url"
                                        onChange={this.handleChange}
                                        name="favicon_url"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Logo Url
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.logo_url ? shoppingHubToUpdate.logo_url.toString() : ''}
                                        placeholder="Logo Url"
                                        onChange={this.handleChange}
                                        name="logo_url"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Host
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.host ? shoppingHubToUpdate.host.toString() : ''}
                                        placeholder="Host"
                                        onChange={this.handleChange}
                                        name="host"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Primary Color
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.colorPrimary ? shoppingHubToUpdate.colorPrimary.toString() : ''}
                                        placeholder="Primary Color"
                                        onChange={this.handleChange}
                                        name="colorPrimary"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Secondary color
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.colorSecondary ? shoppingHubToUpdate.colorSecondary.toString() : ''}
                                        placeholder="Secondary color"
                                        onChange={this.handleChange}
                                        name="colorSecondary"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Contact Email
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.contactEmail ? shoppingHubToUpdate.contactEmail.toString() : ''}
                                        placeholder="Contact Email"
                                        onChange={this.handleChange}
                                        name="contactEmail"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Support Contact Email
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.supportContactEmail ? shoppingHubToUpdate.supportContactEmail.toString() : ''}
                                        placeholder="Support Contact Email"
                                        onChange={this.handleChange}
                                        name="supportContactEmail"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Zendesk Id
                                    </Label>
                                    <Input
                                        value={shoppingHubToUpdate && shoppingHubToUpdate.zendeskId ? shoppingHubToUpdate.zendeskId.toString() : ''}
                                        placeholder="Zendesk Id"
                                        onChange={this.handleChange}
                                        name="zendeskId"
                                    />
                                </Form.Field>
                            </Grid.Row>
                            <Grid.Row className="col-4">
                                <Form.Field>
                                    <Label>
                                        Phone is Required
                                    </Label>
                                    <Radio
                                        className="black-switch"
                                        checked={shoppingHubToUpdate && shoppingHubToUpdate.phoneRequired ? shoppingHubToUpdate.phoneRequired : false}
                                        onChange={this.handleChangeSwitch}
                                        name="phoneRequired"
                                        toggle
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Email Is Required
                                    </Label>
                                    <Radio
                                        className="black-switch"
                                        checked={shoppingHubToUpdate && shoppingHubToUpdate.emailRequired ? shoppingHubToUpdate.emailRequired : false}
                                        onChange={this.handleChangeSwitch}
                                        name="emailRequired"
                                        toggle
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Receipt Scan
                                    </Label>
                                    <Radio
                                        className="black-switch"
                                        checked={shoppingHubToUpdate && shoppingHubToUpdate.scanEnable ? shoppingHubToUpdate.scanEnable : false}
                                        onChange={this.handleChangeSwitch}
                                        name="scanEnable"
                                        toggle
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Card Restaurant
                                    </Label>
                                    <Radio
                                        className="black-switch"
                                        checked={shoppingHubToUpdate && shoppingHubToUpdate.cardRestaurantEnable ? shoppingHubToUpdate.cardRestaurantEnable : false}
                                        onChange={this.handleChangeSwitch}
                                        name="cardRestaurantEnable"
                                        toggle
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Label>
                                        Customer External Id
                                    </Label>
                                    <Radio
                                        className="black-switch"
                                        checked={shoppingHubToUpdate && shoppingHubToUpdate.customerExternalIdRequired ? shoppingHubToUpdate.customerExternalIdRequired : false}
                                        onChange={this.handleChangeSwitch}
                                        name="customerExternalIdRequired"
                                        toggle
                                    />
                                </Form.Field>
                            </Grid.Row>

                            <Grid.Row className="justify-center">
                                <Button secondary className="button">
                                    <Button.Content visible>Save Hub →</Button.Content>
                                </Button>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Form>
                ) : (
                    <div style={{
                        height: '50vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    >
                        <Loader
                            type="Oval"
                            color="#1f2532"
                            height="30"
                            width="30"
                        />
                    </div>
                )
            }
            <ToastContainer
                newestOnTop
                draggable
                pauseOnHover
                transition={Flip}
            />
            </div>
        )
    }
}

export default HubCreationForm
