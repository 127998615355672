import React from 'react'
import { Button, Form, Transition, Header, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    hasTransactionPatternMatching: PropTypes.bool.isRequired,
    patternMatchingTransactions: PropTypes.arrayOf(PropTypes.shape({
        accessToken: PropTypes.string
    })),
    isValidRegex: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    newPattern: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    testPattern: PropTypes.func.isRequired,
    deletePattern: PropTypes.func.isRequired,
    selectedPattern: PropTypes.shape({
        accessToken: PropTypes.string
    }).isRequired,
    updatePattern: PropTypes.func.isRequired,
    displayMatchingTransactions: PropTypes.func.isRequired,
}


const ManagePatternModal = ({
    hasTransactionPatternMatching,
    patternMatchingTransactions,
    action,
    newPattern,
    isLoading,
    hasErrors,
    displayMatchingTransactions,
    deletePattern,
    updatePattern,
    isValidRegex,
    entity,
    selectedPattern,
    testPattern,
    handleChange,
    modalOpen,
    handleClose
}) => (
    <Transition visible={modalOpen} animation="fade up" duration={350}>
        <Modal
            size={hasTransactionPatternMatching ? 'large' : 'tiny'}
            open={modalOpen}
        >
            <Header content={action} />
            <Modal.Content>
                <p style={{ textAlign: 'center', fontSize: '11px' }}>
                    { action === 'Delete pattern' ? 'Pattern:' : 'Previous pattern:'}
                    <span className="selected-pattern">{selectedPattern.pattern}</span>
                </p>
                {
                    action === 'Edit pattern' ? (
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input
                                    width={4}
                                    size="small"
                                    label="New pattern value"
                                    placeholder="insert your regular expression here"
                                    error={!isValidRegex}
                                   // value={newPattern}
                                    name="newPattern"
                                    onChange={handleChange}
                                />
                                <Button
                                    size="mini"
                                    className="test-pattern-btn"
                                    type={'button'}
                                    loading={isLoading && !hasErrors}
                                    disabled={!newPattern || !isValidRegex}
                                    onClick={() => testPattern(newPattern)}
                                >
                                    {
                                        Object.keys(patternMatchingTransactions).length === 0 ? 'Test regex' : 'Re-test'
                                    }
                                </Button>
                                { !isValidRegex ? <p className="invalid-regex">Invalid regex</p> : '' }
                            </Form.Group>
                        </Form>
                    ) : ''
                }
                {
                    Object.keys(patternMatchingTransactions).length > 0 ?
                        displayMatchingTransactions(patternMatchingTransactions)
                        : ''
                }
            </Modal.Content>
            <Modal.Actions>
                <Button size="mini" onClick={() => handleClose()}>
                    Back
                </Button>
                <Button
                    disabled={action === 'Edit pattern' && (!newPattern || !isValidRegex)}
                    color={action === 'Edit pattern' ? 'green' : 'red'}
                    size="mini"
                    onClick={() => {
                        action === 'Delete pattern'
                            ? deletePattern(selectedPattern.id, entity)
                            : updatePattern(selectedPattern.id, newPattern, entity)
                    }}
                >
                    { action === 'Edit pattern' ? 'Validate' : 'Delete' }
                </Button>
            </Modal.Actions>
        </Modal>
    </Transition>
)

ManagePatternModal.propTypes = propTypes
ManagePatternModal.defaultProps = { patternMatchingTransactions: undefined }

export default ManagePatternModal
