import React, { Component } from 'react'
import 'react-dates/initialize'
import { Header } from 'semantic-ui-react'
import UserManagement from '../UserManagement'


export default class UserManagementPage extends Component {
    state = {
        show: false
    }
    componentWillMount = () => {
        this.setState({
            show: true
        })
    }
    render() {
        return (
            <section className="ui container support-page">
                <Header as="h2" attached="top">
                    User Management
                </Header>
                <UserManagement />
            </section>
        )
    }
}
