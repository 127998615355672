import React, { Component } from 'react'
import 'react-dates/initialize'
import { Form, Button, Message, Modal, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'


import 'react-select/dist/react-select.css'
import 'react-dates/lib/css/_datepicker.css'
import { fetchShoppingHubsList } from '../../../../../actions/hubSetup'


function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].name
        obj.text = arr[i].name
        newArr.push(obj)
    }
    return newArr
}


class HubFields extends Component {
    state = { hub: '', submittedHub: '', shoppingHubsList: [] }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }
    handleSubmit = () => {
        this.setState({ submittedHub: this.state.hub })

        this.state.shoppingHubsList.forEach((shoppingHub) => {
            if (this.state.hub === shoppingHub.name) {
                localStorage.setItem('hubId', shoppingHub.id)
                this.props.saveValues({ hubName: shoppingHub.name })
            }
        })

        this.props.nextStep()
    }
    handleClose = () => {
        this.setState({ modalOpen: false, errors: {} })
    }
    componentWillMount = async () => {
        try {
            await this.props.fetchShoppingHubsList()
            const shoppingHubsList = manageArr(this.props.shoppingHubsList)
            this.setState({ shoppingHubsList })
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false, modalOpen: true })
            }
        }
    }
    render() {
        return (
            <div>
                <Message attached info>
                    <p>Please select a shopping mall to work with</p>
                </Message>
                <Form onSubmit={this.handleSubmit} size={'mini'}>
                    <Form.Group>
                        <Form.Select
                            width={4}
                            name="hub"
                            label="Shopping Mall"
                            value={this.state.value}
                            options={this.state.shoppingHubsList}
                            onChange={this.handleChange}
                            placeholder=""
                            required
                        />
                        <Button  size='mini'type="submit" color="green" disabled={!!this.state.submittedHub || this.state.hub.length === 0}>Next</Button>
                    </Form.Group>

                </Form>
                {
                    this.state.errors && this.state.errors.serverError && (
                        <Modal
                            size="tiny"
                            open={this.state.modalOpen}
                            className="transaction-validation-modal"
                        >
                            <Header content="Error" />
                            <Modal.Content>
                                <p>{this.state.errors.serverError}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="red" onClick={() => this.handleClose()}>
                                    Back
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    )
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    shoppingHubsList: state.hubSetup
})


export default connect(mapStateToProps, {
    fetchShoppingHubsList, // mapDispatchToProps,
})(HubFields)
