import { RETAILER_DETAIL_FETCHED } from '../types'


export default function retailer(state = {}, action = {}) {
    switch (action.type) {
        case RETAILER_DETAIL_FETCHED:
            return { ...state, retailerDetail: action.retailerDetail }
        default: return state
    }
}
