import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Form, Header, Icon, Menu, Message, Modal } from 'semantic-ui-react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import validator from 'validator'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import latinize from 'latinize'
import Highlight from 'react-highlighter'
import styled from 'styled-components'
import 'react-select/dist/react-select.css'
import { clearStoreDetail, fetchStoreDetail } from '../../actions/store'
import { getStoreTransactions } from '../../redux/stores'
import { fetchRetailerDetail, refreshRetailer } from '../../actions/retailer'
import CreateEntityModal from './CreateEntityModal'
import EditEntityModal from './EditEntityModal'
import EntityDetail from './EntityDetail'
import ManagePatternModal from './ManagePatternModal'
import AddPatternModal from './AddPatternModal'


import api from '../../api'
import { displayOneValue } from '../../utils'

const StyledSelectRetailerField = styled.div`
    .react-autosuggest__input {
        width: 350px!important;
        height: 32.28px!important;
        font-weight: 400;
        font-color: 'black'!important;
        font-size: 0.85rem!important;
        border: 1px solid #aaa;
        border-radius: 4px;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0px!important;
        border-bottom-right-radius: 0px!important;
       }
        
    .react-autosuggest__container {
        font-weight: 600;
        font-size: 18px;
        border-radius: 4px;
        width: 25rem;
        position: relative;
        width: 340px!important;
    }
    
    .react-autosuggest__suggestions-container--open {
        width: 350px !important;
        top: 29px;
        display: block;
        position: absolute;
        border: 1px solid #85B7D9;
        font-size: 16px!important;
        text-align: left!important;
        letter-spacing: 1.2px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }
`

const ContainerSuggest = styled.div`
    width: 100%;
    padding-right: 16px;
    padding-left: 6px;
    display: flex;
    justify-content: center;
`

class AutoSuggestInput extends React.Component {
    static propTypes = {
        suggestions: PropTypes.arrayOf(PropTypes.object).isRequired, // array of the list we want to show
        colorHighlight: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired, // placeholder of the input
    }

    state = {
        value: '',
        retailerId: null,
        suggestions: [],
        isCommonSelection: false
    }


    getSuggestions = (value) => {
        const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/[“”‘’]/g, '\'') // trim delete white space before and after the word
        if (escapedValue === '') {
            return []
        }
        const regex = new RegExp(latinize(escapedValue), 'i') // this regex will search fr every character that are contain in the word

        if (validator.isUUID(value)) {
            return this.props.suggestions.filter(retailer => retailer.id === value)
        }

        const suggestion = this.props.suggestions.filter(retailerId => regex.test(latinize(retailerId.name).replace(/[“”‘’]/g, '\'')))
        return suggestion.slice(0, 10)
    }

    getSuggestionValue = (suggestion) => {
        this.setState({ retailerId: suggestion.id })
        this.props.getSuggestionId(suggestion.id)
        return suggestion.name.toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        })
    }

    onChange = (event, { newValue }) => {
        if (newValue === '') {
            this.setState({
                retailerId: null
            })
        }
        this.setState({
            value: newValue,
        })
    };

    render() {
        const { value, suggestions } = this.state
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange,
        }
        const colorHighlight = {
            backgroundColor: 'transparent',
            fontWeight: 600,
            fontSize: '15px',
            color: 'rgb(73, 135, 205)'
        }

        function renderSuggestion(suggestion, { query }) {
            return (

                <Highlight
                    matchStyle={colorHighlight}
                    search={query} // this is where we put the element to highlight
                    ignoreDiacritics // this ignores the accent. If i type "e" he will understand "é" and "e"
                >
                    {suggestion.name.toLowerCase()
                        .split(' ')
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' ')}
                </Highlight>
            )
        }

        return (
            <div>
                <StyledSelectRetailerField>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </StyledSelectRetailerField>
            </div>
        )
    }
}


function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].id
        obj.label = `${arr[i].name}`.trim()
        newArr.push(obj)
    }
    return newArr
}


const initialState = {
    entity: '',
    entityName: '',
    entityOptions: [
        { key: 's', text: 'Store', value: 'store' },
        { key: 'r', text: 'Retailer', value: 'retailer' },
    ],
    entityId: '',
    dataFetched: false,
    selectedPattern: {},
    modalOpen: false,
    action: '',
    patternDeleted: false,
    patternUpdated: false,
    patternList: [],
    addPatternModalOpen: false,
    newPattern: '',
    isValidRegex: true,
    errors: {},
    isLoading: false,
    patternMatchingTransactions: [],
    retailersList: [],
    submittedEntity: '',
    visible: false,
    editEntityModal: false,
    editPatternModal: false,
    deleteEntityModal: false,
    entityUpdated: false,
    createEntityModal: false,
    newEntityType: '',
    entityCreated: false,
    newEntityName: '',
    newEntityId: '',
    isOnline: false,
    currentPageStore: 1,
    currentPageTransaction: 1
}

class EntityManagement extends React.PureComponent {
    static propTypes = {
        storeDetail: PropTypes.shape({
            storeName: PropTypes.string,
            retailerId: PropTypes.string,
            transactionPatterns: PropTypes.array,
            transactions: PropTypes.array,
        }),
        retailerDetail: PropTypes.shape({
            retailerName: PropTypes.string,
            patterns: PropTypes.array,
            transactions: PropTypes.array,
            stores: PropTypes.array,
        }),
        clearStoreDetail: PropTypes.func.isRequired,
        fetchRetailerDetail: PropTypes.func.isRequired,
        fetchStoreDetail: PropTypes.func.isRequired,
        refreshRetailer: PropTypes.func.isRequired,
    }
    static defaultProps = {
        storeDetail: undefined,
        retailerDetail: undefined,
    }
    state = initialState

    componentWillMount = async () => {
        try {
            const data = await api.retailers.fetchRetailersList()
            const retailersList = manageArr(data)
            this.setState({ retailersList })
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false, modalOpen: true })
            }
        }
    }

    componentWillUnmount = () => {
        this.clearState()
    }
    clearState = async () => {
        this.props.clearStoreDetail()
        const data = await api.retailers.fetchRetailersList()
        const retailersList = manageArr(data)
        this.setState({ ...initialState, retailersList })
    }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }
    checkboxChangeHandler = (event, data) => {
        this.setState({ [data.name]: data.checked })
    }
    editEntityModal = () => {
        this.setState({
            editEntityModal: true, visible: true
        })
    }
    createEntityModal = () => {
        this.setState({
            createEntityModal: true, visible: true
        })
    }
    handleSubmit = async () => {
        try {
            if (this.state.entityId === '') return

            if (this.state.entity === 'store') {
                this.setState({ isLoading: true, submittedEntity: this.state.entity, dataFetched: false })

                await Promise.all([
                    this.props.fetchStoreDetail(this.state.entityId),
                    this.props.getStoreTransactions(this.state.entityId)
                ])

                this.setState({
                    isLoading: false,
                    entityName: this.props.storeDetail.storeName,
                    patternList: this.props.storeDetail.transactionPatterns,
                    transactionList: this.props.transactionList,
                    dataFetched: true
                })
            } else if (this.state.entity === 'retailer') {
                this.setState({
                    isLoading: true,
                    submittedEntity: this.state.entity,
                    dataFetched: false
                })
                await this.props.fetchRetailerDetail(this.state.entityId)
                this.setState({
                    isLoading: false,
                    entityName: this.props.retailerDetail.retailerName,
                    patternList: this.props.retailerDetail.patterns,
                    transactionList: this.props.retailerDetail.transactions,
                    storeList: this.props.retailerDetail.stores,
                    dataFetched: true
                })
            }
        } catch (err) {
            this.setState({ dataFetched: false })
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            const { status } = err.response
            if (status === 404) {
                if (err.response.data.code === 'storeNotFound') {
                    return this.setState({
                        errors: { storeNotFound: 'Error: no store found' },
                        isLoading: false,
                        submittedEntity: '',
                        entityId: ''

                    })
                }
                if (err.response.data.code === 'retailerNotFound') {
                    return this.setState({
                        errors: { retailerNotFound: 'Error: no retailer found' },
                        isLoading: false,
                        submittedEntity: '',
                        entityId: ''
                    })
                }
            }
            if (status === 400) {
                if (err.response.data.code === 'invalidRetailerId') {
                    return this.setState({
                        errors: { invalidRetailerId: 'Error: invalid retailer id' },
                        isLoading: false,
                        submittedEntity: '',
                        entityId: ''
                    })
                }
                if (err.response.data.code === 'invalidStoreId') {
                    return this.setState({
                        errors: { invalidStoreId: 'Error: invalid store id' },
                        isLoading: false,
                        submittedEntity: '',
                        entityId: ''
                    })
                }
            }
            if (status === 500) {
                return this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            return 1
        }
        return null
    }
    selectPattern = (pattern, actionType) => {
        this.setState({
            selectedPattern: { id: pattern.id, pattern: pattern.pattern },
            modalOpen: true,
            action: actionType,
            visible: true
        })
    }
    displayMatchingTransactions = (matchingTransactions) => {
        const matchingTransactionsData =
            matchingTransactions.map(({ transactionDate, label, storeId, retailerId, storeAssociationOrigin }) => ({
                'Transaction date': transactionDate,
                Label: displayOneValue(label),
                'Store ID': displayOneValue(storeId),
                'Retailer ID': displayOneValue(retailerId),
                'Store association origin': displayOneValue(storeAssociationOrigin),
            }))
        return (
            <div style={{ fontSize: '11px', marginTop: '30px' }}>
                <ReactTable
                    data={matchingTransactionsData}
                    defaultPageSize={100}
                    columns={[
                        {
                            Header: 'Matching transactions',
                            columns: [
                                { Header: 'Date', accessor: 'Transaction date' },
                                { Header: 'Label', accessor: 'Label', minWidth: 300 },
                                {
                                    Header: 'Store ID',
                                    accessor: 'Store ID',

                                },
                                { Header: 'Retailer ID', accessor: 'Retailer ID' },
                                { Header: 'Store association origin', accessor: 'Store association origin' },
                            ]
                        },

                    ]}

                    style={{
                        height: '340px', // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    getTrProps={(state, rowInfo) => {
                        const hasStoreId = rowInfo && rowInfo.original['Store ID'] !== 'N/A'
                        const hasRetailerId = rowInfo && rowInfo.original['Retailer ID'] !== 'N/A'

                        let backgroundColor
                        if (rowInfo && this.state.entity === 'store') {
                            if (
                                (hasStoreId && rowInfo.original['Store ID'] !== this.state.entityId) ||
                                (hasRetailerId && rowInfo.original['Retailer ID'] !== this.props.storeDetail.retailerId)
                            ) {
                                backgroundColor = '#e539352e'
                            } else if (rowInfo && !hasStoreId && !hasRetailerId) {
                                backgroundColor = '#90ee905e'
                            } else if (rowInfo && !hasStoreId && rowInfo.original['Retailer ID'] === this.props.storeDetail.retailerId) {
                                backgroundColor = '#90ee905e'
                            } else {
                                backgroundColor = 'transparent'
                            }
                        } else if (rowInfo && this.state.entity === 'retailer') {
                            if (hasRetailerId && rowInfo.original['Retailer ID'] !== this.state.entityId) {
                                backgroundColor = '#e539352e'
                            } else if (rowInfo && !hasRetailerId) {
                                backgroundColor = '#90ee905e'
                            }
                        }
                        return ({
                            style: { background: backgroundColor }

                        })
                    }}
                />
            </div>
        )
    }

    showMessage() {
        window.setTimeout(() => {
            this.setState({
                patternDeleted: false,
                patternAdded: false,
                patternUpdated: false,
                entityUpdated: false,
            })
        }, 6000)
        if (this.state.patternDeleted) {
            return <Message attached info color="green"><p>The pattern has been successfully deleted</p></Message>
        }
        if (this.state.patternAdded) {
            return <Message attached info color="green"><p>The pattern has been successfully added</p></Message>
        }
        if (this.state.patternUpdated) {
            return <Message attached info color="green"><p>The pattern has been successfully updated</p></Message>
        }
        if (this.state.entityUpdated) {
            return <Message attached info color="green"><p>The entity has been successfully updated</p></Message>
        }
        if (this.state.entityCreated) {
            return (<Message onDismiss={this.handleDismiss} attached info color="green"><p>The
                entity {this.state.newEntityId} has been successfully created</p></Message>)
        }
        return null
    }

    handleClear = () => {
        this.clearState()
    }
    handleClose = () => {
        this.setState({
            createEntityModal: false,
            editEntityModal: false,
            modalOpen: false,
            addPatternModalOpen: false,
            newPattern: '',
            errors: '',
            patternMatchingTransactions: [],
            visible: false,
            isValidRegex: true,
            newEntityType: '',

        })
    }
    handleErrorClose = () => {
        this.setState({ errors: '' })
    }
    deletePattern = async (patternId, entity) => {
        try {
            this.setState(prevState => ({
                    patternList: prevState.patternList.filter(pattern => pattern.patternId !== patternId),
                    modalOpen: false,
                    patternDeleted: true,
                    patternMatchingTransactions: [],
                    visible: false
                }
            ))
            await api.patterns.delete(patternId, entity)
        } catch (err) {
            console.log(err)
        }
    }
    openAddPatternModal = async () => {
        this.setState({ addPatternModalOpen: true, visible: true })
    }
    addPattern = async (entityId, pattern) => {
        try {
            await api.patterns.add(entityId, pattern, this.state.entity)
            this.handleSubmit()
            this.setState({
                addPatternModalOpen: false,
                patternAdded: true,
                modalOpen: false,
                visible: false,
                newPattern: '',
                patternMatchingTransactions: []
            })
        } catch (err) {
            console.log(err)
        }
    }
    testPattern = async (pattern) => {
        try {
            this.setState({ isLoading: true })
            const response = await api.patterns.test(pattern)
            this.setState({ isLoading: false, patternMatchingTransactions: response })
        } catch (err) {
            this.setState({ isLoading: false })
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            const { status } = err.response
            if (status === 404) {
                if (err.response.data.code === 'noTransactionFound') {
                    return this.setState({
                        errors: { storeNotFound: 'Error: no matching transaction found' },
                        isLoading: false,
                    })
                }
            }
            if (status === 500) {
                return this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            return 1
        }
        return null
    }
    updatePattern = async (patternId, newpattern, patternType) => {
        try {
            await api.patterns.update(patternId, newpattern, patternType)

            this.setState(prevState => ({
                    patternList: prevState.patternList.map((pattern) => {
                        if (pattern.patternId === patternId) {
                            return { ...pattern, pattern: newpattern }
                        }
                        return pattern
                    }),
                    addPatternModalOpen: false,
                    patternUpdated: true,
                    modalOpen: false,
                    newPattern: '',
                    patternMatchingTransactions: [],
                    visible: false
                }
            ))
        } catch (err) {
            console.log(err)
        }
    }

    editStore = async (params) => {
        const fieldsToUpdate = {}
        const address = {}
        if (params === null) {
            this.setState({
                errors: {
                    editStore: 'nothing to update'
                }
            })
            return
        }
        if (params.storeName || params.storeName === '') fieldsToUpdate.name = params.storeName
        if (params.cityName || params.cityName === '') address.locality = params.cityName
        if (params.streetName || params.streetName === '') address.route = params.streetName
        if (params.streetNumber || params.streetNumber === '') address.streetNumber = params.streetNumber
        if (params.googlePlaceId || params.googlePlaceId === '') fieldsToUpdate.googlePlaceId = params.googlePlaceId
        if (params.retailerId || params.retailerId === '') fieldsToUpdate.retailerId = params.retailerId
        if (params.shoppingHubId || params.shoppingHubId === '') fieldsToUpdate.shoppingHubId = params.shoppingHubId
        if (params.isOnline !== null) fieldsToUpdate.isOnline = params.isOnline
        if (Object.keys(address).length) fieldsToUpdate.address = address

        if (!Object.keys(fieldsToUpdate).length && !Object.keys(address).length) {
            this.setState({
                errors: {
                    editStore: 'nothing to update'
                }
            })
            return
        }
        try {
            await api.stores.edit(this.state.entityId, fieldsToUpdate)
            this.handleSubmit()
            this.setState({
                editEntityModal: false,
                visible: false,
                entityUpdated: true,
            })
            // fieldsToUpdate = {}
        } catch (ex) {
            if (!ex.response) { // network error
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            const { status } = ex.response

            if (status === 500) {
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            if (status === 400) {
                if (ex.response.data.code === 'invalidRetailerId') {
                    this.setState({
                        errors: { invalidRetailerId: 'Error: invalid retailer id' },
                    })
                }

                if (ex.response.data.code === 'invalidShoppingHubId') {
                    this.setState({
                        errors: { invalidShoppingHubId: 'Shopping Hub ID doesn\'t exist' }
                    })
                }
            }
        }
    }
    editRetailer = async (params) => {
        const fieldsToUpdate = {}
        if (params === null) {
            this.setState({
                errors: {
                    editRetailer: 'nothing to update'
                }
            })
            return
        }
        if (params.retailerName || params.retailerName === '') fieldsToUpdate.name = params.retailerName

        try {
            await api.retailers.edit(this.state.entityId, fieldsToUpdate)
            this.handleSubmit()
            this.setState({
                editEntityModal: false,
                visible: false,
                entityUpdated: true,
                retailerName: '',
            })
        } catch (ex) {
            if (!ex.response) { // network error
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            const { status } = ex.response
            if (status === 500) {
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
        }
    }
    /* this function was taken from  https://codesandbox.io/s/wjrn8wy3r
     It allows us to sort the data (dataToSort)
     according to the column selected by the user (columnSelected) */
    sortDataTable = (columnSelected, dataToSort) => _.orderBy(
          dataToSort,
          columnSelected.map(sort => (row) => {
              if (row[sort.id] === null || row[sort.id] === undefined) {
                  return -Infinity
              }
              return typeof row[sort.id] === 'string'
                ? row[sort.id].toLowerCase()
                : row[sort.id]
          }),
          columnSelected.map(d => (d.desc ? 'desc' : 'asc'))
        )
    /**
     * @param {number} limit for api call pagination
     * @param {number} offset for api call pagination
     * @param {object} state -- object which contains the currentPage, the index and other information regarding the table
     * */
    fetchTransactionOnChange = async (limit, offset, state) => {
        if (this.state.entity === 'store') { // Condition that prevent crash if store entity was chosen
            return // TODO -- We need to be able to handle pagination for store entity
        }

        const { retailerDetail } = this.props
        const { sorted, pageSize, data } = state
        const copyObject = retailerDetail.transactions
        retailerDetail.transactions = await this.props.fetchRetailerDetail(this.state.entityId, offset, limit, 'transaction')
          .then(transaction => transaction.retailerDetail.map(t => ({
                ...t,
                amount: Number(t.amount) // In order to be able to sort the amount column, we need the value to be typeof number
          })))
        this.props.refreshRetailer(retailerDetail) // updateStore

        const isEqual = _.isEqual(copyObject, retailerDetail.transactions) // compare two objects if true, then we only have to sort the object, else, we need to pass the new values
        this.setState({
            entityName: retailerDetail.retailerName,
            patternList: retailerDetail.patterns,
            transactionList: isEqual ? this.sortDataTable(sorted, data) : retailerDetail.transactions,
            storeList: retailerDetail.stores,
            currentPageTransaction: Math.ceil(retailerDetail.transactionCount / pageSize)
        })
    }
    /**
     * @param {number} limit for api call pagination
     * @param {number} offset for api call pagination
     * @param {object} state -- object which contains the currentPage, the index and other information regarding the table
     * */
    fetchStoreOnChange = async (limit, offset, state) => {
        const { retailerDetail } = this.props
        const { sorted, pageSize, data } = state
        const copyObject = retailerDetail.stores

        retailerDetail.stores = await this.props.fetchRetailerDetail(this.state.entityId, offset, limit, 'stores').then(stores => stores.retailerDetail)
        this.props.refreshRetailer(retailerDetail) // updateStore

        const isEqual = _.isEqual(copyObject, retailerDetail.stores) // if true, then we only have to sort the object, else, we need to pass the new values
        this.setState({
            entityName: retailerDetail.retailerName,
            patternList: retailerDetail.patterns,
            transactionList: retailerDetail.transactions,
            storeList: isEqual ? this.sortDataTable(sorted, data) : retailerDetail.stores,
            currentPageStore: Math.ceil(retailerDetail.storeCount / pageSize)
        })
    }
    createEntity = async (entityId) => {
        try {
            if (this.state.newEntityType === 'store' || (this.state.submittedEntity === 'retailer' && this.state.dataFetched)) {
                const buildEntityQueryObject = param => ({
                    name: param.newEntityName,
                    googlePlaceId: param.googlePlaceId,
                    retailerId: this.state.submittedEntity === 'retailer' ? entityId : param.retailerId,
                    shoppingHubId: param.shoppingHubId,
                    ...param.cityId && { cityId: param.cityId },
                    isOnline: param.isOnline,
                })

                let entityQueryObject = buildEntityQueryObject(this.state)

                if (!Object.keys(entityQueryObject).length) {
                    this.setState({
                        errors: {
                            editStore: 'nothing to update'
                        }
                    })
                    return
                }


                const address = {}

                if (this.state.streetNumber) address.streetNumber = this.state.streetNumber
                if (this.state.streetName) address.route = this.state.streetName

                entityQueryObject = Object.keys(address).length
                    ? { ...entityQueryObject, address }
                    : entityQueryObject

                const data = await api.stores.create(entityQueryObject)

                this.setState({
                    entityCreated: true,
                    newEntityId: data.storeId,
                    createEntityModal: false,
                    isLoading: false,
                    retailerId: '',
                    isOnline: false
                })
                this.handleSubmit()
            } else if (this.state.newEntityType === 'retailer') {
                const buildEntityQueryObject = param => ({
                    name: param.newEntityName,
                    siren: param.sirenNumber,
                })

                const entityQueryObject = buildEntityQueryObject(this.state)
                const { retailerId } = await api.retailers.create(entityQueryObject)

                const data = await api.retailers.fetchRetailersList()
                const retailersList = manageArr(data)
                this.setState({
                    entityCreated: true,
                    newEntityId: retailerId,
                    createEntityModal: false,
                    isLoading: false,
                    retailersList
                })
                this.handleSubmit()
            }
        } catch (ex) {
            if (!ex.response) { // network error
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            const { status } = ex.response
            if (status === 500) {
                this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
            if (status === 409) {
                this.setState({
                    errors: { entityCreationConflict: 'We use google place id (for stores) and siren (for retailers) as external id while creation, this google place id or siren already exists in database, please use an unique for each new entity' },
                    isLoading: false
                })
            }

            if (status === 400) {
                if (ex.response.data.code === 'invalidRetailerId') {
                    this.setState({
                        errors: { invalidRetailerId: 'Error: invalid retailer id' },
                        entityId: ''
                    })
                }
                if (ex.response.data.code === 'invalidCityId') {
                    this.setState({
                        errors: { invalidRetailerId: 'Error: invalid city id' },
                    })
                }
                if (ex.response.data.code === 'invalidSiren') {
                    this.setState({
                        errors: { invalidSiren: 'Error: invalid siren number' },
                    })
                }
                if (ex.response.data.code === 'invalidShoppingHubId') {
                    this.setState({
                        errors: { invalidShoppingHubId: 'Shopping Hub Id doesn\'t exist in database' }
                    })
                }
            }
        }
    }
    handleDismiss = () => {
        this.setState({ entityCreated: false, newEntityId: '' })
    }

    render() {
        const {
            entity,
            dataFetched,
            newPattern,
            isLoading,
            errors,
            patternMatchingTransactions,
            patternAdded,
            patternUpdated,
            entityUpdated,
            entityCreated,
            submittedEntity,
            patternList,
            transactionList,
            storeList,
            addPatternModalOpen,
            isValidRegex,
            action,
            selectedPattern,
            modalOpen,
            editEntityModal,
            createEntityModal,
            newEntityType,
            entityOptions,
            retailersList,
            entityId,
            entityName,
            patternDeleted
        } = this.state
        const hasErrors = Object.keys(errors).length > 0

        const hasTransactionPatternMatching = Object.keys(patternMatchingTransactions).length > 0
        return (
            <div style={{ position: 'relative' }}>
                {
                    patternAdded || patternUpdated || entityUpdated || entityCreated || patternDeleted
                        ? this.showMessage()
                        : <Message attached info>
                            <p>Please select an entity and his id</p>
                        </Message>
                }
                <div>

                    <Menu pointing className="nav-store-retailer-management">

                        <Form onSubmit={this.handleSubmit} size={'tiny'} className="nav-store-retailer-management-form">
                            <Form.Group>
                                <Form.Select
                                    value={entity}
                                    name="entity"
                                    onChange={this.handleChange}
                                    label="Entity type"
                                    required
                                    options={entityOptions}
                                    placeholder="Entity"
                                />
                                {
                                    entity === 'store' && (
                                        <Form.Input
                                            label="Store ID"
                                            placeholder="Store ID"
                                            required
                                            style={{ width: '25rem' }}
                                            name="entityId"
                                            onChange={this.handleChange}
                                        />
                                    )
                                }
                                {

                                    entity === 'retailer' && (
                                        <Form
                                            onSubmit={this.handleSubmit}
                                            size={'tiny'}
                                            className="nav-store-retailer-management-form"
                                        >
                                            <ContainerSuggest>
                                                <AutoSuggestInput
                                                    placeholder={'Select Retailer...'}
                                                    suggestions={retailersList}
                                                    className="virtual-select"
                                                    getSuggestionId={entityId => this.setState({ entityId })} // dbg, faire remonter dans le
                                                /></ContainerSuggest>
                                        </Form>
                                    )
                                }


                                {
                                    !dataFetched ? (
                                        <Button
                                            type="submit"
                                            color="green"
                                            size="mini"
                                            loading={isLoading && !hasErrors}
                                            disabled={!entity || !entityId}
                                        >
                                            <Icon name="search" />Search
                                        </Button>
                                    ) : (
                                        <div className="nav-store-retailer-management-form">
                                            <Button

                                                type="button"
                                                color="blue"
                                                size="mini"
                                                onClick={() => this.handleClear()}
                                                disabled={!entity || !entityId}
                                            >
                                                <Icon name="close" />
                                                Clear data
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="orange"
                                                size="mini"
                                                disabled={!entity || !entityId}
                                            >
                                                <Icon name="refresh" />
                                                Refresh data
                                            </Button>
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </Form>
                    </Menu>

                </div>
                {
                    !dataFetched && !isLoading && (
                        <Menu.Menu>
                            <Icon
                                name="plus"
                                circular
                                style={{
                                    backgroundColor: '#1565c0c7',
                                    color: 'white',
                                    position: 'absolute',
                                    right: '20px',
                                    cursor: 'pointer',
                                    top: '50%'
                                }}
                                onClick={() => this.createEntityModal()}
                            />
                        </Menu.Menu>
                    )
                }

                {
                    dataFetched && (
                        <EntityDetail
                            submittedEntity={submittedEntity}
                            entityDetail={submittedEntity === 'store'
                                ? this.props.storeDetail
                                : this.props.retailerDetail
                            }
                            patternList={patternList}
                            transactionList={transactionList}
                            storeList={submittedEntity === 'retailer' && storeList}
                            editEntityModal={this.editEntityModal}
                            openAddPatternModal={this.openAddPatternModal}
                            selectPattern={this.selectPattern}
                            createEntityModal={this.createEntityModal}
                            fetchTransactionOnChange={this.fetchTransactionOnChange}
                            fetchStoreOnChange={this.fetchStoreOnChange}
                            currentPageStore={this.state.currentPageStore}
                            currentPageTransaction={this.state.currentPageTransaction}
                        />
                    )
                }
                {/* Pattern management modals */}
                <AddPatternModal
                    isOpen={addPatternModalOpen}
                    hasTransactionPatternMatching={hasTransactionPatternMatching}
                    isValidRegex={isValidRegex}
                    hasErrors={hasErrors}
                    newPattern={newPattern}
                    isLoading={isLoading}
                    patternMatchingTransactions={patternMatchingTransactions}
                    displayMatchingTransactions={this.displayMatchingTransactions}
                    entity={entity}
                    entityId={entityId}
                    handleClose={this.handleClose}
                    handleChange={this.handleChange}
                    testPattern={this.testPattern}
                    addPattern={this.addPattern}
                />
                <ManagePatternModal
                    hasTransactionPatternMatching={hasTransactionPatternMatching}
                    patternMatchingTransactions={patternMatchingTransactions}
                    action={action}
                    newPattern={newPattern}
                    isLoading={isLoading}
                    hasErrors={hasErrors}
                    displayMatchingTransactions={this.displayMatchingTransactions}
                    deletePattern={this.deletePattern}
                    updatePattern={this.updatePattern}
                    isValidRegex={isValidRegex}
                    entity={entity}
                    selectedPattern={selectedPattern}
                    testPattern={this.testPattern}
                    handleChange={this.handleChange}
                    modalOpen={modalOpen}
                    handleClose={this.handleClose}
                />

                {/* Entity management modals */}


                {
                    editEntityModal &&
                    <EditEntityModal
                        entity={entity}
                        entityName={entityName}
                        handleChange={this.handleChange}
                        entityDetail={entity === 'retailer' ? this.props.retailerDetail : this.props.storeDetail}
                        handleSubmit={this.handleSubmit}
                        editStore={this.editStore}
                        editRetailer={this.editRetailer}
                        handleClose={this.handleClose}
                    />

                }
                {
                    createEntityModal && (
                        <CreateEntityModal
                            visible={createEntityModal}
                            close={this.handleClose}
                            entityType={newEntityType}
                            submittedEntity={submittedEntity}
                            onChange={this.handleChange}
                            createEntity={this.createEntity}
                            checkboxChangeHandler={this.checkboxChangeHandler}
                            entityId={this.state.entityId}
                        />
                    )
                }


                {
                    errors && hasErrors ? <Modal
                        size="tiny"
                        open={!!errors}
                    >
                        <Header icon="warning circle" content="Error" />
                        <Modal.Content>
                            {errors.storeNotFound && <p>{errors.storeNotFound}</p>}
                            {errors.retailerNotFound && <p>{errors.retailerNotFound}</p>}
                            {errors.serverError && <p>{errors.serverError}</p>}
                            {errors.editStore && <p>{errors.editStore}</p>}
                            {errors.editRetailer && <p>{errors.editRetailer}</p>}
                            {errors.invalidRetailerId && <p>{errors.invalidRetailerId}</p>}
                            {errors.invalidCityId && <p>{errors.invalidCityId}</p>}
                            {errors.invalidStoreId && <p>{errors.invalidStoreId}</p>}
                            {errors.entityCreationConflict && <p>{errors.entityCreationConflict}</p>}
                            {errors.invalidShoppingHubId && <p>{errors.invalidShoppingHubId}</p>}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="red" size="mini" onClick={() => this.handleErrorClose()}>
                                Back
                            </Button>
                        </Modal.Actions>
                    </Modal> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    storeDetail: state.store.storeDetail,
    retailerDetail: state.retailer.retailerDetail,
    transactionList: state.stores.transactionList
})

export default connect(mapStateToProps, {
    fetchStoreDetail,
    fetchRetailerDetail,
    refreshRetailer,
    clearStoreDetail,
    getStoreTransactions
})(EntityManagement)
