import React, { Component } from 'react'
import 'react-dates/initialize'
import { Form, Button, Message } from 'semantic-ui-react'

import 'react-select/dist/react-select.css'
import 'react-dates/lib/css/_datepicker.css'


export default class GiftCardFields extends Component {
    state = { card: '', submittedCard: '' }
    handleChange = (e, { name, value }) => this.setState({ [name]: value/*.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')*/ })
    handleSubmit = () => {
        const { card } = this.state
        this.setState({ submittedCard: card })
        const data = { cardNumber: card.trim() }
        this.props.saveValues(data)
        this.props.nextStep()
    }

    render() {
        const { card } = this.state
        return (

            <div>
                <Form onSubmit={this.handleSubmit} size={'mini'}>
                    <Message attached info>
                        <p>Type your gift card number</p>
                    </Message>
                    <Form.Group width={4}>
                        <Form.Input
                            width={4}
                            placeholder="4894-93XX-XXXX-XXXX"
                            name="card"
                            value={card}
                            type="tel"
                            onChange={this.handleChange}
                            required
                            label="Card number"
                            maxLength="19"
                        />
                        <Button type="submit" disabled={this.state.card.length !== 16} color="green" size={'mini'}>Next</Button>
                    </Form.Group>
                </Form>
            </div>
        )
    }
}
