import {
    GENERAL_STATS_FETCHED,
    STORE_STATS_FETCHED,
    MEAN_BASKET_STATS_FETCHED,
    VISIT_FREQUENCY_STATS_FETCHED,
    VISIT_DELAY_STATS_FETCHED,
    VISIT_SCENARIO_STATS_FETCHED,
    CROSS_SELLS_STATS_FETCHED,
    STORE_SYNERGIES_STATS_FETCHED,
    STORE_RANK_STATS_FETCHED,
    PENETRATION_RATE_STATS_FETCHED,
    PROGRAM_OFFER_STATS_FETCHED
} from '../types'


export default function shoppingHubStats(state = {}, action = {}) {
    switch (action.type) {
    case GENERAL_STATS_FETCHED:
        return action.generalStats.data
    case STORE_STATS_FETCHED:
        return { ...state, ...action.storeStats.data }
    case MEAN_BASKET_STATS_FETCHED:
        return { ...state, ...action.meanBasketStats.data }
    case VISIT_FREQUENCY_STATS_FETCHED:
        return { ...state, ...action.visitFrequencyStats.data }
    case VISIT_DELAY_STATS_FETCHED:
        return { ...state, ...action.visitDelayStats.data }
    case VISIT_SCENARIO_STATS_FETCHED:
        return { ...state, ...action.visitScenarioStats.data }
    case CROSS_SELLS_STATS_FETCHED:
        return { ...state, ...action.crossSellsStats.data }
    case STORE_SYNERGIES_STATS_FETCHED:
        return { ...state, ...action.storeSynergiesStats.data }
    case STORE_RANK_STATS_FETCHED:
        return { ...state, ...action.storeRankStats.data }
    case PENETRATION_RATE_STATS_FETCHED:
        return { ...state, ...action.penetrationRateStats.data }
    case PROGRAM_OFFER_STATS_FETCHED:
        return { ...state, ...action.programOfferStats.data }
    default: return state
    }
}
