import React, { Component } from 'react'
import Chart from 'react-apexcharts'

class StoresShares extends Component {
    render() {
        let series
        let labels
        if (this.props.store_shares.share_online === 0) {
            series = [
                Math.floor(this.props.store_shares.share_in),
                Math.floor(this.props.store_shares.share_out)
            ]

            labels = ['Part physique', 'Cannibalisation physique']
        } else {
            series = [
                Math.floor(this.props.store_shares.share_in),
                Math.floor(this.props.store_shares.share_out),
                Math.floor(this.props.store_shares.share_online)
            ]

            labels = [
                'Part physique',
                'Cannibalisation physique',
                'Cannibalisation en ligne'
            ]
        }

        const options = {
            series,
            labels,
            dataLabels: {
                enabled: true,
                dropShadow: {
                    enabled: true
                }
            },
            chart: {
                type: 'donut'
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter(value) {
                        return `${Math.round(value / 1000)}k€`
                    }
                },
                x: {
                    formatter(value) {
                        return `${Math.round(value / 1000)}k€`
                    }
                }
            },
            plotOptions: {
                pie: {
                    offsetY: 30,
                    donut: {
                        size: '50%'
                    }
                }
            }
        }
        return (
            <Chart series={series} options={options} height={150} type="donut" />
        )
    }
}

export default StoresShares
