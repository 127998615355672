import React, { Component } from 'react'
import {
    Container,
    Card,
    Header,
    Icon,
    List,
    Grid,
    Loader
} from 'semantic-ui-react'

import './statistics.scss'

import Statstab from './StoresStats/StoresFigures/StoresFigures'
import PieShare from './StoresStats/StoresShares/StoresShares'
import api from '../../../api'
import StarsRatings from './StoresStats/LinksRating/StarsRatings'
import SearchGraph from './StoresSearchTool/StoresSearchTool'
import GlobalStatistics from './HubStats/GlobalStatistics'

class Statistics extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            store_shares: null,
            store: null,
            store_links: null,
            isLoading: false
        }
    }

    async getShareData(storeName) {
        this.setState({
            store: this.props.store,
            store_links: this.store_links(this.props.store)
        })
        return api.offers.get('get_share_store/', {
            params: {
                storeName
            }
        }).then((res) => {
            this.setState({
                store_shares: res.data,
                store: this.props.store,
                store_links: this.store_links(this.props.store),
                isLoading: false
            })
        })
    }

    sort_links(links) {
        return links.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
    }

    store_links(store) {
        const links = []
        this.props.links.forEach((link) => {
            if (link.source === store || link.target === store) {
                links.push(link)
            }
        })

        return this.sort_links(links)
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.store !== this.props.store) {
            this.setState({
                isLoading: true,
                store: this.props.store
            })
            this.props.event_emitter.emit('inputValue', this.props.store)
            await this.getShareData(this.props.store)
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <Container fluid className="my_container">
                <Card.Content textAlign="center">
                    <Header textAlign="center" as="h4" className="avenir">
                        <Header.Content>
                            <Header.Content as="h2">
                                <Icon size={'small'} name="chart bar" />
                Statistiques
                            </Header.Content>
                            <SearchGraph
                                placeholder={'Choisir une enseigne...'}
                                graph
                                selected={this.props.selected}
                                event_emitter={this.props.event_emitter}
                            />
                        </Header.Content>
                    </Header>
                    <Card.Description textAlign="left">
                        {!this.props.store ? (
                            <Container className="statloader">
                                <GlobalStatistics allStoresData={this.props.allStoresData} />
                Sélectionnez une boutique sur le graphe.
                            </Container>
                        ) : this.state.isLoading ||
              !this.state.store_links ||
              !this.state.store_shares ||
              !this.props.store_data ? (
                                <Container className="statloader">
                                    <Loader active />
                                </Container>
                            ) : (
              <>
                <Statstab
                    store_data={this.props.store_data}
                    nb_magasins={this.props.nb_magasins}
                />

                <Grid columns="equal">
                    <Grid.Column
                        computer={8}
                        tablet={8}
                        mobile={8}
                        textAlign="center"
                    >
                        <Header className="avenir title" sub>
                            {this.props.levier === 'sessions'
                                ? 'Mellieures synergies'
                                : 'Magasins ayant le plus de clients partagés'}
                        </Header>
                        <List style={{ marginTop: 50 }}>
                            {this.state.store_links.slice(0, 3).map((link, i) => {
                                if (link.source !== this.state.store) {
                                    return (
                                        <StarsRatings
                                            link={link}
                                            key={i}
                                            links={this.props.links}
                                            type="source"
                                        />
                                    )
                                }
                                return (
                                    <StarsRatings
                                        link={link}
                                        key={i}
                                        links={this.props.links}
                                        type="target"
                                    />
                                )
                            })}
                        </List>
                    </Grid.Column>
                    <Grid.Column
                        computer={8}
                        tablet={8}
                        mobile={8}
                        textAlign="center"
                    >
                        <Header className="avenir title" sub>
                      Cannibalisation
                        </Header>

                        <PieShare store_shares={this.state.store_shares} />
                    </Grid.Column>
                </Grid>
              </>
                            )}
                    </Card.Description>
                </Card.Content>
            </Container>
        )
    }
}

export default Statistics
