import axios from 'axios'

// action
export const GET_RECEIPTS_REQUEST = 'GET_RECEIPTS_REQUEST'
export const GET_RECEIPTS_SUCCESS = 'GET_RECEIPTS_SUCCESS'
export const GET_RECEIPTS_ERROR = 'GET_RECEIPTS_ERROR'

export const UPDATE_RECEIPT_REQUEST = 'UPDATE_RECEIPT_REQUEST'
export const UPDATE_RECEIPT_SUCCESS = 'UPDATE_RECEIPT_SUCCESS'
export const UPDATE_RECEIPT_ERROR = 'UPDATE_RECEIPT_ERROR'

const TCHost = process.env.REACT_APP_TC_HOST


const initialState = {
    isFetchingReceipts: false,
    isUpdatingReceipt: false,
    receiptList: [],
    error: null,
    receiptToUpdate: {}
}

export default (state = initialState, action = {}) => {
    const { payload, type } = action
    switch (type) {
        case GET_RECEIPTS_REQUEST: {
            return { ...state,
                isFetchingReceipts: true
            }
        }
        case GET_RECEIPTS_SUCCESS: {
            return { ...state,
                isFetchingReceipts: false,
                receiptList: payload.receiptList
            }
        }
        case GET_RECEIPTS_ERROR: {
            return { ...state,
                error: payload.error,
                isFetchingReceipts: false
            }
        }
        case UPDATE_RECEIPT_REQUEST: {
            return { ...state,
                isUpdatingReceipt: true
            }
        }
        case UPDATE_RECEIPT_SUCCESS: {
            return {
                ...state,
                isUpdatingReceipt: false
            }
        }
        default:break
    }
    return state
}


// get receipt list
export const getReceiptsRequest = () => ({
    type: GET_RECEIPTS_REQUEST
})

export const getReceiptsSuccess = receiptList => ({
    type: GET_RECEIPTS_SUCCESS,
    payload: receiptList
})
export const getReceiptsError = error => ({
    type: GET_RECEIPTS_ERROR,
    payload: error
})


export const updateReceiptRequest = () => ({
    type: UPDATE_RECEIPT_REQUEST
})
export const updateReceiptSuccess = receipt => ({
    type: UPDATE_RECEIPT_SUCCESS,
    payload: receipt
})
export const updateReceiptError = error => ({
    type: UPDATE_RECEIPT_ERROR,
    payload: error
})


/*
* complex actions creators
* */

/**
 * Get receipt list
 *
 * @returns {Function}
 */
export function getReceipts() {
    return async (dispatch, getState) => {
        try {
            dispatch(getReceiptsRequest())
            const { accessToken } = getState().backUser
            const response = await axios.get(`${TCHost}/backoffice/receipts?limit=1000`, {
                headers: { Authorization: `Bearer ${accessToken}` }
            })
            const { receiptList } = response.data
            //   console.log(response.data)
            dispatch(getReceiptsSuccess({ receiptList }))
        } catch (err) {

            dispatch(getReceiptsError(err))
            throw err
        }
    }
}


/**
 *  Update receipt
 *
 * @param values`
 * @returns {Function}
 */
export function updateReceipt(values) {
    return async (dispatch, getState) => {
        try {
            // manage receipt real time editing management
            // manage receipt treatment
            dispatch(updateReceiptRequest())
            const { accessToken } = getState().backUser
            await axios.patch(
                `${TCHost}/backoffice/receipts`,
                { ...values },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            dispatch(updateReceiptSuccess(values))
        } catch (err) {
            dispatch(updateReceiptError(err))
            throw err
        }
    }
}
