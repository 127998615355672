import React from 'react'
import _ from 'lodash'
import { Button, Icon, Menu } from 'semantic-ui-react'
import ReactTable from 'react-table'
import PropTypes from 'prop-types'
import 'react-table/react-table.css'
import { displayOneValue, displayTwoValues } from '../../../utils'


const propTypes = {
    entityDetail: PropTypes.shape({
        storeName: PropTypes.string,
        retailerId: PropTypes.string,
        transactionPatterns: PropTypes.array,
        transactions: PropTypes.array,
        storeList: PropTypes.array,
    }),
    patternList: PropTypes.arrayOf(PropTypes.shape({
        accessToken: PropTypes.string
    })),
    transactionList: PropTypes.arrayOf(PropTypes.shape({
        accessToken: PropTypes.string
    })),
    submittedEntity: PropTypes.string.isRequired,
    editEntityModal: PropTypes.func.isRequired,
    openAddPatternModal: PropTypes.func.isRequired,
    createEntityModal: PropTypes.func.isRequired,
    selectPattern: PropTypes.func.isRequired,
    fetchTransactionOnChange: PropTypes.func.isRequired,
    fetchStoreOnChange: PropTypes.func.isRequired,
    currentPageStore: PropTypes.number.isRequired,
    currentPageTransaction: PropTypes.number.isRequired,
}


const EntityDetail = (
    {
        submittedEntity,
        entityDetail,
        currentPageStore,
        currentPageTransaction,
        fetchTransactionOnChange,
        fetchStoreOnChange,
        patternList,
        transactionList,
        createEntityModal,
        storeList,
        editEntityModal,
        openAddPatternModal,
        selectPattern,
    }) => {
    let patterns = []
    let transactions = []
    let stores = []
    let entityName
    let entityId
    let generalInfo
    if (submittedEntity === 'store' && entityDetail) {
        const formattedAddress =
            `${entityDetail.streetNumber || 'N/A'} ${entityDetail.route || 'N/A'}, ${entityDetail.cityName || 'N/A'} ${entityDetail.country || 'N/A'}`

        entityName = <p style={{ textAlign: 'left', fontSize: '11px' }}>Store:  <span style={{ fontSize: '11px' }}>{entityDetail.storeName}</span></p>
        generalInfo = (
            <div>
                <p>Address:  <span>{formattedAddress}</span></p>
                <p>Google place ID:  <span>{displayOneValue(entityDetail.googlePlaceId)}</span></p>
                <p>Hub:  <span>{displayOneValue(entityDetail.shoppingHubName)}</span></p>
                <p>Retailer:  <span>{displayOneValue(entityDetail.retailerName)}</span></p>
                <p>Retailer ID:  <span>{displayOneValue(entityDetail.retailerId)}</span></p>
                <p>Number of stores:  <span>{displayOneValue(entityDetail.storeCount)}</span></p>
                <p>Number of stores in this city w/o strong label / total  <span>{entityDetail.cityName ? displayTwoValues(entityDetail.numberOfStoresFromSameRetailerInCityWithoutStrongPattern, entityDetail.numberOfStoresFromSameRetailerInCity) : 'N/A'}</span></p>
                <p>Number of claims:  <span>{displayOneValue(entityDetail.numberOfClaims)}</span></p>
                <p>Is Online:  <span>{displayOneValue(entityDetail.isOnline ? entityDetail.isOnline.toString() : 'false')}</span></p>
            </div>
        )
        patterns = patternList.map(transactionPattern => ({
            Pattern: transactionPattern.pattern,
            'Edit pattern': (
                <Button
                    size="mini"
                    style={{ width: '100%' }}
                    onClick={() => selectPattern(transactionPattern, 'Edit pattern')}
                >
                    <Icon name="edit" />
                    Edit</Button>
            ),
            'Delete pattern': (
                <Button
                    size="mini"
                    color={'red'}
                    style={{ width: '100%' }}
                    onClick={() => selectPattern(transactionPattern, 'Delete pattern')}
                ><Icon name="close" />Delete</Button>
            )
        }))
        transactions = transactionList.map(transaction => ({
            id: transaction.id,
            transactionDate: transaction.transactionDate,
            label: transaction.label,
            amount: transaction.amount,
            bankName: transaction.BankAccount && transaction.BankAccount.Bank ? transaction.BankAccount.Bank.name : 'N/A',
            hub: displayOneValue(entityDetail.shoppingHubName),
            retailer: displayOneValue(transaction.retailerId),
            store: displayOneValue(entityDetail.storeName),
            city: displayOneValue(entityDetail.cityName),
            storeAssociationOrigin: displayOneValue(transaction.storeAssociationOrigin),
        }))
    } else if (submittedEntity === 'retailer' && entityDetail) {
        entityName = <p style={{ textAlign: 'left', fontSize: '11px' }}>Retailer:  <span style={{ fontSize: '11px' }}>{entityDetail.retailerName}</span></p>
        entityId = <p style={{ textAlign: 'left', fontSize: '11px' }}>Retailer Id:  <span style={{ fontSize: '11px' }}>{entityDetail.retailerId}</span></p>
        generalInfo = (
            <div>
                <p>Number of stores:  <span>{entityDetail.storeCount}</span></p>
                <p>Number of affected transactions with store id / total:  <span>{entityDetail.affectedTransactionCount} / {entityDetail.transactionCount}</span></p>
            </div>
        )
        patterns = patternList.map(transactionPattern => ({
            Pattern: transactionPattern.pattern,
            'Edit pattern': (
                <Button
                    size="mini"
                    style={{ width: '100%' }}
                    onClick={() => selectPattern(transactionPattern, 'Edit pattern')}
                >
                    <Icon name="edit" />
                    Edit</Button>
            ),
            'Delete pattern': (
                <Button
                    size="mini"
                    color={'red'}
                    style={{ width: '100%' }}
                    onClick={() => selectPattern(transactionPattern, 'Delete pattern')}
                ><Icon name="close" />Delete</Button>
            )
        }))
        transactions = transactionList.map(transaction => ({
            id: transaction.transactionId || transaction.id,
            transactionDate: transaction.transactionDate,
            label: transaction.label,
            amount: Number(transaction.amount),
            bankName: transaction.bankAccount && transaction.bankAccount.bank ? transaction.bankAccount.bank.name : 'N/A',
            hub: transaction.store && transaction.store.shoppingHub ? displayOneValue(transaction.store.shoppingHub.name) : 'N/A',
            retailer: displayOneValue(entityDetail.retailerId),
            store: transaction.store ? displayOneValue(transaction.store.name) : 'N/A',
            city: displayOneValue(transaction.city),
            storeAssociationOrigin: displayOneValue(transaction.storeAssociationOrigin),
        }))
        stores = storeList.map(store => ({
            storeId: displayOneValue(store.storeId),
            storeName: displayOneValue(store.storeName),
            locality: displayOneValue(store.locality),
            formattedAddress: displayOneValue(store.formattedAddress),
            cityId: displayOneValue(store.cityId),
            hubName: store && store.shoppingHub ? displayOneValue(store.shoppingHub.name) : 'N/A',
            isOnline: displayOneValue(store.isOnline)
        }))
    }
    const fetchTransaction = async (state) => {
        if (submittedEntity === 'store') return
        const offset = state.pageSize * state.page
        const limit = state.pageSize

        fetchTransactionOnChange(limit, offset, state)
    }
    const fetchStore = async (state) => {
        const offset = state.pageSize * state.page
        const limit = state.pageSize

        fetchStoreOnChange(limit, offset, state)
    }
    return (
        <section className="entity-management-container">
            <article className="store-general-info-pattern">
                <div style={{ textAlign: 'left', fontSize: '11px', position: 'relative' }}>
                    <div style={{ position: 'absolute', right: '10px' }}>
                        <Icon name="edit" title={'Edit'} link size="large" onClick={() => editEntityModal()} />
                    </div>
                    <h4 style={{ fontSize: '10px', textTransform: 'uppercase', textAlign: 'center', marginTop: '0' }}>General information:</h4>
                    {entityName}
                    {entityId}
                    {generalInfo}
                </div>
                <div style={{ fontSize: '11px' }}>
                    <ReactTable
                        data={patterns}
                        noDataText="No pattern found"
                        columns={[
                            {
                                Header: 'Pattern',
                                columns: [
                                    { accessor: 'Pattern' },

                                ]
                            },
                            {
                                Header: 'Actions',
                                columns: [
                                    { accessor: 'Edit pattern', sortable: false, filterable: false, maxWidth: 100 },
                                    { accessor: 'Delete pattern', sortable: false, filterable: false, maxWidth: 100 },
                                ]
                            }
                        ]}

                        style={{
                            height: '250px', // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                    />
                    <Button floated="right" icon labelPosition="right" style={{ backgroundColor: '#1565c0c7', color: 'white', width: '9rem' }} size="mini" onClick={() => openAddPatternModal()}>
                        <Icon name="plus" /> Add pattern
                    </Button>

                </div>
            </article>
            <article style={{ fontSize: '11px' }}>
                {
                    transactions ?
                        <ReactTable
                            data={transactions}
                            onFetchData={submittedEntity === 'retailer' ? fetchTransaction : undefined} // value undefined is mandatory if we dont want to trigger fetchTransaction
                            manual={submittedEntity !== 'store'} // When false, we dont need to paginate server-side (store page)
                            defaultPageSize={10}
                            pages={submittedEntity === 'retailer' ? currentPageTransaction : undefined} // value undefined mandatory otherwise does not work
                            columns={[
                                {
                                    Header: 'Related transactions',
                                    columns: [
                                        { Header: 'ID', accessor: 'id' },
                                        { Header: 'Date', accessor: 'transactionDate' },
                                        { Header: 'Label', accessor: 'label', minWidth: 300 },
                                        { Header: 'Amount', accessor: 'amount' },
                                        { Header: 'Bank name', accessor: 'bankName' },
                                        { Header: 'Hub', accessor: 'hub' },
                                        { Header: 'Store', accessor: 'store' },
                                        { Header: 'City', accessor: 'city' },
                                        { Header: 'Store association origin', accessor: 'storeAssociationOrigin' },
                                    ],
                                },
                            ]}

                            getTdProps={() => ({
                                    style: {
                                        background: (transactions.retailerId !== entityDetail.retailerId) ? null : 'rgba(229, 57, 53, 0.18)'
                                    },
                                })
                            }

                            style={{
                                height: '280px', // This will force the table body to overflow and scroll, since there is not enough room
                            }}

                        />
                        :
                        ''
                }

            </article>
            {
                submittedEntity === 'retailer' && (
                    <article style={{ fontSize: '11px' }}>
                        <ReactTable
                            data={stores}
                            onFetchData={fetchStore}
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            defaultPageSize={10}
                            pages={currentPageStore} // Set the number of pages available according to the PageSize
                            columns={[
                                {
                                    Header: 'Related stores',
                                    columns: [
                                        { Header: 'ID', accessor: 'storeId' },
                                        { Header: 'Store', accessor: 'storeName' },
                                        { Header: 'Is Online', id: 'isOnline', accessor: d => (d.isOnline ? d.isOnline.toString() : 'false') },
                                        { Header: 'Locality', accessor: 'locality' },
                                        { Header: 'City ID', accessor: 'cityId' },
                                        { Header: 'Formatted address', accessor: 'formattedAddress' },
                                        { Header: 'Hub', accessor: 'hubName' },
                                    ]
                                },
                            ]}
                            style={{
                                height: '280px', // This will force the table body to overflow and scroll, since there is not enough room
                            }}
                        />
                        <Menu.Menu>
                            <Button floated="right" color="blue" onClick={() => createEntityModal()} style={{ width: '9rem' }} icon labelPosition="right" size="mini">
                                <Icon name="plus" /> Add store
                            </Button>
                        </Menu.Menu>
                    </article>
                )
            }
        </section>

    )
}
EntityDetail.propTypes = propTypes
EntityDetail.defaultProps = { entityDetail: undefined, patternList: undefined, transactionList: undefined }

export default EntityDetail
