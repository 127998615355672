import React, { Component } from 'react'
import { List, Popup } from 'semantic-ui-react'

import MyRating from './MyRating'
import '../../statistics.scss'

const percentile = require('percentile')

class StarsRatings extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {}
    }

    render() {
        return (
            <List.Item key={this.props.link[this.props.type]}>
                <List.Content floated="left">
                    {this.props.link[this.props.type]}
                </List.Content>
                <Popup
                    trigger={
                        <List.Content floated="right">
                            {this.props.link.value >
              percentile(90, this.props.links, item => item.value).value ? (
                                    <MyRating rate={5} />
                                ) : this.props.link.value >
                percentile(70, this.props.links, item => item.value).value ? (
                                        <MyRating rate={4} />
                                    ) : this.props.link.value >
                percentile(50, this.props.links, item => item.value).value ? (
                                            <MyRating rate={3} />
                                        ) : this.props.link.value >
                percentile(30, this.props.links, item => item.value).value ? (
                                                <MyRating rate={2} />
                                            ) : (
                                                <MyRating rate={1} />
                                            )}
                        </List.Content>
                    }
                    content={
                        'mesure la propension des clients à aller dans les 2 boutiques sachant qu\'ils sont allés dans l\'une ou l\'autre.'
                    }
                />
            </List.Item>
        )
    }
}

export default StarsRatings
