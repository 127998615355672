import { STORE_DETAIL_FETCHED, STORE_DETAIL_CLEARED } from '../types'


export default function store(state = {}, action = {}) {
    switch (action.type) {
        case STORE_DETAIL_FETCHED:
        return { ...state, storeDetail: action.storeDetail }
        case STORE_DETAIL_CLEARED:
            return { ...state, storeDetail: {} }
        default: return state
    }
}
