import React from 'react'

import {
    Container,
    Icon
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import api from '../../api'
import BackOfficeHeader from '../Layout/BackOfficeHeader'


// create your cellRenderer as a React component
class NameCellRenderer extends React.Component {
    render() {
        // put in render logic
        return <Icon name="pencil alternate" size="large" />
    }
}


class HubCreationForm extends React.Component {
    state = {
        hubs: [],
        columnDefs: [{
            headerName: 'Name', field: 'name', width: 280
        }, {
            headerName: 'Created', field: 'created_at', width: 280
        }, {
            headerName: 'Updated', field: 'updated_at', width: 280
        }],
    }

    async componentWillMount() {
        const hubs = await api.shoppingHubs.fetchShoppingHubsList()
        this.setState({
            hubs,
            columnDefs: [{
                headerName: 'Name', field: 'name', width: 280
            }, {
                headerName: 'Created', field: 'created_at', width: 280
            }, {
                headerName: 'Updated', field: 'updated_at', width: 280
            }, {
                headerName: 'Modify',
                cellRendererFramework: NameCellRenderer,
                field: 'id',
                cellRendererParams: {
                    prop1: 'yeah'
                }
            }],
        })
    }

    onCellClicked = async (event) => {
        const { history } = this.props
        // interaction column
        if (event.colDef.headerName === 'Modify') {
            history.push(`/hub-update/${event.data.id}`)
        }
        return undefined
    }

    getRowData = () => {
        const { hubs } = this.state
        return hubs.map(hub => ({
            id: hub.id,
            name: hub.name,
            created_at: moment(hub.created_at).format('DD/MM/YYYY HH:mm'),
            updated_at: moment(hub.updated_at).format('DD/MM/YYYY HH:mm'),
        }))
    }


    render() {
        const { hubs } = this.state
        return (
            <div>
                <BackOfficeHeader
                    title="Modify Shopping Hub"
                    cta="New Shopping Hub +"
                    to="/hub-creation"
                />
                <Container>
                    <div
                        className="ag-theme-material"
                        style={{
                            height: '73vh',
                            width: '100%',
                            marginTop: '30px'
                        }}
                    >
                    {
                        hubs.length > 0 ?
                            <AgGridReact
                                enableSorting
                                enableFilter
                                enableCellChangeFlash
                                pivotPanelShow={'always'}
                                enableColResize
                                rowSelection="single"
                                rowData={this.getRowData()}
                                columnDefs={this.state.columnDefs}
                                onCellClicked={this.onCellClicked}
                                onGridReady={params => this.gridApi = params.api}
                            />
                            : <div style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                minHeight: '70vh',
                                alignItems: 'center'

                            }}
                            >
                                <Loader
                                    type="Oval"
                                    color="#0000000f"
                                    height="60"
                                    width="60"
                                />
                            </div>
                    }
                    </div>
                </Container>
            </div>

        )
    }
}

export default withRouter(HubCreationForm)
