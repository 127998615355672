import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Form, Message } from 'semantic-ui-react'
import Validator from 'validator'
import './LoginForm.css'

const LoginButton = styled.button`
    width: 100%;
    border-radius: 6px!important;
    height: 42px;
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important;
    cursor: pointer;
    background-color: #01738d!important;
    color: white!important;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
    transition: .1s ease all;
    outline: none;
    &:hover {
        background-color: #01738ddb!important
    } 
`

class LoginForm extends Component {
    static propTypes = {
        submit: PropTypes.func.isRequired,
    }
    state = {
        data: {
            email: '',
            password: ''
        },
        loading: false,
        errors: {}
    }

    // i store previous state  and affect only data that changed from the previous state
    onChange = ({ target: { name, value } }) => this.setState({
        data: { ...this.state.data, [name]: value } // for universal text fields
    })

    // data validation
    onSubmit = async () => {
        // validate function will return error object, if no errors, it will be empty
        const errors = this.validate(this.state.data)
        this.setState({ errors })

        // Test if the keys of the object is empty
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            try {
                return await this.props.submit(this.state.data)
            } catch (error) {
                if (!error.response) { // network error
                    return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
                }
                const { status } = error.response

                if (status === 404) {
                    return this.setState({ errors: { email: 'No account is associated with these identifiers' }, loading: false })
                } else if (status === 401) {
                    return this.setState({ errors: { password: 'Invalid password' }, loading: false })
                } else if (status === 400 && error.response.data.code === 'blockedAccount') {
                    return this.setState({ errors: { blockedAccount: 'Blocked account' }, loading: false })
                } else {
                    return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
                }
            }
        }
        return 1
    }

    validate = (data) => {
        const errors = {}
        if (!Validator.isEmail(data.email)) errors.email = 'Ce champ est obligatoire.'
        if (!data.password) errors.password = 'Ce champ est obligatoire.'
        return errors // return empty errors if fields are valid
    }

    render() {
        const { data, errors, loading } = this.state
        return (
            <Form onSubmit={this.onSubmit} loading={loading} id={'formConnection'}>
                { /* check if the data errors object contains something */ }
                <Form.Field error={!!errors.email} > {/* convert to boolean (if not empty => true) */}
                    <label htmlFor="email">E-mail</label>
                    <input
                        type="email"
                        id={'email'}
                        name={'email'}
                        placeholder={'example@example.com'}
                        value={data.email}
                        onChange={this.onChange}
                    />

                </Form.Field>

                <Form.Field error={!!errors.password}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id={'password'}
                        name={'password'}
                        placeholder={'Password'}
                        value={data.password}
                        onChange={this.onChange}
                    />
                </Form.Field>
                <LoginButton className={'login-button'}>Login</LoginButton>
                { errors.email && <Message negative size={'tiny'}>
                    <Message.Header>{errors.email}</Message.Header>
                    <p>Please check your login details</p>
                </Message> }
                { errors.password && <Message negative size={'tiny'}>
                    <Message.Header>{errors.password}</Message.Header>
                    <p>Please check your login details</p>
                </Message> }
                { errors.serverError && <Message negative size={'tiny'}>
                    <Message.Header>{errors.serverError}</Message.Header>
                    <p>Please try again</p>
                </Message> }
                { errors.blockedAccount && <Message negative size={'tiny'}>
                    <Message.Header>{errors.blockedAccount}</Message.Header>
                    <p>Please ask an admin to reset your password</p>
                </Message> }
            </Form>
        )
    }
}

export default LoginForm
