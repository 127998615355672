import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Header, Modal } from 'semantic-ui-react'



const initialState = {
    storeName: null,
    cityName: null,
    streetName: null,
    streetNumber: null,
    retailerId: null,
    googlePlaceId: null,
    retailerName: null
}

class EditEntitymodal extends React.Component {
    static propTypes = {
        entityDetail: PropTypes.shape({
            storeName: PropTypes.string,
            retailerId: PropTypes.string,
            transactionPatterns: PropTypes.array,
            transactions: PropTypes.array,
        }),
        entityName: PropTypes.string.isRequired,
        entity: PropTypes.string.isRequired,
        handleClose: PropTypes.func.isRequired,
        editRetailer: PropTypes.func.isRequired,
        editStore: PropTypes.func.isRequired,
    }
    state = {}
    componentWillMount = async () => {
        this.setState(initialState)
    }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }
    checkboxChangeHandler = (event, data) => {
        this.setState({ [data.name]: data.checked });
    };

    render() {
        const {
            entity,
            entityName,
            entityDetail,
            editStore,
            editRetailer,
            handleClose
        } = this.props
        return (
            <Modal
                size={'tiny'}
                open
            >
                <Header icon="edit" content={`Edit ${entity}`} />
                <Modal.Content >
                    {
                        entity === 'store' ? (
                            <Form>
                                <Form.Input
                                    size="small"
                                    label="Store name"
                                    name="storeName"
                                    defaultValue={entityName}
                                    placeholder="Store name"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="City"
                                    name="cityName"
                                    defaultValue={
                                        (entityDetail && entityDetail.cityName !== 'N/A') && entityDetail.cityName
                                    }
                                    placeholder="City"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="Street name"
                                    name="streetName"
                                    placeholder="Street name"
                                    defaultValue={
                                        (entityDetail && entityDetail.streetName !== 'N/A') && entityDetail.route
                                    }
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="Street number"
                                    name="streetNumber"
                                    placeholder="street number"
                                    defaultValue={
                                        (entityDetail && entityDetail.streetNumber !== 'N/A') && entityDetail.streetNumber
                                    }
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="Retailer ID"
                                    name="retailerId"
                                    placeholder="Retailer ID"
                                    defaultValue={
                                        (entityDetail && entityDetail.retailerId !== 'N/A') && entityDetail.retailerId
                                    }
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="Google place ID"
                                    name="googlePlaceId"
                                    placeholder="Google place ID"
                                    defaultValue={
                                        (entityDetail && entityDetail.googlePlaceId !== 'N/A') && entityDetail.googlePlaceId
                                    }
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    size="small"
                                    label="Shopping Hub ID"
                                    name="shoppingHubId"
                                    placeholder="Shopping Hub ID"
                                    defaultValue={
                                        (entityDetail && entityDetail.shoppingHubId !== 'N/A') && entityDetail.shoppingHubId
                                    }
                                    onChange={this.handleChange}
                                />
                                <Form.Checkbox
                                    toggle
                                    size="small"
                                    label="Is Online"
                                    name="isOnline"
                                    placeholder="Is Online"
                                    defaultChecked={
                                        (entityDetail && entityDetail.isOnline !== null) && entityDetail.isOnline
                                    }
                                    onChange={this.checkboxChangeHandler}
                                />
                            </Form>
                        ) : (
                            <Form>
                                <Form.Input
                                    size="small"
                                    label="Retailer name"
                                    name="retailerName"
                                    placeholder="Retailer name"
                                    onChange={this.handleChange}
                                />
                            </Form>
                        )
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button size="mini" onClick={() => handleClose()}>
                            Back
                    </Button>
                    <Button
                        color="blue"
                        size="mini"
                        type="submit"
                        onClick={() => {
                            entity === 'store' ? editStore(this.state) : editRetailer(this.state)
                        }}
                    >
                            Validate
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}


export default EditEntitymodal
