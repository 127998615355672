import React from 'react'
import { Header } from 'semantic-ui-react'

const ForbiddenPage = () => (
    <section className="homePage">
        <Header as="h2" attached="top" className="support">
                Dashboard TC
        </Header>
        <div>
            <p Class="error">Forbidden access</p>
        </div>
    </section>
)


export default ForbiddenPage

