import React from 'react'
import { Button, Form, Header, Modal, Transition, Label, Message } from 'semantic-ui-react'

class UpdateUserModal extends React.Component {
    componentWillMount() {
        this.setState({
            currentFunctionalities: this.props.defaultFunctionalitiesValues,

        })
    }
    render() {
        const hasFormError = !!(this.props.formError && this.props.formError.required)
        return (
            <Transition visible={this.props.visible} animation="fade up" duration={350}>
                <Modal
                    size={'tiny'}
                    open={this.props.visible}
                >
                    <Header icon="pencil" content={'Update a user'} />
                    <Modal.Content >
                        <Message info={!hasFormError} negative={hasFormError} >
                            <Message.Header>Update submission rules</Message.Header>
                            <Message.List items={['At least one field is required for udpate a user, the password or the functionalities']} />
                        </Message>
                        {
                            !!(this.props.formError && this.props.formError.serverError) && (
                                <Message negative attached>
                                    <Message.Header>{this.props.formError.serverError}</Message.Header>
                                </Message>
                            )
                        }
                        <Form onSubmit={this.props.onSubmit} >
                            <Form.Field>
                                <label>Change password</label>
                                <Form.Input
                                    size="small"
                                    onChange={this.props.onChange}
                                    error={!!(this.props.inputError && this.props.inputError.updatedPassword)}
                                    required
                                    autoComplete="new-password"
                                    type={'password'}
                                    name="updatedPassword"
                                    placeholder="Password"
                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.updatedPassword) &&
                                    <Label pointing color="red">{this.props.inputError.updatedPassword}</Label>
                                }
                            </Form.Field>
                            <Form.Field>
                                <label>Confirm password</label>
                                <Form.Input
                                    size="small"
                                    onChange={this.props.onChange}
                                    error={!!(this.props.inputError && this.props.inputError.passwordConfirm)}
                                    required
                                    autoComplete="new-password"
                                    type={'password'}
                                    name="updatedPasswordConfirm"
                                    placeholder="Confirm password"
                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.passwordConfirm) &&
                                    <Label pointing color="red">{this.props.inputError.passwordConfirm}</Label>
                                }
                            </Form.Field>
                            <Form.Field>
                                <label>Functionalities</label>
                                <Form.Dropdown
                                    placeholder="Functionalities"
                                    name="updatedFunctionalities"
                                    onChange={this.props.onChange}
                                    fluid
                                    multiple
                                    defaultValue={this.state.currentFunctionalities}
                                    search
                                    selection
                                    options={this.props.functionalitiesOptions}
                                    error={!!(this.props.inputError && this.props.inputError.updatedFunctionalities)}

                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.updatedFunctionalities) &&
                                    <Label pointing color="red">{this.props.inputError.updatedFunctionalities}</Label>
                                }
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="mini" onClick={() => this.props.closeModal()}>
                            Back
                        </Button>
                        <Button
                            color="blue"
                            size="mini"
                            loading={this.props.loadingBtn}
                            type="submit"
                            onClick={() => this.props.onSubmit()}
                        >
                            Update
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>
        )
    }
}

export default UpdateUserModal
