import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Label, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { connect } from 'react-redux'
import { logout } from '../../../actions/auth'
import tcLogo from '../../../img/TransactionConnect.svg'
import './MainNavigation.css'
import pJson from '../../../../package.json'


class MainNavigation extends Component {
    static propTypes = {
        backUser: PropTypes.shape({
            accessToken: PropTypes.string.isRequired
        }).isRequired,
        logout: PropTypes.func.isRequired
    }

    state = { activeItem: 'home', isOpen: false, layoutMode: this.getLayoutMode() }
    componentDidMount() {
        window.addEventListener('resize', this.onResize)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }
    onResize = () => {
        this.setState({ layoutMode: this.getLayoutMode() })
    }
    getLayoutMode() {
        return window.innerWidth > 768 ? 'desktop' : 'mobile'
    }
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })


    render() {
        let ribbonColor
        if (process.env.REACT_APP_NODE_ENV === 'development') {
            ribbonColor = 'blue'
        } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
            ribbonColor = 'orange'
        } else if (process.env.REACT_APP_NODE_ENV === 'production') {
            ribbonColor = 'red'
        }
        const backUserFunctionalities = jwtDecode(this.props.backUser.accessToken).functionalities.map(functionality => Object.keys(functionality)[0])
        let transactionRecords
        let pinpoint
        let storeRetailerManagemment
        let customerSupport
        let userManagement
        let statisticBoard
        let receiptsScan

        if (backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1) {
            customerSupport = (
                <Menu.Item>
                    <Menu.Header>Customer support</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/customerSupport/manualAssociation'}
                            name="customer-support"
                            active={this.state.activeItem === 'customer-support' || this.props.location.pathname === '/customerSupport/manualAssociation'}
                            onClick={this.handleItemClick}
                        >
                            Manual association
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/decrypt-customer'}
                            name="decrypt-customer"
                            active={this.state.activeItem === 'decrypt-customer' || this.props.location.pathname === '/decrypt-customer'}
                            onClick={this.handleItemClick}
                        >
                            Decrypt Customer
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/customer-search-support'}
                            name="customer-search-support"
                            active={this.state.activeItem === 'customer-search-support' || this.props.location.pathname === '/customer-search-support'}
                            onClick={this.handleItemClick}
                        >
                            Customer Support
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        if (backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1) {
            receiptsScan = (
                <Menu.Item>
                    <Menu.Header>Receipts Scan</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/receipts-management'}
                            name="receipts-management"
                            active={this.state.activeItem === 'receipts-management' || this.props.location.pathname === '/receipts-management'}
                            onClick={this.handleItemClick}
                        >
                            Receipts Management
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        const homepage = (
            <div style={{
                marginBottom: '80px'
            }}>

                <img src={tcLogo} alt={'Transaction connect'} />
                <Label as='a' color={ribbonColor} ribbon='right'>
                    {process.env.REACT_APP_NODE_ENV}{' '}v{pJson.version}
                </Label>
            </div>
        )

        if (backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1) {
            transactionRecords = (
                <Menu.Item>
                    <Menu.Header>Hub setup</Menu.Header>

                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/hub-setup-initial-transaction'}
                            name="hub-setup-init"
                            active={this.state.activeItem === 'hub-setup-init' || this.props.location.pathname === '/hub-setup-initial-transaction'}
                            onClick={this.handleItemClick}
                        >
                            Initial transaction
                            </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/offers'}
                            name="offers"
                            active={this.state.activeItem === 'offers' || this.props.location.pathname === '/offers'}
                            onClick={this.handleItemClick}
                        >
                            Offers
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/hub-creation'}
                            name="hub-creation"
                            active={this.state.activeItem === 'hub-creation' || this.props.location.pathname === '/hub-creation'}
                            onClick={this.handleItemClick}
                        >
                            Create New Hub
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/hub-management'}
                            name="hub-management"
                            active={this.state.activeItem === 'hub-management' || this.props.location.pathname === '/hub-management'}
                            onClick={this.handleItemClick}
                        >
                            Hubs Management
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        if (backUserFunctionalities.indexOf('TRANSACTION_PINPOINT') > -1) {
            pinpoint = (
                <Menu.Item>
                    <Menu.Header>Pinpoint</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/manual-association'}
                            name="manual-association"
                            active={this.state.activeItem === 'manual-association' || this.props.location.pathname === '/manual-association'}
                            onClick={this.handleItemClick}
                        >
                            Manual association
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={'/manual-dissociation'}
                            name="manual-dissociation"
                            active={this.state.activeItem === 'manual-dissociation' || this.props.location.pathname === '/manual-dissociation'}
                            onClick={this.handleItemClick}
                        >
                            Manual dissociation
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        if (backUserFunctionalities.indexOf('STORE_RETAILER_MANAGEMENT') > -1) {
            storeRetailerManagemment = (
                <Menu.Item>
                    <Menu.Header>Store & retailer</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/entity-management'}
                            name="store-retailer-management"
                            active={this.state.activeItem === 'entity-management' || this.props.location.pathname === '/entity-management'}
                            onClick={this.handleItemClick}
                        >
                            Management
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        if (backUserFunctionalities.indexOf('ADMIN') > -1) {
            userManagement = (
                <Menu.Item>
                    <Menu.Header>Admin dashboard</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/user-management'}
                            name="user-management"
                            active={this.state.activeItem === 'user-management' || this.props.location.pathname === '/user-management'}
                            onClick={this.handleItemClick}
                        >
                            Users Management
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        }
        // TODO -- Fix customer Stats page. When it is fixed, we can uncomment this section
        // eslint-disable-next-line no-lone-blocks
        {/* if (backUserFunctionalities.indexOf('STATISTIC_BOARD') > -1) {
            statisticBoard = (
                <Menu.Item>
                    <Menu.Header>Stats</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            as={Link}
                            to={'/statistic-board'}
                            name="statistic-board"
                            active={this.state.activeItem === 'statistic-board' || this.props.location.pathname === '/statistic-board'}
                            onClick={this.handleItemClick}
                        >
                            Customer stats
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            )
        } */}
        return (
            <nav className="main-nav">

                {
                    <Menu vertical inverted pointing>
                        {homepage}
                        {userManagement}
                        {statisticBoard}
                        {customerSupport}
                        {receiptsScan}
                        {pinpoint}
                        {transactionRecords}
                        {storeRetailerManagemment}
                    </Menu>
                }

            </nav>
        )
    }
}

const mapStateToProps = state => ({
    backUser: state.backUser,
})


export default connect(mapStateToProps, { logout })(MainNavigation)

