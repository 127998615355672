import React from 'react'
import { Header } from 'semantic-ui-react'
import ManualDissociationForm from '../forms/ManualDissociationForm'


const ManualDissociationPage = () => (
    <section className="ui container support-page">
        <Header as='h2' attached="top" className="transactions-disassociation">
            Manual dissociation
        </Header>
        <ManualDissociationForm />
    </section>
)

export default ManualDissociationPage
