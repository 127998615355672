import axios from 'axios'
import moment from "moment";
import { string } from 'prop-types';

// action
export const GET_CUSTOMER_INFO_REQUEST = 'GET_CUSTOMER_INFO_REQUEST'
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS'
export const GET_CUSTOMER_INFO_ERROR = 'GET_CUSTOMER_INFO_ERROR'

export const GET_CUSTOMER_ID_LIST_REQUEST = 'GET_CUSTOMER_ID_LIST_REQUEST'
export const GET_CUSTOMER_ID_LIST_SUCCESS = 'GET_CUSTOMER_ID_LIST_SUCCESS'
export const GET_CUSTOMER_ID_LIST_ERROR = 'GET_CUSTOMER_ID_LIST_ERROR'

export const GET_CUSTOMER_IBAN_REQUEST = 'GET_CUSTOMER_IBAN_REQUEST'
export const GET_CUSTOMER_IBAN_SUCCESS = 'GET_CUSTOMER_IBAN_SUCCESS'
export const GET_CUSTOMER_IBAN_ERROR = 'GET_CUSTOMER_IBAN_ERROR'

export const GET_SHOPPING_HUB_NAME_REQUEST = 'GET_SHOPPING_HUB_NAME_REQUEST'
export const GET_SHOPPING_HUB_NAME_SUCCESS = 'GET_SHOPPING_HUB_NAME_SUCCESS'
export const GET_SHOPPING_HUB_NAME_ERROR = 'GET_SHOPPING_HUB_NAME_ERROR'

export const DELETE_STATE = 'DELETE_STATE'
export const RESTORE_STATE = 'RESTORE_STATE'

const initialState = {
    customerInfo: null,
    bankConnection: null,
    email: '',
    cashback: '',
    waiting: '',
    rewardPayOutId: '',
    isFetchingCustomerInfo: false,
    isFetchingCustomerIdList: false,
    isFetchIban: false,
    transactionsList: null,
    backHome: true,
    isFetchingShoppingHubName: false,
    shoppingHubName: null
}

export default (state = initialState, action = {}) => {
    const { payload, type } = action
    switch (type) {
    case GET_CUSTOMER_INFO_REQUEST: {
        return { ...state,
            isFetchingCustomerInfo: true,
        }
    }
    case GET_CUSTOMER_INFO_SUCCESS: {
        return { ...state,
            customerInfo: payload[0],
            bankConnection: payload[1],
            cashback: payload[2],
            transactionsList: payload[3]
        }
    }
    case GET_CUSTOMER_INFO_ERROR: {
        return { ...state,
            error: payload.error,
            isFetchingCustomerInfo: false,
        }
    }


    case GET_CUSTOMER_IBAN_REQUEST: {
        return { ...state,
            isFetchIban: true
        }
    }
    case GET_CUSTOMER_IBAN_SUCCESS: {
        return { ...state,
            isFetchIban: true,
            iban: payload
        }
    }

    case GET_CUSTOMER_IBAN_ERROR: {
        return { ...state,
            error: payload.error,
            isFetchIban: false
        }
    }

    case GET_CUSTOMER_ID_LIST_REQUEST: {
        return { ...state,
            isFetchingCustomerIdList: true,
        }
    }
    case GET_CUSTOMER_ID_LIST_SUCCESS: {
        return { ...state,
            isFetchingCustomerIdList: true,
            customerIdList: payload
        }
    }
    case GET_CUSTOMER_ID_LIST_ERROR: {
        return { ...state,
            isFetchingCustomerIdList: false
        }
    }

    case DELETE_STATE: {
        return { ...initialState,
            backHome: false
            }
    }
    case RESTORE_STATE: {
        return { ...state,
            backHome: true
        }
    }
    case GET_SHOPPING_HUB_NAME_REQUEST: {
        return { ...state,
            isFetchingShoppingHubName: true,
        }
    }
    case GET_SHOPPING_HUB_NAME_SUCCESS: {
        return { ...state,
            shoppingHubName: payload.data.name,
        }
    }
    case GET_SHOPPING_HUB_NAME_ERROR: {
        return { ...state,
            error: payload.error,
            isFetchingShoppingHubName: false
        }
    }
    default:break
    }
    return state
}

// action creators
export const getCustomerInfoRequest = customerId => ({
    type: GET_CUSTOMER_INFO_REQUEST,
    payload: customerId
})
export const getCustomerInfoError = error => ({
    type: GET_CUSTOMER_INFO_ERROR,
    payload: error
})
export const getCustomerInfoSuccess = (responseCustomer, reponseBankConnections, reponseRewards, reponseTransaction) => ({
    type: GET_CUSTOMER_INFO_SUCCESS,
    payload: [responseCustomer.data, reponseBankConnections.data, reponseRewards.data, reponseTransaction.data]
})

export const getCustomerIdListRequest = () => ({
    type: GET_CUSTOMER_ID_LIST_REQUEST,
})
export const getCustomerIdListError = error => ({
    type: GET_CUSTOMER_ID_LIST_ERROR,
    payload: error
})
export const getCustomerIdListSuccess = result => ({
    type: GET_CUSTOMER_ID_LIST_SUCCESS,
    payload: result
})


export const getFetchIbanRequest = mgPayoutId => ({
    type: GET_CUSTOMER_IBAN_REQUEST,
    payload: (mgPayoutId)
})

export const getFetchIbanSuccess = result => ({
    type: GET_CUSTOMER_IBAN_SUCCESS,
    payload: result
})

export const getFetchIbanError = error => ({
    type: GET_CUSTOMER_IBAN_ERROR,
    payload: error
})


export const getShoppingHubNameRequest = (shoppingHubId) => ({
    type: GET_SHOPPING_HUB_NAME_REQUEST,
    payload: (shoppingHubId)
})

export const getShoppingHubNameSuccess = result => ({
    type: GET_SHOPPING_HUB_NAME_SUCCESS,
    payload: result
})

export const getShoppingHubNameError = error => ({
    type: GET_SHOPPING_HUB_NAME_ERROR,
    payload: error
})


export const deleteState = () => ({
    type: DELETE_STATE
})
export const restoreState = () => ({
    type: RESTORE_STATE
})

/*
* complex actions creators
* */


/**
 * Used to get information of a customer from it's customer id
 *
 * @param customerId
 * @returns {Function}
 */
export const fetchInfoFromId = customerId => async (dispatch, getState) => {
    const dateLimit = moment().add(-3, 'month').format('YYYY-MM-DD')
    try {
        dispatch(getCustomerInfoRequest(customerId))
        const { accessToken } = getState().backUser
        const headers = { headers : {  Authorization: `Bearer ${accessToken}` }};
        const url = `${process.env.REACT_APP_TC_HOST}/backoffice/customers/${customerId}/`

        const getReponseCustomer = () => axios.get(url, headers)
        const getreponseBankConnections = () => axios.get(`${url}bankConnections`, headers)
        const getReponseRewards = () => axios.get(`${url}rewards`, headers)
        const getReponseTransaction = () => axios.get(`${url}transactions?startDate=${dateLimit}`, headers)
       
        // Run in parallel with axios.all
        const [
            responseCustomer, 
            reponseBankConnections, 
            reponseRewards, 
            reponseTransaction ] = await axios.all([getReponseCustomer(), getreponseBankConnections(), getReponseRewards(), getReponseTransaction()])

        return dispatch(getCustomerInfoSuccess(
            responseCustomer,
            reponseBankConnections,
            reponseRewards,
            reponseTransaction
            )
        ) // We receive the response from the API, and we got all the information
    } catch (err) {
        console.log(err);
        dispatch(getCustomerInfoError(err))
        throw err
    }
}

/**
 * Get shopping hub name associate to a shopping hub id
 *
 * @param shoppingHubId
 * @returns {Function}
 */
export const fetchShoppingHubName = (shoppingHubId) => async(dispatch, getState) => {
    try {
        dispatch(getShoppingHubNameRequest(shoppingHubId))
        const { accessToken } = getState().backUser
        const reponseShoppingHubName = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHub/${shoppingHubId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return dispatch(getShoppingHubNameSuccess(reponseShoppingHubName))
    }catch (err) {
        dispatch(getShoppingHubNameError(err))
        throw err
    }

}

/**
 * Get list of customerID associate to a given phone number or email
 *
 * @param email
 * @param phoneNumber
 * @param countryCode
 * @returns {Function}
 */
export const fetchCustomerByInfo = ({ email, phoneNumber, countryCode }) => async (dispatch, getState) => {
    try {
        dispatch(getCustomerIdListRequest())
        const { accessToken } = getState().backUser

        let queryParams
        let emailParam

        if (email) {
            emailParam = email.toLowerCase().replace('+', '%2B')
            queryParams = `email=${emailParam}`
        } else {
            queryParams = `phoneNumber=${phoneNumber}&countryCode=${countryCode}`
        }

        const response = await axios.get(
            `${process.env.REACT_APP_TC_HOST}/backoffice/customers?${queryParams}`,
            {
            headers: { Authorization: `Bearer ${accessToken}` }
        })
        const { data: { customerIdList } } = response
        return dispatch(getCustomerIdListSuccess(customerIdList))
    } catch (err) {
        dispatch(getCustomerIdListError(err))
        throw err
    }
}


/**
 * Get an Iban with a mg_payout_id
 *
 * @param mg_pay_out_id
 * @returns {Function}
 */

export const fetchIbanByMgPayOutId = mgPayoutId => async (dispatch) => {
    try {
        dispatch(getFetchIbanRequest(mgPayoutId))
        const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/payouts/${mgPayoutId}/iban`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`
            }
        })
        const { data: iban } = response
        return dispatch(getFetchIbanSuccess(iban))
    } catch (err) {
        dispatch(getFetchIbanError(err))
        throw err
    }
}
