import { GET_BACK_USERS, GET_BACK_USERS_SUCCESS } from '../types'


export default function userManagement(state = {}, action = {}) {
    switch (action.type) {
        case GET_BACK_USERS:
            return {
                ...state,
                isFetching: true
            };
        case GET_BACK_USERS_SUCCESS:
            return {
                ...state,
                backUsers: action.backUsers,
                isFetching: false
            };
        default: return state
    }
}
