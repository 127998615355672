import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import StatsCustomer from './StatBoardCustomer'
import StatsRegistered from './StatBoardRegistered'
import './StatBoard.css'
import StatsDesync from './StatBoardDesynchro'
import StatsUnsubscribe from './StatBoardUnsubscribe'
import DailyChart from './StatBoardDailyChart'

export default class StatBoardIndex extends Component {
    render() {
        return (
            <section>
                <div>
                    <Header as="h2" attached="top" className="stat-header">
                        Statistic board TC
                    </Header>
                </div>
                <div className="customer-div" style={{ display: 'flex' }}>
                    <StatsCustomer />
                    <StatsRegistered />
                    <StatsDesync />
                    <StatsUnsubscribe />
                </div>
                <DailyChart />
            </section>
        )
    }
}
