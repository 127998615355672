import React, { Component } from 'react'
import HubFields from '../GiftCardForm/Fields/HubFields'
import InitialStoreTableTransactionsTable from './ManualAssociationTable'

let fieldValues = {
    cardNumber: null,
    hubName: null,
    hubId: null,
    storeId: null
}

export default class ManualAssociationPageForm extends Component {
    state = { step: 1 }
    nextStep = () => this.setState({ step: this.state.step + 1 })
    prevStep = () => this.setState({ step: this.state.step - 1 })

    saveValues = (fieldValue) => {
        fieldValues = Object.assign({}, fieldValues, fieldValue)
        if (fieldValues.hubName && !localStorage.getItem('hubName')) {
            localStorage.setItem('hubName', fieldValues.hubName)
        }
    }

    showStep = () => {
        const { step } = this.state

        switch (step) {
            case 1:
                return (
                    <HubFields
                        fieldValues={fieldValues}
                        nextStep={this.nextStep}
                        saveValues={this.saveValues}
                        fieldValue={fieldValues}
                        prevStep={this.prevStep}
                    />
                )
            case 2:
                return (
                    <InitialStoreTableTransactionsTable
                        fieldValues={fieldValues}
                        nextStep={this.nextStep}
                        saveValues={this.saveValues}
                        fieldValue={fieldValues}
                        prevStep={this.prevStep}
                    />
                )
            default: return true
        }
    }

    render() {
        return (
            <div>
                {this.showStep()}
            </div>
        )
    }
}
