import React from 'react'
import { Form, Button, Modal, Header, Transition } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const displayModalHeader = (entityType, submittedEntity) => {
    if (entityType === 'store' || submittedEntity === 'retailer') {
        return 'Create store'
    } else if (entityType === 'retailer') {
        return 'Create retailer'
    }
    return 'Select entity'
}
const displayEntitySelector = (entityType, onChange, submittedEntity) => (
    entityType !== '' || submittedEntity === 'retailer' ? '' : (
        <Form.Select
            value={entityType}
            required
            name="newEntityType"
            size="small"
            onChange={onChange}
            label="Entity type"
            options={[
                { key: 's', text: 'Store', value: 'store' },
                { key: 'r', text: 'Retailer', value: 'retailer' },
            ]}
            placeholder="Entity"
        />
    ))

const displayFields = (entityType, onChange, checkboxChangeHandler, submittedEntity, entityId) => {

    if (entityType === 'store' || submittedEntity === 'retailer') {
        return (
            <div>
                <Form.Input
                    size="small"
                    required
                    label="Store name"
                    name="newEntityName"
                    placeholder="Store name"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    required
                    label="Google place ID"
                    name="googlePlaceId"
                    placeholder="Google place ID"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    label="Street number"
                    name="streetNumber"
                    placeholder="Street number"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    label="Street Name"
                    name="streetName"
                    placeholder="Street name"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    label="Retailer ID"
                    defaultValue={submittedEntity === 'retailer' ? entityId : ''}
                    name="retailerId"
                    placeholder="Retailer ID"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    label="City ID"
                    name="cityId"
                    placeholder="City ID"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    label="Shopping Hub ID"
                    name="shoppingHubId"
                    placeholder="Shopping Hub ID"
                    onChange={onChange}
                />

                <Form.Checkbox
                    toggle
                    size="small"
                    label="Is Online"
                    name="isOnline"
                    placeholder="Is Online"
                    //TODO ajouter le onChange
                    defaultChecked={false}
                    onChange={checkboxChangeHandler}

                />
            </div>
        )
    } else if (entityType === 'retailer') {
        return (
            <div>
                <Form.Input
                    size="small"
                    required
                    label="Retailer name"
                    name="newEntityName"
                    placeholder="Retailer name"
                    onChange={onChange}
                />
                <Form.Input
                    size="small"
                    required
                    label="N°de SIREN"
                    name="sirenNumber"
                    placeholder="N°de SIREN"
                    onChange={onChange}
                />
            </div>
        )
    }
    return ''
}

const propTypes = {
    visible: PropTypes.bool.isRequired,
    entityType: PropTypes.string.isRequired,
    submittedEntity: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    createEntity: PropTypes.func.isRequired,
}

const CreateEntityModal = ({ visible, entityType, onChange, checkboxChangeHandler, close, createEntity, submittedEntity, entityId }) => console.log(checkboxChangeHandler) || (
    <div>
        <Transition visible={visible} animation="fade up" duration={350}>
            <Modal
                size={'tiny'}
                open={visible}
            >
                <Header icon="plus" content={displayModalHeader(entityType, submittedEntity)} />
                <Modal.Content >
                    {
                        <Form>
                            {displayEntitySelector(entityType, onChange, submittedEntity)}
                            {displayFields(entityType, onChange, checkboxChangeHandler,  submittedEntity, entityId)}
                        </Form>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button size="mini" onClick={() => close()}>
                            Back
                    </Button>
                    <Button
                        color="blue"
                        size="mini"
                        onClick={() => createEntity(entityId)}
                    >
                            Validate
                    </Button>
                </Modal.Actions>
            </Modal>
        </Transition>
    </div>
)


CreateEntityModal.propTypes = propTypes

export default CreateEntityModal
