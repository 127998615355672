import connect from 'react-redux/es/connect/connect'
import React, { Component } from 'react'
import { Header, Segment, Loader } from 'semantic-ui-react'
import './StatBoard.css'
import {
    fetchAllStats
} from '../../actions/stats'

class StatsDesync extends Component {
    state = { errors: false }

    componentDidMount = async () => {
        try {
            await this.props.fetchAllStats()
        } catch (e) {
            this.setState({ errors: true })
        }
    }

    countTotal = () => {
        let total = 0
        let totalDesync = 0
        let res = 0
        for (let i = 0; i < this.props.customerAllStats.stats.connectionErrorCustomers.length; i++) {
            const nb = parseInt(this.props.customerAllStats.stats.connectionErrorCustomers[i].count, 10)
            totalDesync += nb
        }
        for (let i = 0; i < this.props.customerAllStats.stats.totalCustomers.length; i++) {
            const nb = parseInt(this.props.customerAllStats.stats.registeredCustomers[i].count, 10)
            total += nb
        }
        if (total === 0) {
            res = 0
        } else {
            res = (totalDesync / total) * 100
        }
        return res.toFixed(2)
    }

    countPercentage = (stats) => {
        let res = 0
        for (let i = 0; i < this.props.customerAllStats.stats.totalCustomers.length; i++) {
            if (stats && this.props.customerAllStats.stats.totalCustomers[i].shortcut === stats.shortcut) {
                const a = parseInt(stats.count, 10)
                const b = parseInt(this.props.customerAllStats.stats.registeredCustomers[i].count, 10)
                if (b === 0) {
                    res = 0
                } else {
                    res = (a / b) * 100
                }
            }
        }
        return res.toFixed(2)
    }

    render() {
        let name
        let count
        let total
        let totalHeader
        let loading
        if (this.props.customerAllStats && this.props.customerAllStats.stats) {
            this.countPercentage()
            name = (
                this.props.customerAllStats.stats.connectionErrorCustomers.map(customerAllStats => (
                    <Segment className="segment">{customerAllStats.shortcut.toUpperCase()}</Segment>
                ))
            )

            count = (
                this.props.customerAllStats.stats.connectionErrorCustomers.map(customerAllStats => (
                    <Segment className="segment">{`${this.countPercentage(customerAllStats)}%`}</Segment>
                ))
            )

            totalHeader = (
                <Segment className="segment">{'Total'}</Segment>
            )

            total = (
                <Segment className="segment">{`${this.countTotal()}%`}</Segment>
            )
        } else if (this.state.errors === true) {
            count = (
                <Segment className="segment">{'api error'}</Segment>
            )
        } else {
            loading = (
                <div className="loading">
                    <Loader active />
                </div>
            )
        }
        return (
            <div className="div-customer">
                <Segment.Group>
                    <Header className="desync-header">Desynchronization rate</Header>
                    <Segment.Group horizontal className="data">
                        <Segment.Group>
                            {name}
                            {loading}
                            {totalHeader}
                        </Segment.Group>
                        <Segment.Group>
                            {count}
                            {total}
                        </Segment.Group>
                    </Segment.Group>
                </Segment.Group>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerAllStats: state.customerStats.customerAllStats,
    backUser: state.backUser
})

export default connect(mapStateToProps, {
    fetchAllStats
})(StatsDesync)
