export const BACK_USER_LOGGED_IN = 'BACK_USER_LOGGED_IN'
export const BACK_USER_LOGGED_OUT = 'BACK_USER_LOGGED_OUT'

export const CUSTOMER_INFO_FETCHED = 'CUSTOMER_INFO_FETCHED'
export const CUSTOMER_ID_FETCHED = 'CUSTOMER_ID_FETCHED'
export const COUNTRIES_FETCHED = 'COUNTRIES_FETCHED'
export const RESET_CUSTOMER_INFO = 'RESET_CUSTOMER_INFO'

export const GENERAL_STATS_FETCHED = 'GENERAL_STATS_FETCHED'
export const STORE_STATS_FETCHED = 'STORE_STATS_FETCHED'
export const MEAN_BASKET_STATS_FETCHED = 'MEAN_BASKET_STATS_FETCHED'
export const VISIT_FREQUENCY_STATS_FETCHED = 'VISIT_FREQUENCY_STATS_FETCHED'
export const VISIT_DELAY_STATS_FETCHED = 'VISIT_DELAY_STATS_FETCHED'
export const VISIT_SCENARIO_STATS_FETCHED = 'VISIT_SCENARIO_STATS_FETCHED'
export const CROSS_SELLS_STATS_FETCHED = 'CROSS_SELLS_STATS_FETCHED'
export const STORE_SYNERGIES_STATS_FETCHED = 'STORE_SYNERGIES_STATS_FETCHED'
export const STORE_RANK_STATS_FETCHED = 'STORE_RANK_STATS_FETCHED'
export const PENETRATION_RATE_STATS_FETCHED = 'PENETRATION_RATE_STATS_FETCHED'
export const PROGRAM_OFFER_STATS_FETCHED = 'PROGRAM_OFFER_STATS_FETCHED'

export const SHOPPING_HUB_LIST_FETCHED = 'SHOPPING_HUB_LIST_FETCHED'
export const STORES_LIST_FETCHED = 'STORES_LIST_FETCHED'
export const GIFT_CARD_TRANSACTIONS_FETCHED = 'GIFT_CARD_TRANSACTIONS_FETCHED'
export const UNAFFECTED_TRANSACTIONS_FETCHED = 'UNAFFECTED_TRANSACTIONS_FETCHED'
export const GIFT_CARD_TRANSACTIONS_SENT = 'GIFT_CARD_TRANSACTIONS_SENT'
export const TRANSACTIONS_ASSOCIATION_SENT = 'TRANSACTIONS_ASSOCIATION_SENT'

export const STORE_DETAIL_FETCHED = 'STORE_DETAIL_FETCHED'
export const RETAILER_DETAIL_FETCHED = 'RETAILER_DETAIL_FETCHED'
export const STORE_DETAIL_CLEARED = 'STORE_DETAIL_CLEARED'
export const STORE_TRANSACTIONS_FETCHED = 'STORE_TRANSACTIONS_FETCHED'

export const GET_BACK_USERS = 'GET_BACK_USERS'
export const GET_BACK_USERS_SUCCESS = 'GET_BACK_USERS_SUCCESS'
export const GET_BACK_USERS_FAILURE = 'GET_BACK_USERS_FAILURE'

export const CUSTOMER_ALL_STATS = 'CUSTOMER_ALL_STATS'
export const CUSTOMER_DAILY_STATS = 'CUSTOMER_DAILY_STATS'
export const CLEAR_DAILY_STATS = 'CLEAR_DAILY_STATS'
