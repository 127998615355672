import React, { Component } from 'react'
import Chart from 'react-apexcharts'

import { CSVLink } from 'react-csv'

import api from '../../../api'

class StatsOffer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: this.getOptions('', '', '', ['']),
            optionsBudget: this.getOptions('', '', '', ['']),
            series: this.getSeries('', '', [''], ['']),
            seriesBudget: this.getSeries('', '', [''], ['']),
            turnoverParameter: 0,
            interactionParameter: 0,
            budget: 0,
            loaded: false
        }
    }

  getOptions = (yaxisType, formateur, title, categories) => ({
      dataLabels: {
          enabled: false
      },
      chart: {
          stacked: true,
          toolbar: {
              show: true
          },
          zoom: {
              enabled: true
          }
      },
      yaxis: {
          type: yaxisType,
          labels: {
              formatter(value, index) {
                  return Math.round(value).toString() + formateur
              }
          },
          title: {
              text: title
          }
      },
      colors: ['#2E93fA', '#ffadb8'],
      responsive: [
          {
              breakpoint: 480,
              options: {
                  legend: {
                      position: 'bottom',
                      offsetX: -10,
                      offsetY: 0
                  }
              }
          }
      ],
      plotOptions: {
          bar: {
              horizontal: false
          }
      },

      xaxis: {
          categories
      },
      legend: {
          position: 'right',
          offsetY: 40
      },
      fill: {
          opacity: 1
      }
  });

  getSeries = (name1, name2, data1, data2) => [
      {
          name: name1,
          data: data1
      },
      {
          name: name2,
          data: data2
      }
  ];

  componentDidMount() {
      this.props.event_emitter.on('turnoverParameter', (value) => {
          this.setState({ turnoverParameter: value })
          this.loadGraph(value, this.state.interactionParameter, this.state.budget)
      })
      this.props.event_emitter.on('interactionParameter', (value) => {
          this.setState({ interactionParameter: value })
          this.loadGraph(this.state.turnoverParameter, value, this.state.budget)
      })
      this.props.event_emitter.on('budget', (value) => {
          this.setState({ budget: value })
          this.updateBudget(value)
      })
  }

  updateBudget = (budget) => {
      api.offers.get('json_stats_update_budget/', {
          params: { budget }
      }).then((json) => {
          this.setState({
              csv: json.data[1],
              options: this.getOptions(
                  'percentage',
                  '%',
                  'Part des clients recevant l\'offre ',
                  json.data[0].categories
              ),
              optionsBudget: this.getOptions(
                  '',
                  '€',
                  'Part du budget par boutique ',
                  json.data[0].categories
              ),
              series: this.getSeries(
                  'Clients reçevant une offre',
                  'Clients ne reçevant pas d\'offre',
                  json.data[0].data2,
                  json.data[0].data1
              ),
              seriesBudget: this.getSeries(
                  'Budget des offres par magasin',
                  ' ',
                  json.data[0].data3,
                  Array(json.data[0].data3.length)
              )
          })
      })
  };

  add_title = (l, title) => {
      l.unshift(title)
      return l
  };

  loadGraph = (value1, value2, budget) => {
      api.offers.get('json_stats/', {
          params: {
              turnover_parameter: value1,
              interaction_parameter: value2,
              budget
          }
      }).then((json) => {
          this.setState({
              csv: json.data[1],
              options: this.getOptions(
                  'percentage',
                  '%',
                  'Part des clients recevant l\'offre ',
                  json.data[0].categories
              ),
              optionsBudget: this.getOptions(
                  '',
                  '€',
                  'Part du budget par boutique ',
                  json.data[0].categories
              ),
              series: this.getSeries(
                  'Clients reçevant une offre',
                  'Clients ne reçevant pas d\'offre',
                  json.data[0].data2,
                  json.data[0].data1
              ),
              seriesBudget: this.getSeries(
                  'Budget des offres par magasin',
                  ' ',
                  json.data[0].data3,
                  Array(json.data[0].data3.length)
              )
          })
      })
  };

  render() {
      return (
          <div className="app">
              <CSVLink
                  data={
                      this.state.csv
                          ? this.add_title(this.state.csv, [
                              'customerId',
                              'storeName',
                              'cost'
                          ])
                          : 'Please choose parameters'
                  }
              >
          Download .csv predictions
              </CSVLink>
              <div className="row">
                  <div className="mixed-chart">
                      {this.props.graph === 'stores' ? (
                          <Chart
                              options={this.state.optionsBudget}
                              series={this.state.seriesBudget}
                              type="bar"
                              width="100%"
                          />
                      ) : (
                          <Chart
                              options={this.state.options}
                              series={this.state.series}
                              type="bar"
                              width="100%"
                          />
                      )}
                  </div>
              </div>
          </div>
      )
  }
}

export default StatsOffer
