import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoginForm from '../forms/LoginForm/LoginForm'
import { login } from '../../actions/auth'
import tcLogo from '../../img/tc-logo.png'
import './LoginPage.css'
import pJson from '../../../package.json'

class LoginPage extends Component {
    handleSubmit = async (data) => {
        try {
            const response = await this.props.login(data)
            if (response.data.accessToken) {
                this.props.history.push('/home')
            }
            return response
        } catch (error) {
            throw error
        }
    }
    render() {
        return (
            <section className="ui text container login-page">
                <img src={tcLogo} alt="transaction connect logo" />
                <p>Environement: <span>{process.env.REACT_APP_NODE_ENV}</span><br />Version: <span>{pJson.version}</span></p>
                <LoginForm submit={this.handleSubmit} />
            </section>
        )
    }
}

LoginPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    login: PropTypes.func.isRequired
}
export default connect(null, {
    login
})(LoginPage)
