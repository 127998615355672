import React from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './HomePage.css'

const HomePage = ({ backUser }) => {
    return (
        <section className="homePage">
            <Header as='h2' attached="top" className="support">
                Dashboard TC
            </Header>
        </section>
    )
}

HomePage.propTypes = {
    backUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    backUser: state.backUser,
})

export default connect(mapStateToProps)(HomePage)

