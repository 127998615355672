import React from 'react'
import './ManualAssociation.css'
import DecryptCustomer from '../forms/DecryptCustomer/DecryptCustomer'

const DecryptCustomerPage = () => (
    <section className="ui container support-page">
        <DecryptCustomer />
    </section>
)

export default DecryptCustomerPage
