import React from 'react'
import './ManualAssociation.css'
import CustomerSupport from '../forms/CustomerSupport/CustomerSupport'

const CustomerSupportPage = () => (
    <section className="ui container support-page">
        <CustomerSupport />
    </section>
)

export default CustomerSupportPage
