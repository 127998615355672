// action types
import { STORE_DETAIL_FETCHED, STORE_DETAIL_CLEARED, STORE_TRANSACTIONS_FETCHED } from '../types'
// api calls
import api from '../api'

// Action Creators
export const storeDetailFetched = storeDetail => ({
    type: STORE_DETAIL_FETCHED,
    storeDetail
})

export const storeDetailCleared = () => ({
    type: STORE_DETAIL_CLEARED
})

export const storeTransactionsFetched = () => ({
    type: STORE_TRANSACTIONS_FETCHED
})
/**
 * This function returns a other function that will call our API,
 * Redux Thunk middleware allows you to write action creators that return a function instead of an action.
 * i first make api request, get data and dispatch action that will change via reducers to the redux store
 * @param storeId
 */
export const fetchStoreDetail = storeId => dispatch => api.stores.fetchStoreDetail(storeId).then(storeDetail => dispatch(storeDetailFetched(storeDetail)))

export const fetchStoreTransactions = storeId => dispatch => api.stores.fetchStoreTransactions(storeId).then(storeTransactions => dispatch(storeTransactionsFetched(storeTransactions)))
/**
 * Clear the store state
 */
export const clearStoreDetail = () => dispatch => dispatch(storeDetailCleared())

