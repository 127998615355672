import axios from 'axios'

// action
export const GET_STORES_REQUEST = 'GET_STORES_REQUEST'
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS'
export const GET_STORES_ERROR = 'GET_STORES_ERROR'
export const GET_STORES_TRANSACTIONS_REQUEST = 'GET_STORES_TRANSACTIONS_REQUEST'
export const GET_STORES_TRANSACTIONS_SUCCESS = 'GET_STORES_TRANSACTIONS_SUCCESS'
export const GET_STORES_TRANSACTIONS_ERROR = 'GET_STORES_TRANSACTIONS_ERROR'
const TCHost = process.env.REACT_APP_TC_HOST

const initialState = {
    isFetchingStores: false,
    storeList: [],
    transactionList: [],
    error: null,
}

export default (state = initialState, action = {}) => {
    const { payload, type } = action
    switch (type) {
        case GET_STORES_REQUEST: {
            return { ...state,
                isFetchingStores: true,
            }
        }
        case GET_STORES_SUCCESS: {
            return { ...state,
                isFetchingStores: false,
                storeList: payload.storeList,
            }
        }
        case GET_STORES_ERROR: {
            return { ...state,
                error: payload.error,
                isFetchingStores: false,
            }
        }
        case GET_STORES_TRANSACTIONS_REQUEST: {
            return { ...state,
                isFetchingTransactions: true,
            }
        }
        case GET_STORES_TRANSACTIONS_SUCCESS: {
            return { ...state,
                isFetchingTransactions: false,
                transactionList: payload,
            }
        }
        case GET_STORES_TRANSACTIONS_ERROR: {
            return { ...state,
                error: payload.error,
                isFetchingTransactions: false,
            }
        }
        default:break
    }
    return state
}


// action creators
export const getStoresRequest = () => ({
    type: GET_STORES_REQUEST,
})
export const getStoresSuccess = storeList => ({
    type: GET_STORES_SUCCESS,
    payload: storeList
})
export const getStoresError = error => ({
    type: GET_STORES_ERROR,
    payload: error
})

// action creators
export const getTransactionsRequest = () => ({
    type: GET_STORES_TRANSACTIONS_REQUEST,
})
export const getTransactionsSuccess = storeList => ({
    type: GET_STORES_TRANSACTIONS_SUCCESS,
    payload: storeList
})
export const getTransactionsError = error => ({
    type: GET_STORES_TRANSACTIONS_ERROR,
    payload: error
})


/*
* complex actions creators
* */


/**
 * Used to update a receipt's store id
 *
 * @param accessToken
 * @param hubId
 * @returns {Function}
 */
export function getStores(accessToken, hubId) {
    return async (dispatch) => {
        try {

            dispatch(getStoresRequest())

            const { data: storeList } = await axios.get(`${TCHost}/backoffice/shoppingHubs/stores?limit=1000`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    shoppingHubId: hubId
                }
            })
            dispatch(getStoresSuccess({ storeList: storeList.stores }))
        } catch (err) {
            dispatch(getStoresError(err))
            throw err
        }
    }
}


export function getStoreTransactions(storeId) {
    return async (dispatch, getState) => {
        try {
            const accessToken = getState().backUser.accessToken
            dispatch(getTransactionsRequest())

            const { data: transactionList } = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/stores/${storeId}/transactions`, {
                headers: {  Authorization: `Bearer ${accessToken}`}
            })
            dispatch(getTransactionsSuccess(transactionList))
        } catch (err) {
            dispatch(getTransactionsError(err))
            throw err
        }
    }
}

