import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'


// HOC : high order component
const BackUserRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to={'/'} />)} />
)

BackUserRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: !!state.backUser.accessToken
})

export default connect(mapStateToProps)(BackUserRoute)
