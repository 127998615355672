import React, { Component } from 'react'
import { Form, Button, Message, Modal, Header, Transition } from 'semantic-ui-react'
import 'react-table/react-table.css'
import { connect } from 'react-redux'
import api from '../../../api'


const initialState = {
    transactionId: '',
    transactionDissociationSubmitted: false,
    disassociateTransactionModal: false,
    visible: false

}
class ManualDissociationForm extends Component {
    state = initialState
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    }

    disassociateTransactionModal = async () => {
        this.setState({ disassociateTransactionModal: true, visible: true })
    }
    handleClose = () => {
        this.setState({ modalOpen: false, addPatternModalOpen: false, newPattern: '', errors: '', disassociateTransactionModal: false, visible: false })

    }
    deleteTransactionAssociation = async (transactionId) => {
        try {
            this.setState({ disassociateTransactionModal: false, visible: false })
            await api.shoppingHubs.sendTransactionDissociation(transactionId)
            this.setState({ transactionDissociationSubmitted: true, transactionId: '' })

        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
            const { status } = err.response
            if (status === 404) {
                if (err.response.data.code === 'transactionNotFound') {
                    return this.setState({
                        errors: { transactionNotFound: 'Error: No affected transaction found' },
                    })
                }
            }
            if (err.response.data.code === 'invalidTransactionId') {
                return this.setState({
                    errors: { invalidTransactionId: 'Error: invalid transaction ID' },
                })
            }
            if (status === 500) {
                return this.setState({ errors: { serverError: 'An error has occurred' } })
            }
            return 1
        }
        return null
    }
    showMessage() {
        window.setTimeout(() => {
            this.setState({ transactionDissociationSubmitted: false })
        }, 6000)
        if (this.state.transactionDissociationSubmitted) {
            return <Message attached info color="green"><p>Transaction has been successfully dissociated</p></Message>
        }
        return null
    }
    render() {
        const { transactionId } = this.state
        return (
            <div>
                {
                    this.state.transactionDissociationSubmitted
                        ? this.showMessage()
                        : <Message attached info>
                            <p>Please enter the transaction id you want disassociate</p>
                        </Message>
                }

                <Form size={'mini'}>
                    <Form.Group>
                        <Form.Input
                            width={4}
                            label="Transaction ID"
                            placeholder="Transaction ID"
                            value={transactionId}
                            name="transactionId"
                            onChange={this.handleChange}
                        />
                        <Button size="mini"type="button" color="red" disabled={!transactionId} onClick={() => this.disassociateTransactionModal()}>Dissociate</Button>

                    </Form.Group>
                </Form>
                {
                    <Transition visible={this.state.visible} animation='fade up' duration={350}>
                        <Modal
                            size="small"
                            open={this.state.disassociateTransactionModal}
                        >
                            <Header content="Disassociate transaction" />
                            <Modal.Content>
                                Disassociate transaction with id : <span style={{ fontWeight: '800' }}>{transactionId.toLocaleUpperCase()}</span> ?
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.handleClose()}>
                                    Back
                                </Button>
                                <Button
                                    onClick={() => this.deleteTransactionAssociation(transactionId)}
                                    color={'red'}
                                >
                                    Disassociate
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Transition>

                }
                {
                    this.state.errors && Object.keys(this.state.errors).length > 0 ?  <Modal
                        size="tiny"
                        open={!!this.state.errors}
                    >
                        <Header content="Error" />
                        <Modal.Content>
                            {this.state.errors.transactionNotFound && <p>{this.state.errors.transactionNotFound}</p>}
                            {this.state.errors.invalidTransactionId && <p>{this.state.errors.invalidTransactionId}</p>}
                            {this.state.errors.serverError && <p>{this.state.errors.serverError}</p>}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="red" onClick={() => this.handleClose()}>
                                Back
                            </Button>
                        </Modal.Actions>
                    </Modal> : ''
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {
})(ManualDissociationForm)
