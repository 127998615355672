import React from 'react'
import { Header } from 'semantic-ui-react'
import './ManualAssociation.css'
import CustomerManualAssociationIndex from "../forms/CustomerManualAssociation/CustomerManualAssociationIndex";

const CustomerSupportPage = () => (
    <section className="ui container support-page">
        <Header as='h2' attached="top" className="transactions-assignment">
            Manual association
        </Header>
        <CustomerManualAssociationIndex />
    </section>
)

export default CustomerSupportPage
