import React, { Component } from 'react'
import { Form, Button, Icon, Message } from 'semantic-ui-react'
import connect from 'react-redux/es/connect/connect'
import './CustomerManualAssociation.css'
import { fetchCustomerTransactions } from '../../../actions/supportClient'

class CustomerManualAssociation extends Component {
    state = {
        customerId: '',
        errors: '',
    }

    onChange = e => this.setState({ customerId: e.target.value })

    getTransactions = async (customerId) => {
        try {

            const result = await this.props.fetchCustomerTransactions(customerId)
            this.setState({ customerTransactions: result.customerInfo.transactions })
            return this.props.nextStep()
        } catch (err) {
            this.setState({ errors: 'serverError' })
            return null
        }
    }

    HandleSubmit = async () => {
        try {
            return await this.getTransactions(this.state.customerId)
        } catch (e) {
            return null
        }
    }

    render() {
        return (
            <div>
                <Message attached info>
                    <p>Please enter a customer ID to display all his transactions</p>
                </Message>
                {
                    this.state.errors
                        ? <Message attached error>Cannot get transactions: server error or wrong customer id</Message>
                        : null
                }
                <Form onSubmit={this.HandleSubmit}>
                    <input
                        type="Search"
                        placeholder="Customer ID"
                        onChange={this.onChange}
                        id="customerId"
                        name="customerId"
                    />
                    <Button animated size="huge">
                        <Button.Content visible>Search</Button.Content>
                        <Button.Content hidden>
                            <Icon name="right arrow" />
                        </Button.Content>
                    </Button>
                </Form>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    customerTransactions: state.customer.customerTransactions,
})

export default connect(mapStateToProps, {
    fetchCustomerTransactions
})(CustomerManualAssociation)
