import React, { Component } from 'react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'
import {
    Accordion,
    Icon,
    Form,
    Select,
    Input,
    Grid,
    Popup,
    Header,
    Container,
    Button,
    List,
    Card,
    Loader
} from 'semantic-ui-react'
import 'rc-slider/assets/index.css'

import _ from 'lodash'

import './generateOffer.scss'
import SearchGraph from '../../Statistics/StoresSearchTool/StoresSearchTool'
import CustomerFiltering from '../CustomerFiltering/CustomerFiltering'

const mecanism = [
    { key: 'l', text: 'Loterie', value: 'lottery' },
    { key: 'p', text: 'Prix', value: 'price' }
]

class GenerateOffer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            duration: 500,
            offerName: '',
            time: '',
            dateRange: '',
            activeIndex: 0,
            activeIndexFilter: 1,
            offerType: '',
            activeShopFilter: 1,
            shopsSelected: [],
            graphdata: null,
            selectedCustomers: []
        }
        this.removeShopFromSelection = this.removeShopFromSelection.bind(this)
        this.setSelectedCustomers = this.setSelectedCustomers.bind(this)
    }

    componentDidMount() {
        this.props.event_emitter.on('graphdata', (data) => {
            this.setState({
                graphdata: _.map(data, 'id')
            })
        })

        this.props.event_emitter.on('selectShop', (data) => {
            this.setState({
                shopsSelected: _.includes(this.state.shopsSelected, data)
                    ? [...this.state.shopsSelected]
                    : [...this.state.shopsSelected, data]
            })
        })
    }

  handleShopClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeShopFilter } = this.state
      const newIndex = activeShopFilter === index ? -1 : index
      this.setState({ activeShopFilter: newIndex })
  };

  handleClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeIndexFilter } = this.state
      const newIndex = activeIndexFilter === index ? -1 : index
      this.setState({ activeIndexFilter: newIndex })
  };

  handleChange = (event, { name, value }) => {
      if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value })
      }
  };

  handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex })
  };

  removeShopFromSelection(e) {
      this.setState({
          shopsSelected: this.state.shopsSelected.filter(shop => shop !== e)
      })
  }

  addShop(shop) {
      this.setState({
          shopsSelected: _.includes(this.state.shopsSelected, shop)
              ? [...this.state.shopsSelected]
              : [...this.state.shopsSelected, shop]
      })
  }

  selectAll() {
      this.setState({
          shopsSelected: this.state.graphdata
      })
  }

  removeAll() {
      this.setState({
          shopsSelected: []
      })
  }

  sendOffer() {
      const offer = {
          name: this.state.offerName,
          dateRange: this.state.dateRange,
          offerType: this.state.offerType,
          shopsSelected: this.state.shopsSelected,
          selectedCustomers: this.state.selectedCustomers
      }
      console.log(offer)
      // objet offer à envoyer à une API pour lancer l'offre!
  }

  setSelectedCustomers(customers) {
      this.setState({ selectedCustomers: customers })
  }

  render() {
      return (
          <Container fluid className="my_container">
              <Header textAlign="center" as="h2" style={{ paddingTop: '10px' }}>
                  <Header.Content>
                      <Icon size={'small'} name="paper plane" />
            Lancer une offre
                  </Header.Content>
              </Header>
              <Form>
                  <Grid>
                      <Grid.Row>
                          <Grid.Column widescreen={8} largeScreen={8} computer={8}>
                              <Form.Field
                                  name="offerName"
                                  value={this.state.offerName}
                                  onChange={this.handleChange}
                                  control={Input}
                                  label="Nom de l'offre"
                                  placeholder="Nom de l'offre"
                              />
                          </Grid.Column>
                          <Grid.Column widescreen={8} largeScreen={8} computer={8}>
                              <Form.Field
                                  name="offerType"
                                  onChange={this.handleChange}
                                  value={this.state.offerType}
                                  control={Select}
                                  label="Type de récompense"
                                  options={mecanism}
                                  placeholder="Type de récompense"
                              />
                          </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                          <Grid.Column computer={16}>
                              <DatesRangeInput
                                  name="dateRange"
                                  placeholder="Durée de l'offre"
                                  value={this.state.dateRange}
                                  label="Durée de l'offre"
                                  iconPosition="left"
                                  onChange={this.handleChange}
                              />
                          </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                          <Grid.Column computer={15}>
                              <Accordion>
                                  <Accordion.Title
                                      active={this.state.activeShopFilter === 0}
                                      index={0}
                                      onClick={this.handleShopClick}
                                  >
                                      <Icon name="dropdown" /> &nbsp; Sélectionner les magasins
                                  </Accordion.Title>
                                  <Accordion.Content active={this.state.activeShopFilter === 0}>
                                      <Grid>
                                          <Grid.Row>
                                              <Grid.Column width={4}>
                                                  <SearchGraph
                                                      event_emitter={this.props.event_emitter}
                                                  />
                                              </Grid.Column>
                                              <Grid.Column width={6} textAlign="right">
                                                  <Button
                                                      content="Tout sélectionner"
                                                      onClick={() => this.selectAll()}
                                                  />
                                              </Grid.Column>
                                              <Grid.Column width={6}>
                                                  <Button
                                                      content="Ne rien sélectionner"
                                                      onClick={() => this.removeAll()}
                                                  />
                                              </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                              <Grid.Column width={8}>
                                                  <Card
                                                      style={{
                                                          padding: 10,
                                                          height: 100,
                                                          overflowY: 'auto'
                                                      }}
                                                  >
                                                      {this.state.graphdata ? (
                                                          <List>
                                                              {_.differenceWith(
                                                                  this.state.graphdata,
                                                                  this.state.shopsSelected,
                                                                  _.isEqual
                                                              ).map(shop => (
                                                                  <List.Item
                                                                      style={{ cursor: 'pointer' }}
                                                                      onClick={() => this.addShop(shop)}
                                                                      key={shop}
                                                                  >
                                                                      <List.Content>{shop}</List.Content>
                                                                  </List.Item>
                                                              ))}
                                                          </List>
                                                      ) : (
                                                          <Loader size="big" />
                                                      )}
                                                  </Card>
                                              </Grid.Column>
                                              <Grid.Column width={8} textAlign="right">
                                                  <Card
                                                      style={{
                                                          padding: 10,
                                                          height: 100,
                                                          overflowY: 'auto'
                                                      }}
                                                  >
                                                      <List>
                                                          {this.state.shopsSelected.map(shop => (
                                                              <List.Item key={shop}>
                                                                  <List.Content floated="right">
                                                                      <List.Icon
                                                                          name="times"
                                                                          color="red"
                                                                          onClick={() => {
                                                                              this.removeShopFromSelection(shop)
                                                                          }}
                                                                      />
                                                                  </List.Content>
                                                                  <List.Content>{shop}</List.Content>
                                                              </List.Item>
                                                          ))}
                                                      </List>
                                                  </Card>
                                              </Grid.Column>
                                          </Grid.Row>
                                      </Grid>
                                  </Accordion.Content>
                              </Accordion>
                          </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                          <Grid.Column
                              computer={16}
                              centered="true"
                              style={{ margin: 'auto' }}
                          >
                              <Accordion>
                                  <Accordion.Title
                                      active={this.state.activeIndexFilter === 0}
                                      index={0}
                                      onClick={this.handleClick}
                                  >
                                      <Icon name="dropdown" /> Filtrer les clients du centre
                    commercial{'   '} &nbsp;
                                      <Popup
                                          trigger={<Icon name="info circle" />}
                                          content="Sélectionnez les clients à cibler à l'aide de filtres. Pour les abandonnistes, si vous sélectionnez par exemple les clients abandonnistes entre 10 et 50%, cela signifie que vous sélectionnerez les 50% clients les moins abandonnistes, sauf les 10% les moins abandonnistes."
                                      />
                                  </Accordion.Title>
                                  <CustomerFiltering
                                      selectCustomer={this.setSelectedCustomers}
                                      active={this.state.activeIndexFilter === 0}
                                  />
                              </Accordion>
                          </Grid.Column>
                      </Grid.Row>
                  </Grid>
                  <hr />
                  <Button
                      onClick={() => this.sendOffer()}
                      icon="right arrow"
                      labelPosition="right"
                      id="sendOfferButton"
                      content="Lancer l'offre"
                  />
              </Form>
          </Container>
      )
  }
}

export default GenerateOffer
