import React, { Component } from 'react'
import { Form, Select, Grid } from 'semantic-ui-react'
import './chooseGraph.scss'

const levers = [
    {
        key: 's',
        text: 'Meilleure synergie',
        value: 'sessions'
    },
    {
        key: 'f',
        text: 'Meilleur taux de clients partagés',
        value: 'sharedCustomer'
    }
]

const sizeOptions = [
    { key: 'avgCa', text: 'Chiffre d\'affaires', value: 'avgCa' },
    {
        key: 'avgNbCustomer',
        text: 'Taux de pénétration journalier',
        value: 'avgNbCustomer'
    },
    {
        key: 'avgNbTransaction',
        text: 'Volume de transactions',
        value: 'avgNbTransaction'
    }
]

class TabComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

  handleChange = (event, { name, value }) => {
      if (name === 'levier') {
          this.props.levier(value)
      }
      if (name === 'sizeOption') {
          this.props.sizeOption(value)
      }
  };

  render() {
      return (
          <Grid>
              <Grid.Row style={{ paddingLeft: 20, paddingBottom: 0 }}>
                  <Grid.Column width={14}>
                      <Form>
                          <Form.Group widths="equal">
                              <Form.Field>
                                  <strong>Groupement par</strong>
                              </Form.Field>
                              <Form.Field>
                                  <strong>Taille des points par</strong>
                              </Form.Field>
                          </Form.Group>
                          <Form.Group widths="equal">
                              <Form.Field
                                  className="selection"
                                  value={this.props.choosedLevier}
                                  name="levier"
                                  onChange={this.handleChange}
                                  control={Select}
                                  options={levers}
                                  placeholder="Levier d'action"
                              />
                              <Form.Field
                                  className="selection"
                                  value={this.props.choosedOption}
                                  name="sizeOption"
                                  onChange={this.handleChange}
                                  control={Select}
                                  options={sizeOptions}
                                  placeholder="Size option"
                              />
                          </Form.Group>
                      </Form>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
      )
  }
}

export default TabComponent
