import React from 'react'
import { Message, Button } from 'semantic-ui-react'

import connect from 'react-redux/es/connect/connect'
import { ToastContainer, toast, Flip } from 'react-toastify'
import PropTypes from 'prop-types'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'

import { manageArr } from '../../utils'
import { updateReceipt} from '../../redux/receipts'
import { getStores } from '../../redux/stores'

import ManageReceiptModal from './ManageReceiptModal'
import axios from "axios"

const getNextReceipt = async (accessToken, manageReceiptData, receiptId) => {
        try {
            const queryString = receiptId ? `?receiptId=${receiptId}` : ''
            const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/nextReceipt${queryString}`, {
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            const receipt = response.data

            if (Object.entries(receipt).length === 0) {
                alert(`No more receipt to validate !`)
                return false
            }
            const receiptData = await manageReceiptData(receipt) // take first receipt
            return receiptData
        } catch (err) {
            alert(err)
            throw err
        }
    }

class ReceiptsManagement extends React.Component {
    static propTypes = {
        backUser: PropTypes.shape({
            accessToken: PropTypes.string
        }).isRequired,
        getNextReceipt: PropTypes.func.isRequired,
        accessToken: PropTypes.string.isRequired,
        getStores: PropTypes.func.isRequired,
        updateReceipt: PropTypes.func.isRequired,
    }
    state = {
        manageReceiptModalIsOpen: true,
        receiptData: null,
        nextReceipt: null,
        error: '',
    }

    async componentWillMount() {
        try {
            // GET RECEIPTS REQUEST
            const receiptData = await getNextReceipt(this.props.backUser.accessToken, this.manageReceiptData)

            this.setState({
                receiptData
            })
        } catch (e) {
            console.log(e)
            this.setState({
                serverError: 'An error has occurred'
            })
        }
    }

    /**
     * Get next receipt in the list
     *
     * @returns {Promise<void>}
     */
    getNextRecord = async (receiptId) => {
        try {
            const receiptData = await getNextReceipt(this.props.backUser.accessToken, this.manageReceiptData, receiptId)

            this.setState({
                receiptData
            })
        } catch (e) {
            if (!e.response) {
                this.setState({ error: true })
            } else if (e.response.data.code === 'alreadyUnderReview') {
                await this.getNextRecord()
                return 0
            }
        }
    }

    notify = (type, message) => {
        toast[type](<div>
            <p>{message}</p>
        </div>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        })
    }
    closeModal = async (status, id) => {
        this.setState({ manageReceiptModalIsOpen: false, receiptData: null })
    }
    openModal = async (status, id) => {
        this.setState({ manageReceiptModalIsOpen: true})
        await this.getNextRecord()
    }
    manageReceiptData = async (data) => {
        try {
            const receiptData = {}

            await this.props.getStores(this.props.accessToken, data.hubId).catch((e) => {
                e.message = 'error while getting stores by hub id'
                throw e
            })
            const storeList = manageArr(this.props.stores.storeList)
            receiptData.storeList = storeList
            receiptData.id = data.id
            receiptData.hubName = data.hubName
            receiptData.imageURI = data.imageURI
            receiptData.amount = data.ocrTotal || ''
            receiptData.date = data.ocrDate ? moment(data.ocrDate) : ''
            receiptData.time = data.ocrTime || ''
            receiptData.receiptsLeft = data.receiptsLeft
            receiptData.isahitId = data.isahitId
            if (data.ocrInHub) receiptData.ocrInHub = true

            // manage store
            if (data.ocrStoreId || data.declaredStoreName) {
                storeList.forEach((store) => {
                    if (data.declaredStoreName === store.name || data.ocrStoreId === store.id) {
                        receiptData.storeId = store.id
                        receiptData.storeName = store.name
                    }
                })
            }
            return receiptData
        } catch (e) {
            console.log('Error while managing receipt data: ', e)
            this.setState({ error: true })
            return false
        }
    }


    render() {
        const { serverError, manageReceiptModalIsOpen, receiptData } = this.state
        let content
        if (serverError) {
            content = (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    minHeight: '70vh',
                    alignItems: 'center'

                }}
                >
                    <Message negative>
                        <Message.Header>{serverError}</Message.Header>
                        <p>Try again in few seconds !</p>
                    </Message>
                </div>
            )
        } else if (!this.props.receiptData) {
            content = (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    minHeight: '70vh',
                    alignItems: 'center'

                }}
                >
                    <Button onClick={this.openModal}>Start </Button>
                </div>
            )
        }
        return (
            <div style={{ minHeight: '90vh' }}>
                <div
                    className="ag-theme-material"
                    style={{
                        height: '100vh',
                        width: '100%',
                        marginTop: '30px'
                    }}
                >
                    <ManageReceiptModal
                        visible={manageReceiptModalIsOpen}
                        closeModal={this.closeModal}
                        receiptData={receiptData}
                        notify={this.notify}
                        getReceipts={this.props.getReceipts}
                        formError={this.state.formError}
                        getNextRecord={this.getNextRecord}
                    />
                    {content}
                </div>
                <ToastContainer
                    newestOnTop
                    draggable
                    pauseOnHover
                    transition={Flip}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    backUser: state.backUser,
    nextReceipt: state.receipts.nextReceipt,
    accessToken: state.backUser.accessToken,
    stores: state.stores
})

// dispatching actions returned by action creators
const mapDispatchToProps = dispatch => ({
    getNextReceipt: (...args) => dispatch(getNextReceipt(...args)),
    updateReceipt: (...args) => dispatch(updateReceipt(...args)),
    getStores: (...args) => dispatch(getStores(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptsManagement)
