import React from 'react'
import './ManualAssociation.css'
import HubForm from '../forms/HubUpdate'

function HubUpdatePage(props){
    return (
        <section className="ui container support-page">
            <HubForm update="true" match={props.match} />
        </section>)
}


export default HubUpdatePage
