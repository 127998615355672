import React from 'react'
import './ManualAssociation.css'
import HubManagementList from '../HubManagement/HubManagementList'

const HubCreationPage = () => (
    <section className="ui container support-page">
        <HubManagementList />
    </section>
)

export default HubCreationPage
