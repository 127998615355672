import React  from 'react'
import {
    Form,
    Label,
    Container,
    Button,
    Input,
    Grid,
    Select,
    Radio,
    Search, Modal, Header
} from 'semantic-ui-react'
import Loader from 'react-loader-spinner'
import { ToastContainer, toast, Flip } from 'react-toastify'
import loda from 'lodash'
import api from '../../../api'
import { alpha2Countries, languageCodes } from '../../../utils'
import i18n from '../../../i18n'
import BackOfficeHeader from '../../Layout/BackOfficeHeader'

class HubCreationForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            form: {
                phoneRequired: false,
                emailRequired: false,
                scanEnable: false,
                cardRestaurantEnable: false,
                customerExternalIdRequired: false
            },
            errors: {
                shoppingHubOwnerId: false,
                cityId: false,
                country: false,
            },
            showCreateShoppingHubOwnerModal: false,
            shoppingHubOwner: '',
            shoppingHubOwnerExists: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    notify = (type, message) => {
        toast[type](<div>
            <p>{message}</p>
        </div>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        })
    }

    async componentWillMount() {
        const hubOwners = await api.shoppingHubsOwners.fetchHubsOwnersList()
        const hubOwnersOptions = hubOwners.map(owner => ({
            value: owner.id,
            text: owner.name
        }))
        hubOwnersOptions.push({ value: -1, text: 'Add a hub owner' })
        const cities = await api.cities.fetchCitiesList()
        const citiesOptions = cities.map(city => ({
            value: city.id,
            title: city.name
        }))

        const countriesOptions = alpha2Countries()
        const languageOptions = languageCodes()
        const state = {}
        state.hubOwnersOptions = hubOwnersOptions
        state.source = citiesOptions
        state.results = []
        state.countriesOptions = countriesOptions
        state.languageOptions = languageOptions
        state.loading = false
        state.searchIsLoading = false
        state.searchValue = ''
        this.setState(state)
    }

    createShoppingHubOwner = async () => {
        if (!this.state.shoppingHubOwner) {
            return
        }
        let shoppingHubOwnerExists = false
        this.state.hubOwnersOptions.forEach((e) => {
            if (e.text === this.state.shoppingHubOwner) {
                shoppingHubOwnerExists = true
            }
        })
        if (shoppingHubOwnerExists) {
            this.setState({ shoppingHubOwnerExists: true })
            return
        }
        try {
            await api.shoppingHubsOwners.create({ name: this.state.shoppingHubOwner, description: '' })
            this.notify('success', '🚀  hub updated')
        } catch (e) {
            this.notify('error', `🚀 ${e.message}`)
        }
        const hubOwners = await api.shoppingHubsOwners.fetchHubsOwnersList()
        const hubOwnersOptions = hubOwners.map(owner => ({
            value: owner.id,
            text: owner.name
        }))
        hubOwnersOptions.push({ value: -1, text: 'Add a hub owner' })
        this.setState({
            showCreateShoppingHubOwnerModal: false,
            hubOwnersOptions
        })
    }

    handleChange = async (e, { name, value }) => {
        this.setState({ shoppingHubOwnerExists: false })
        if (this.state.showCreateShoppingHubOwnerModal) {
            this.setState({ shoppingHubOwner: value })
        }
        if (name === 'shoppingHubOwnerId' && value === -1) {
            this.setState({ showCreateShoppingHubOwnerModal: true })
            return
        }
        const { form } = this.state
        value = value ? value.toString() : ''
        form[name] = value
        this.setState({
            form
        })
    }

    handleChangeSwitch = (e, { name, value, checked }) => {
        const { form } = this.state
        value = checked
        form[name] = value
        this.setState({
            form
        })
    }
    async handleSubmit(event) {
        try {
            await api.shoppingHubs.create(this.state.form)
            this.notify('success', `🚀 hub ${this.state.form.name} Created`)
            setTimeout(() => {
                window.location = '/hub-management'
            }, 3000)
        } catch (e) {
            this.notify('error', `🚀 ${e.message}`)
        }

        event.preventDefault()
    }


    handleResultSelect = (e, { result }) => {
        this.setState({ searchValue: result.title })
        const { form } = this.state
        form.cityId = result.value
        this.setState({
            form
        })
    }


    handleSearchChange = (e, { value }) => {
        const initialState = { searchIsLoading: false, results: [], searchValue: '' }
        this.setState({ searchIsLoading: true, searchValue: value })

        setTimeout(() => {
            if (this.state.searchValue.length < 1) return this.setState(initialState)

            const re = new RegExp(loda.escapeRegExp(this.state.searchValue), 'i')
            const isMatch = result => re.test(result.title)

            this.setState({
                searchIsLoading: false,
                results: loda.filter(this.state.source, isMatch)
            })
        }, 100)
    };

    render() {
        const { loading, hubOwnersOptions, countriesOptions, languageOptions, searchIsLoading, searchValue, results } = this.state
        return (
            <div>
                <BackOfficeHeader
                    title="Create Shopping Hub"
                    subtitle={i18n.t(('shoppingHub.create.subtitle'))}
                    cta="Save Hub →"
                    onClick={this.handleSubmit}
                    disabled={
                        !this.state.form.name ||
                        !this.state.form.shoppingHubOwnerId ||
                        !this.state.form.cityId ||
                        !this.state.form.programName}
                />
                {
                    !loading ? (
                        <Form className="big-form" onSubmit={this.handleSubmit}>
                            <Container textAlign="center">
                                <Grid>
                                    <Grid.Row className="col-3 label-left">
                                        <Form.Field>
                                            <Label>
                                                Hub Name*
                                    </Label>
                                            <Input
                                                placeholder="Hub Name*"
                                                onChange={this.handleChange}
                                                name="name"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Hub Owner*
                                    </Label>
                                            <Select
                                                placeholder="Hub Owner*"
                                                name="shoppingHubOwnerId"
                                                options={hubOwnersOptions}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                City
                                    </Label>
                                            <Search
                                                name="cityId"
                                                input={{ icon: 'search', iconPosition: 'left' }}
                                                loading={searchIsLoading}
                                                onResultSelect={this.handleResultSelect}
                                                onSearchChange={loda.debounce(this.handleSearchChange, 500, {
                                                    leading: true,
                                                })}
                                                results={results}
                                                value={searchValue}
                                                {...this.props}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Baseline (main description)
                                    </Label>
                                            <Input
                                                placeholder="Baseline"
                                                onChange={this.handleChange}
                                                name="baseline"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Shortcut
                                    </Label>
                                            <Input
                                                placeholder="Shortcut"
                                                onChange={this.handleChange}
                                                name="shortcut"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Street Number
                                    </Label>
                                            <Input
                                                placeholder="Street Number"
                                                onChange={this.handleChange}
                                                name="streetNumber"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Street Name
                                    </Label>
                                            <Input
                                                placeholder="Street Name"
                                                onChange={this.handleChange}
                                                name="streetName"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Postal Code
                                    </Label>
                                            <Input
                                                placeholder="Postal Code"
                                                onChange={this.handleChange}
                                                name="postalCode"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                City
                                    </Label>
                                            <Input
                                                placeholder="City"
                                                onChange={this.handleChange}
                                                name="city"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Full Address
                                    </Label>
                                            <Input
                                                placeholder="Full Address"
                                                onChange={this.handleChange}
                                                name="formattedAddress"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Country
                                    </Label>
                                            <Select
                                                placeholder="Country"
                                                name="country"
                                                options={countriesOptions}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Longitude
                                    </Label>
                                            <Input
                                                placeholder="Longitude"
                                                onChange={this.handleChange}
                                                name="longitude"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Latitude
                                    </Label>
                                            <Input
                                                placeholder="Latitude"
                                                onChange={this.handleChange}
                                                name="latitude"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Mango pay user Id
                                    </Label>
                                            <Input
                                                placeholder="Mango pay user Id"
                                                onChange={this.handleChange}
                                                name="mgUserId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Mango pay wallet Id
                                    </Label>
                                            <Input
                                                placeholder="Mango pay wallet Id"
                                                onChange={this.handleChange}
                                                name="mgWalletId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Sms Sender Name
                                    </Label>
                                            <Input
                                                placeholder="Sms Sender Name"
                                                onChange={this.handleChange}
                                                name="smsSenderName"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Bank Credential Update URL
                                    </Label>
                                            <Input
                                                placeholder="Bank Credential Update URL"
                                                onChange={this.handleChange}
                                                name="bankCredentialUpdateUrl"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Iban Update Url
                                    </Label>
                                            <Input
                                                placeholder="Iban Update Url"
                                                onChange={this.handleChange}
                                                name="ibanUpdateUrl"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Registration Url
                                    </Label>
                                            <Input
                                                placeholder="Registration Url"
                                                onChange={this.handleChange}
                                                name="registrationUrl"

                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Account password Url
                                    </Label>
                                            <Input
                                                placeholder="Account password Url"
                                                onChange={this.handleChange}
                                                name="accountPasswordUrl"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Account password Template Id
                                    </Label>
                                            <Input
                                                placeholder="Account password Template Id"
                                                onChange={this.handleChange}
                                                name="accountPasswordTemplateId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Incomplete Registration Template Id
                                    </Label>
                                            <Input
                                                placeholder="Incomplete Registration Template Id"
                                                onChange={this.handleChange}
                                                name="incompleteRegistrationTemplateId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Refund Notification Template Id
                                    </Label>
                                            <Input
                                                placeholder="Refund Notification Template Id"
                                                onChange={this.handleChange}
                                                name="refundNotificationTemplateId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Program Name*
                                    </Label>
                                            <Input
                                                placeholder="Program Name"
                                                onChange={this.handleChange}
                                                name="programName"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Recaptcha Site Key
                                    </Label>
                                            <Input
                                                placeholder="Recaptcha Site key"
                                                onChange={this.handleChange}
                                                name="recaptchaSiteKey"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Recaptcha Secret
                                    </Label>
                                            <Input
                                                placeholder="Recaptcha Secret"
                                                onChange={this.handleChange}
                                                name="recaptchaSecret"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Web Service Id
                                    </Label>
                                            <Input
                                                placeholder="Web Service Id"
                                                onChange={this.handleChange}
                                                name="webServiceId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Main Language
                                    </Label>
                                            <Select
                                                options={languageOptions}
                                                placeholder="Main Language"
                                                onChange={this.handleChange}
                                                name="mainLanguage"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Google Place Id
                                    </Label>
                                            <Input
                                                placeholder="Google Place Id"
                                                onChange={this.handleChange}
                                                name="googlePlaceId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                External Id
                                    </Label>
                                            <Input
                                                placeholder="External Id"
                                                onChange={this.handleChange}
                                                name="externalId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Google Analytics Id
                                    </Label>
                                            <Input
                                                placeholder="Google Analytics Id"
                                                onChange={this.handleChange}
                                                name="googleAnalyticsId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Hotjar Id
                                    </Label>
                                            <Input
                                                placeholder="Hotjar Id"
                                                onChange={this.handleChange}
                                                name="hotjarId"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Favicon Url
                                    </Label>
                                            <Input
                                                placeholder="Favicon Url"
                                                onChange={this.handleChange}
                                                name="favicon_url"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Logo Url
                                    </Label>
                                            <Input
                                                placeholder="Logo Url"
                                                onChange={this.handleChange}
                                                name="logo_url"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Host
                                    </Label>
                                            <Input
                                                placeholder="Host"
                                                onChange={this.handleChange}
                                                name="host"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Primary Color
                                    </Label>
                                            <Input
                                                placeholder="Primary Color"
                                                onChange={this.handleChange}
                                                name="colorPrimary"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Secondary color
                                    </Label>
                                            <Input
                                                placeholder="Secondary color"
                                                onChange={this.handleChange}
                                                name="colorSecondary"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Contact Email
                                    </Label>
                                            <Input
                                                placeholder="Contact Email"
                                                onChange={this.handleChange}
                                                name="contactEmail"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Support Contact Email
                                    </Label>
                                            <Input
                                                placeholder="Support Contact Email"
                                                onChange={this.handleChange}
                                                name="supportContactEmail"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Zendesk Id
                                    </Label>
                                            <Input
                                                placeholder="Zendesk Id"
                                                onChange={this.handleChange}
                                                name="zendeskId"
                                            />
                                        </Form.Field>
                                    </Grid.Row>
                                    <Grid.Row className="col-4">
                                        <Form.Field>
                                            <Label>
                                                Phone is Required
                                    </Label>
                                            <Radio
                                                className="black-switch"
                                                onChange={this.handleChangeSwitch}
                                                name="phoneRequired"
                                                toggle
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Email Is Required
                                    </Label>
                                            <Radio
                                                className="black-switch"
                                                onChange={this.handleChangeSwitch}
                                                name="emailRequired"
                                                toggle
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Receipt Scan
                                    </Label>
                                            <Radio
                                                className="black-switch"
                                                onChange={this.handleChangeSwitch}
                                                name="scanEnable"
                                                toggle
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Card Restaurant
                                    </Label>
                                            <Radio
                                                className="black-switch"
                                                onChange={this.handleChangeSwitch}
                                                name="cardRestaurantEnable"
                                                toggle
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Label>
                                                Customer External Id
                                    </Label>
                                            <Radio
                                                className="black-switch"
                                                onChange={this.handleChangeSwitch}
                                                name="customerExternalIdRequired"
                                                toggle
                                            />
                                        </Form.Field>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Form.Button
                                            secondary
                                            size="huge"
                                            className="button"
                                            disabled={
                                                !this.state.form.name ||
                                                !this.state.form.shoppingHubOwnerId ||
                                                !this.state.form.cityId}
                                        >
                                            <Button.Content>Save Hub →</Button.Content>
                                        </Form.Button>
                                    </Grid.Row>
                                </Grid>
                                <Modal
                                    size={'tiny'}
                                    open={this.state.showCreateShoppingHubOwnerModal}
                                >
                                    <Header icon="plus">Create shopping hub owner</Header>
                                    <Modal.Content>
                                        <Form.Field>
                                            <Input
                                                onChange={this.handleChange}
                                                name="shoppingHubOwner"
                                                placeholder={'Shopping Hub Owner'}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Field>
                                        {this.state.shoppingHubOwnerExists ? <b style={{ color: 'red' }}>Shopping hub owner already exists</b> : null}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button
                                            size="mini"
                                            onClick={() => {
                                                this.setState({ showCreateShoppingHubOwnerModal: false, shoppingHubOwner: '' })
                                            }}
                                        >
                                            Cancel
                                </Button>
                                        <Button
                                            color="blue"
                                            size="mini"
                                            onClick={this.createShoppingHubOwner}
                                        >
                                            Create
                                </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Container>
                        </Form>
                    ) : (
                            <div style={{
                                height: '50vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            >
                                <Loader
                                    type="Oval"
                                    color="#1f2532"
                                    height="30"
                                    width="30"
                                />
                            </div>
                        )
                }
                <ToastContainer
                    newestOnTop
                    draggable
                    pauseOnHover
                    transition={Flip}
                />
            </div>

        )
    }
}

export default HubCreationForm
