import React from 'react'
import './ManualAssociation.css'
import HubCreation from '../forms/HubCreation'

const HubCreationPage = () => (
    <section className="ui container support-page">
        <HubCreation />
    </section>
)

export default HubCreationPage
