import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { useTranslation } from 'react-i18next'
import frTranslations from './locales/fr/translations.json'
import enTranslations from './locales/en/translations.json'


i18n
.use(LanguageDetector)
.use(useTranslation)
.init({
    fallbackLng: 'en',
    ns: ['translations'], // have a common namespace used around the full app
    defaultNS: 'translations',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    react: {
        wait: true,
        defaultTransParent: 'span' // the parent surrounding <Trans> component (needed as we're using React < 16)
    }
})
i18n.addResourceBundle('fr', 'translations', frTranslations, true, true)
i18n.addResourceBundle('en', 'translations', enTranslations, true, true)

export default i18n
