import React from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { Button, Message, Modal, Transition, Header, Form } from 'semantic-ui-react'
import { fetchStoresList, sendTransactionAssociationInfo } from '../../../actions/hubSetup'
import { fetchCustomerTransactions } from '../../../actions/supportClient'

function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].name
        obj.text = arr[i].name
        delete obj.hasInitialStoreTransaction
        newArr.push(obj)
    }
    return newArr
}

function displayOneValue(oneValue) {
    if ((oneValue === null || oneValue === undefined)) {
        return 'N/A'
    } if (oneValue === true) {
        return 'true'
    } if (oneValue === false) {
        return 'false'
    }
    return oneValue
}

function parseData(transactionArr) {
    return transactionArr.map(transaction => ({
        transactionId: displayOneValue(transaction.id),
        isAssociated: transaction.storeId ? 'Yes' : 'No',
        date: displayOneValue(transaction.transactionDate),
        label: displayOneValue(transaction.label),
        amount: displayOneValue(transaction.amount),
        customerId: displayOneValue(transaction.customerId),
    }))
}

class CustomerManualAssociationTable extends React.Component {
    state = { selectedTransaction: '', visible: false, storeId: '', transactions: [], modalOpen: false, storesList: [], transactionSubmitted: false }
    componentWillMount = async () => {
        try {
            await this.getStores()
            const table = parseData(this.props.customerTransactions.transactions)

            return this.setState({ transactions: table })
        } catch (err) {
            const { code } = err.response.data
            if (code === 'transactionsNotFound') {
                localStorage.removeItem('hubId')
                localStorage.removeItem('hubName')
                return this.setState({ modalOpen: true })
            }
        }
        return null
    }

    getStores = async () => {
        try {
            if (this.props.customerTransactions) {
                const { customerHubId } = this.props.customerTransactions
                const { stores } = await this.props.fetchStoresList(customerHubId)
                const storesList = manageArr(stores)
                return this.setState({ storesList })
            }
        } catch (err) {
            return null
        }
        return null
    }

    postTransactionAssociation = async () => {
        if (!this.state.selectedTransaction.transactionId || !this.state.storeId) {
            return
        }
        await this.props.sendTransactionAssociationInfo(this.state.storeId, this.state.selectedTransaction.transactionId)
        await this.props.fetchCustomerTransactions(this.state.selectedTransaction.customerId)
        await this.setState({
            transactions: parseData(this.props.customerTransactions.transactions),
            visible: false,
            transactionSubmitted: true,
            modalOpen: false,
            storeId: '',
            storeName: ''
        })
    }

    handleChange = (e, { name, value }) => {
        this.state.storesList.forEach((store) => {
            if (value === store.name) {
                this.setState({
                    storeId: store.id,
                    [name]: value
                })
            }
        })
    }

    transactionSelection = async (transactionInfo) => {
        await this.setState({ modalOpen: true, selectedTransaction: transactionInfo, visible: true })
    }

    handleClose() {
        this.setState({ modalOpen: false, selectedTransaction: '' })
    }

    displayTransactionInfo() {
        const columns = [
            {
                Header: 'General Information',
                columns: [
                    { Header: 'Transaction id', accessor: 'transactionId' },
                    { Header: 'Associated', accessor: 'isAssociated' },
                    { Header: 'Customer id', accessor: 'customerId' },
                    { Header: 'Transaction date', accessor: 'date' },
                    { Header: 'Label', accessor: 'label' },
                    { Header: 'Amount', accessor: 'amount' },
                ]
            },
            { Header: 'Transaction association',
                accessor: 'Transaction association',
                sortable: false,
                filterable: false,
                Cell: ({ row }) => (
                    <Button
                        color="blue"
                        size="mini"
                        disabled={row.isAssociated === 'Yes'}
                        style={{ width: '100%' }}
                        onClick={() => row.isAssociated !== 'Yes' && this.transactionSelection(row)}
                    >{row.isAssociated === 'Yes' ? 'Already Associated' : 'Associate'}</Button>)
            },
        ]
        return (
            <div>
                <ReactTable
                    style={{ height: '600px' /* This will force the table body to overflow and scroll, since there is not enough room */}}
                    data={this.state.transactions}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    noDataText="No transaction"
                    filterable
                    columns={columns}
                />
            </div>
        )
    }

    showMessage() {
        window.setTimeout(() => {
            this.setState({ transactionSubmitted: false,
                visible: false
            })
        }, 6000)
        if (this.state.transactionSubmitted) {
            return (
                this.state.transactionSubmitted && (
                    <Message attached info color="green"><p>The transaction has been successfully associated</p></Message>
                )
            )
        }
        return null
    }

    render() {
        return (
            <div>
                {
                    this.state.transactionSubmitted
                        ? this.showMessage()
                        : <Message attached info><p>You can now link an unassigned transaction to a store</p></Message>
                }
                <div id="table-scroll">
                    {
                        this.state.transactions.length > 0
                            ? this.displayTransactionInfo()
                            : <Message attached error>No transactions found</Message>
                    }
                    <Transition visible={this.state.visible === true} animation="fade up" duration={350}>
                        <Modal size="tiny" open={this.state.modalOpen} className="transaction-validation-modal">
                            <Header content="Transaction association" />
                            <Modal.Content className="validate-association">
                                <p>
                                    <span>Customer.id: </span>
                                    {this.state.selectedTransaction.customerId}
                                </p>
                                <p>
                                    <span>Transaction date: </span>
                                    {this.state.selectedTransaction.date}
                                </p>
                                <p>
                                    <span>Label: </span>
                                    {this.state.selectedTransaction.label}
                                </p>
                                <Form.Group>
                                    {
                                        !!this.state.storesList.length && (
                                            <Form.Select
                                                name="storeName"
                                                selection
                                                value={this.state.storeName}
                                                onChange={this.handleChange}
                                                label="Store: "
                                                options={this.state.storesList}
                                                placeholder="Choose a store"
                                                search
                                                required
                                            />
                                        )
                                    }
                                </Form.Group>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="red" size="mini" onClick={() => this.handleClose()}>
                                    Back
                                </Button>
                                <Button color="green" size="mini" onClick={() => this.postTransactionAssociation()}>
                                    Validate
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Transition>
                    <Button color="red" size='tiny' onClick={this.props.prevStep}>Back</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerTransactions: state.customer.customerTransactions,
})

export default connect(mapStateToProps, { fetchStoresList,
    sendTransactionAssociationInfo,
    fetchCustomerTransactions
})(CustomerManualAssociationTable)
