import React from 'react'
import { Button, Icon, Message } from 'semantic-ui-react'
import axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import Loader from 'react-loader-spinner'
import Validator from 'validator'
import { ToastContainer, toast } from 'react-toastify'
import connect from 'react-redux/es/connect/connect'


import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'react-toastify/dist/ReactToastify.css'

import CreateUserModal from './CreateUserModal'

import { fetchBackUsers } from '../../actions/userManagement'

import UpdateUserModal from './UpdateUserModal'
import DeleteUserModal from './DeleteUserModal'


function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = arr[i].name
        obj.value = arr[i].name
        obj.text = arr[i].name
        delete obj.hasInitialStoreTransaction
        newArr.push(obj)
    }
    return newArr
}


class UserManagement extends React.Component {
    notify = (message) => {
        toast.success(<div>
            <h4>Success</h4>
            <p>{message}</p>
        </div>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 6000
        })
    }
    state = {
        loadingBtn: false,
        isLoading: true,
        userData: [],
        errors: {},
        userFunctionalities: [],
        columnDefs: [
            { headerName: 'Email', field: 'email' },
            { headerName: 'Functionalities', field: 'functionalities', width: 1000 },
            { headerName: 'Edit',
                field: 'id',
                width: 120,
                cellRenderer: () => '<button class="edit-user-btn">Edit</button>',
            },
            { headerName: 'Delete',
                field: 'id',
                width: 120,
                cellRenderer: (params) => {
                    if (params.data.email !== localStorage.getItem('email')) {
                        return '<button class="delete-user-btn">Delete</button>'
                    }
                },
            },
        ],
        functionalities: [],
        updatedFunctionalities: [],
        updatedPassword: '',
        updatedPasswordConfirm: '',
        email: '',
        password: '',
        passwordConfirm: '',
        createUserModalOpen: false,
        updateUserModalOpen: false,
        deleteUserModalOpen: false,
        userCreatedSuccess: false
    }
    onCellClicked = (event) => {
        if (event.colDef.headerName === 'Edit') {
            this.openUpdateUserModal(event.data)
            return this.setState({
                backUserIdToUpdate: event.data.backUserId
            })
        }
        if (event.colDef.headerName === 'Delete' && event.data.email !== localStorage.getItem('email')) {
            this.openDeleteUserModal(event.data)
            return this.setState({
                backUserIdToDelete: event.data.backUserId,
                backUserEmail: event.data.email,
            })
        }
    }
    openCreateUserModal = () => {
        this.setState({ createUserModalOpen: true })
    }
    openUpdateUserModal = (data) => {
        this.setState({ updateUserModalOpen: true, currentFunctionalities: data.functionalities })
    }
    openDeleteUserModal = () => {
        this.setState({ deleteUserModalOpen: true })
    }
    componentWillMount = async () => {
        try {
            const usersResponse = await axios
            .get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
            })

            const functionalitiesResponse = await axios
            .get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/usersFunctionalities`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
            })

            const userFunctionalities = manageArr(functionalitiesResponse.data.backUsersFunctionalityList)
            this.setState({ userData: usersResponse.data.backUsersDetails, userFunctionalities, isLoading: false })
        } catch (err) {
            if (!err.response) {
                return this.setState({ errors: { serverError: 'An error has occurred' }, isLoading: false })
            }
        }
    }
    closeCreateUserModal = () => {
        this.setState({ createUserModalOpen: false, inputError: {}, formError: {} })
    }
    closeUpdateUserModal = () => {
        this.setState({ updateUserModalOpen: false, inputError: {}, formError: {} })
    }
    closeDeleteUserModal = () => {
        this.setState({ deleteUserModalOpen: false, inputError: {}, formError: {} })
    }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value, inputError: {}, formError: {} })
    }
    onSelectionChanged = () => {
        const selectedRows = this.gridApi.getSelectedRows()
        this.setState({
            currentUserEmail: selectedRows.email
        })
    }
    handleSubmit = async () => {
        try {
            if (this.state.createUserModalOpen) {
                if (!this.state.email.length) {
                    this.setState({ inputError: { email: 'Email is required' } })
                } else if (!Validator.isEmail(this.state.email)) {
                    this.setState({ inputError: { email: 'Email is not valid' } })
                } else if (this.state.password !== this.state.passwordConfirm) {
                    this.setState({ inputError: { passwordConfirm: 'Password and password confirmation are not the same' } })
                } else if (!new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{9,}$/).test(this.state.password)) {
                    this.setState({ inputError: { password: 'Password must contains at least 9 chars, one uppercase, one lowercase and one digit' } })
                } else if (!this.state.functionalities.length) {
                    this.setState({ inputError: { functionalities: 'A least one functionality is required' } })
                } else {
                    this.setState({ loadingBtn: true })

                    const functionalitiesRequest = []
                    this.state.userFunctionalities.forEach((userFunctionality) => {
                        this.state.functionalities.forEach((el) => {
                            if (el === userFunctionality.name) {
                                functionalitiesRequest.push(userFunctionality.code)
                            }
                        })
                    })

                    await axios.post(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                        email: this.state.email, password: this.state.passwordConfirm, functionalities: functionalitiesRequest }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    })
                    const usersResponse = await axios
                    .get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
                    })
                    this.notify('🚀   User successfully created !')
                    this.setState({
                        createUserModalOpen: false,
                        loadingBtn: false,
                        userData: usersResponse.data.backUsersDetails,
                        functionalities: [],
                        password: '',
                        passwordConfirm: '',
                        email: ''
                    })
                }
            } else if (this.state.updateUserModalOpen) {
                if (!this.state.updatedFunctionalities.length && !this.state.updatedPassword) {
                    this.setState({ formError: { required: 'At lease one parameter is required for updating back user' } })
                } else {
                    this.setState({ loadingBtn: true })
                    const updateFunctionalitiesRequest = []
                    this.state.userFunctionalities.forEach((userFunctionality) => {
                        this.state.updatedFunctionalities.forEach((el) => {
                            if (el === userFunctionality.name) {
                                updateFunctionalitiesRequest.push({
                                    code: userFunctionality.code,
                                    name: userFunctionality.name
                                })
                            }
                        })
                    })

                    const userId = this.state.backUserIdToUpdate
                    await axios.patch(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users/${userId}`,
                        {
                            password: this.state.updatedPasswordConfirm,
                            functionalities: updateFunctionalitiesRequest
                        }, {
                            headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                        })
                    this.notify('🚀   User successfully updated !')
                    const usersResponse = await axios
                    .get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
                    })
                    this.setState({
                        backUserIdToUpdate: null,
                        updateUserModalOpen: false,
                        loadingBtn: false,
                        userData: usersResponse.data.backUsersDetails,
                        userCreatedSuccess: true,
                        updatedFunctionalities: [],
                        updatedPassword: '',
                        updatedPasswordConfirm: '',

                    })
                }
            } else if (this.state.deleteUserModalOpen) {
                const userId = this.state.backUserIdToDelete
                await axios.delete(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users/${userId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
                })
                const usersResponse = await axios
                .get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` }
                })
                this.setState({
                    backUserIdToUpdate: null,
                    deleteUserModalOpen: false,
                    loadingBtn: false,
                    userData: usersResponse.data.backUsersDetails
                })
                this.notify('🚀   User successfully deleted !')
            }
        } catch (err) {
            if (!err.response) {
                this.setState({ formError: { serverError: 'An error has occurred' }, loadingBtn: false })
            } else if (err.response.data.code === 'backUserAlreadyExists') {
                this.setState({ formError: { emailAlreadyExists: 'A user with same email already exists in database' }, loadingBtn: false })
            } else if (err.response.data.code === 'required') {
                this.setState({ formError: { emailAlreadyExists: 'At least one value is required' }, loadingBtn: false })
            } else if (err.response.data.code === 'invalidPassword') {
                this.setState({ inputError: { passwordConfirm: 'Password must contains at least 9 chars, one uppercase, one lowercase and one digit' }, loadingBtn: false })
            } else if (err.response.data.code === 'invalidFunctionalities') {
                this.setState({ inputError: { functionalities: 'Invalid functionalities' }, loadingBtn: false })
            }
        }
    }

    render() {
        return (
            <div style={{ minHeight: '90vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ right: '6rem', backgroundColor: '#00b5ad', color: 'white' }}
                            onClick={() => this.openCreateUserModal()}
                            size={'tiny'}
                        >
                            <Icon name="plus" /> Create a user
                        </Button>
                    </div>
                    {
                        !!(!this.state.isLoading && this.state.errors.serverError) && (
                            <Message negative attached>
                                <Message.Header>{this.state.errors.serverError}</Message.Header>
                            </Message>
                        )
                    }
                    {
                        !this.state.isLoading ? (
                            <div
                                className="ag-theme-material"
                                style={{
                                    height: '80vh',
                                    width: '100%',
                                    marginTop: '30px',
                                    margin: 'auto'
                                }}
                            >
                                <AgGridReact
                                    enableSorting
                                    pivotPanelShow={'always'}
                                    animateRows
                                    enableFilter
                                    onCellClicked={this.onCellClicked}
                                    paginationAutoPageSize
                                    enableColResize
                                    onSelectionChanged={this.onSelectionChanged}
                                    onRowSelected={this.onRowSelected}
                                    rowSelection="single"
                                    columnDefs={this.state.columnDefs}
                                    pagination={false}
                                    rowData={this.state.userData}
                                    onGridReady={params => this.gridApi = params.api}
                                />
                            </div>
                        ) : (
                            <div style={{
                                height: '50vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            >
                                <Loader
                                    type="Oval"
                                    color="#1f2532"
                                    height="30"
                                    width="30"
                                />
                            </div>
                        )
                    }
                    <CreateUserModal
                        visible={this.state.createUserModalOpen}
                        functionalitiesOptions={this.state.userFunctionalities}
                        onChange={this.handleChange}
                        closeModal={this.closeCreateUserModal}
                        onSubmit={this.handleSubmit}
                        loadingBtn={this.state.loadingBtn}
                        inputError={this.state.inputError}
                        formError={this.state.formError}
                    />
                    {
                        this.state.updateUserModalOpen && <UpdateUserModal
                            visible={this.state.updateUserModalOpen}
                            functionalitiesOptions={this.state.userFunctionalities}
                            onChange={this.handleChange}
                            closeModal={this.closeUpdateUserModal}
                            defaultFunctionalitiesValues={this.state.currentFunctionalities}
                            onSubmit={this.handleSubmit}
                            loadingBtn={this.state.loadingBtn}
                            inputError={this.state.inputError}
                            formError={this.state.formError}
                        />
                    }
                    {
                        this.state.deleteUserModalOpen && <DeleteUserModal
                            visible={this.state.deleteUserModalOpen}
                            functionalitiesOptions={this.state.userFunctionalities}
                            onChange={this.handleChange}
                            closeModal={this.closeDeleteUserModal}
                            defaultFunctionalitiesValues={this.state.currentFunctionalities}
                            onSubmit={this.handleSubmit}
                            userEmail={this.state.backUserEmail}
                            userId={this.state.backUserIdToDelete}
                            loadingBtn={this.state.loadingBtn}
                            inputError={this.state.inputError}
                            formError={this.state.formError}
                        />
                    }


                    <ToastContainer
                        newestOnTop
                        draggable
                        pauseOnHover
                    />


            </div>
        )
    }
}

const mapStateToProps = state => ({
    backUsers: state.userManagement.backUsers,
})

export default connect(mapStateToProps, {
    fetchBackUsers
})(UserManagement)

