import { CUSTOMER_ALL_STATS, CUSTOMER_DAILY_STATS, CLEAR_DAILY_STATS } from '../types'

export default function customerStats(state = {}, action = {}) {
    switch (action.type) {
    case CUSTOMER_ALL_STATS:
        return { ...state, customerAllStats: action.payload }
    case CUSTOMER_DAILY_STATS:
        return { ...state, customerDailyStats: action.payload }
    case CLEAR_DAILY_STATS:
        return { ...state, customerDailyStats: null }    
    default: return state
    }
}