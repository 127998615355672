/* eslint-disable */
import React, { useEffect } from 'react'
import * as d3 from 'd3'
import _ from 'lodash'

import 'font-awesome/css/font-awesome.min.css'

import api from '../../../../../api'

import simulation_session from './simulationSession'
import simulation_sharedCustomer from './simulationLifeTime'
import circleSize from './circleSizeFunction'


import './storeGraph.scss'

// D3 JS FUNCTION
function generateSvg(
    data,
    nodesDict,
    height,
    width,
    click,
    dispatch,
    levier,
    sizeOption,
    risks,
    ee,
    potentials
) {
    function color() {
        const scale = d3.scaleOrdinal(d3.schemePaired)
        return d => scale(d.group)
    }

    // Building the svg
    const links = data.links.map(d => Object.create(d))
    const nodes = data.nodes.map(d => Object.create(d))

    let simulation
    if (levier === 'sessions') {
        simulation = simulation_session(nodes, links, nodesDict, width, height)
    } else if (levier === 'sharedCustomer') {
        simulation = simulation_sharedCustomer(
            nodes,
            links,
            nodesDict,
            width,
            height
        )
    }

    const body = d3.select('#ForceGraph')

    const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0)
    d3.select('#zoomin').on('click', () => {
        zoom.scaleBy(svg.transition().duration(750), 1.5)
    })
    d3.select('#zoomout').on('click', () => {
        zoom.scaleBy(svg.transition().duration(750), 0.66)
    })
    d3.select('#resetzoom').on('click', () => {
        zoom.scaleTo(svg.transition().duration(750), 1)
    })

    d3.select('#reset').on('click', () => {
        circle.style('opacity', o => 1)
        link.style('opacity', o => 1)
    })

    const zoom = d3
    .zoom()
    .scaleExtent([1 / 3, 5])
    .on('zoom', zoomed)

    let toggle = 0

    const rect = body
    .append('rect')
    .attr('width', width)
    .attr('height', height)

    const svg = rect
    .append('svg')
    .attr('width', width)
    .attr('height', height)

    const link = svg
    .append('g')
    .attr('stroke', '#999')
    .attr('stroke-opacity', 0.6)
    .selectAll('line')
    .data(links)
    .join('line')
    .attr('stroke-width', (d) => {
        if (levier === 'sessions') {
            if (d.value >= 1.4) {
                return d.value
            }
            return 0
        } else if (d.value >= 8) {
            return d.value / 10
        }
        return 0
    })

    const gNode = svg
    .attr('id', 'svgChart')
    .append('g')
    .attr('stroke', '#fff')
    .attr('stroke-width', 1)
    .selectAll('g')
    .data(nodes)
    .join('g')

    const textNode = svg
    .attr('id', 'svgChart')
    .append('g')
    .attr('stroke', '#fff')
    .attr('stroke-width', 0)
    .selectAll('g')
    .data(nodes)
    .join('g')

    const circle = gNode
    .append('circle')
    .attr('r', d => circleSize(sizeOption, d))
    .attr('fill', color())

    // --------------- Pop Up HTML -----------------------
    // const foreigner = gNode
    //   .filter(function(d) {
    //     return _.find(risks, { name: _.kebabCase(d.id) });
    //   })
    //   .append("foreignObject")
    //   .attr("width", 30)
    //   .attr("height", 30);

    // const foDiv = foreigner
    //   .append("xhtml:div")
    //   .append("div")
    //   .attr("class", "danger");

    // foDiv
    //   .append("p")
    //   .attr("class", "lead")
    //   .html(d => d.id);

    // foDiv.append("p").html("I am default");

    // ----------------------------------------------------

    const labels = textNode
    .append('text')
    .attr('id', d => `t${d.index}`)
    .attr('x', d => d.x + 20)
    .attr('y', d => d.y)
    .text('')

    const dangerNode = svg
    .attr('id', 'svgChart')
    .append('g')
    .attr('stroke', '#fff')
    .attr('stroke-width', 0)
    .selectAll('g')
    .data(nodes)
    .join('g')

    const dangerText = dangerNode
    .append('text')
    .attr('stroke', '#fff')
    .attr('stroke-width', 0)
    .attr('font-family', 'FontAwesome')
    .attr('fill', 'red')
    .attr('id', 'text')
    .attr('text-anchor', 'middle')
    .attr('class', 'my_icon')
    .text((d) => {
        if (
            _.find(risks, o => _.kebabCase(o.name) === _.kebabCase(d.id))
        ) {
            return '\uf071'
        }
        return null
    })

    const potential = dangerNode
    .append('text')
    .attr('stroke', '#fff')
    .attr('stroke-width', 0)
    .attr('font-family', 'FontAwesome')
    .attr('fill', 'green')
    .attr('id', 'potential')
    .attr('text-anchor', 'middle')
    .attr('class', 'my_icon')
    .text((d) => {
        if (
            _.find(potentials, o => _.kebabCase(o.name) === _.kebabCase(d.id))
        ) {
            return '\uf058'
        }
        return null
    })

    const blank = svg
    .append('rect')
    .attr('id', 'blank')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('fill', 'white')

    const waiter = d3.timer((elapsed) => {
        blank.remove()
        waiter.stop()
    }, 1000)

    circle.append('title').text(d => d.id)

    circle.selectAll(function (d, i) {
        if (d3.select(this).attr('r') >= 12) {
            d3.select(`#t${d.index}`).text(d.id)
        }
    })

    circle.on('mouseover', function (d, i) {
        circle.selectAll((e, j) => {
            if (e && e !== d) {
                d3.select(`#t${e.index}`).text('')
            }
        })
        d3.select(this).attr('r', Math.max(d3.select(this).attr('r') * 1.3, 15))
        d3.select(`#t${d.index}`).text(d.id)
    })

    circle.on('mouseout', (d, i) => {
        circle.attr('r', e => circleSize(sizeOption, e))
        circle.selectAll(function (d, i) {
            if (d3.select(this).attr('r') >= 12) {
                d3.select(`#t${d.index}`).text(d.id)
            }
        })
    })

    d3.selectAll('#potential').on('mouseover', (d, i) => {
        tooltip
        .transition()
        .duration(500)
        .style('opacity', 0.9)
        .style('background', 'green')
        tooltip
        .html('Potentiel!')
        .style('top', `${d3.event.pageY - 10}px`)
        .style('left', `${d3.event.pageX + 10}px`)
    })

    d3.selectAll('#potential').on('mouseout', (d, i) => {
        tooltip
        .transition()
        .duration(500)
        .style('opacity', 0)
    })

    d3.selectAll('#text').on('mouseover', (d, i) => {
        tooltip
        .transition()
        .duration(500)
        .style('opacity', 0.9)
        .style('background', 'red')
        tooltip
        .html('Danger!')
        .style('top', `${d3.event.pageY - 10}px`)
        .style('left', `${d3.event.pageX + 10}px`)
    })

    d3.selectAll('#text').on('click', (d, i) => {
        ee.emit('dangerSelected', d.id)
    })

    d3.selectAll('#text').on('mouseout', (d, i) => {
        tooltip
        .transition()
        .duration(500)
        .style('opacity', 0)
    })

    circle.on('click', (d, i) => {
        connectedNodes(d)
        click(d)
    })

    dispatch.on('nodeClick', function () {
        const node = d3.select(`#${_.kebabCase(this)}`)
        node.select((d) => {
            connectedNodes(d)
        })

        node.attr('r', 15)
        d3.select('svg')
        .append('text')
        .attr('id', 'n')
        .attr('x', node.attr('cx') + 20)
        .attr('y', node.attr('cy'))
        .text(this)
        node.style('opacity', 1)
    })

    simulation.on('tick', () => {
        for (let i = 0; i < 10; i++) {
            simulation.tick()
        }
        link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y)

        circle
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('id', d => _.kebabCase(d.id))

        dangerText.attr('x', d => d.x + 7).attr('y', d => d.y - 7)

        potential.attr('x', d => d.x - 7).attr('y', d => d.y - 7)

        labels.attr('x', d => d.x + 20).attr('y', d => d.y)
    })

    function bestLinks(storeName) {
        let graphName = ''
        if (levier === 'sessions') {
            graphName = 'session'
        } else if (levier === 'sharedCustomer') {
            graphName = 'jaccard'
        }

        return api.offers.get('get_best_links/', {
            params: {
                graphName,
                storeName
            }
        })
    }

    function connectedNodes(d) {
        bestLinks(d.id).then((res) => {
            const { data } = res
            if (toggle === 0) {
                circle.style('opacity', o => (_.some(data, elemt => elemt[0] === o.id || d.id === o.id)
                    ? 1
                    : 0.1))
                link.style('opacity', (o) => {
                    const sourceStore = o.target.id === d.id ? o.target.id : o.source.id
                    const targetStore = o.target.id === d.id ? o.source.id : o.target.id
                    return _.some(
                        data,
                        elemt => d.id === sourceStore && elemt[0] === targetStore
                    )
                        ? 1
                        : 0.1
                })
            } else {
                circle.style('opacity', 1)
                link.style('opacity', 1)
                toggle = 0
            }
        })
    }

    function zoomed() {
        svg.attr('transform', d3.event.transform)
    }
}

function getNodesFromData(data) {
    const nodes = {}
    data.nodes.forEach((node) => {
        nodes[node.id] = node.group
    })
    return nodes
}

function ForceGraph({ id, ...props }) {
    const height = 600
    const dispatch = d3.dispatch('nodeClick')

    const nodes = getNodesFromData(props.data)

    props.event_emitter.on('nodeClick', (data) => {
        dispatch.call('nodeClick', data)
    })

    useEffect(() => {
        d3.select('#ForceGraph')
        .selectAll('*')
        .remove()

        generateSvg(
            props.data,
            nodes,
            height,
            props.width,
            props.onClick,
            dispatch,
            props.levier,
            props.sizeOption,
            props.risks,
            props.event_emitter,
            props.potentials
        )
    }, [props.levier, props.sizeOption])

    return <div id={id} />
}

export default ForceGraph
