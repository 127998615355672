import React, { Component } from 'react'

import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip'
import 'rc-slider/assets/index.css'
import { EventEmitter } from 'events'

import {
    Form,
    Input,
    Grid,
    Container,
    Icon,
    Header,
    Select,
    Button
} from 'semantic-ui-react'

import api from '../../../api'

import StatsOffer from './StatsOffer'


const Handle = Slider.Handle

const graph = [
    { key: 'customers', text: 'Répartition des clients', value: 'customers' },
    { key: 'stores', text: 'Répartition du budget', value: 'stores' }
]

class Automatic extends Component {
    constructor(props) {
        super(props)

        this.state = {
            duration: 500,
            offerName: '',
            graph: 'customers',
            dateBegin: '',
            dateEnd: '',
            time: '',
            max_budget: 0,
            dateTime: '',
            datesRange: '',
            turnoverParameter: '',
            activeIndex: 0,
            ca: 0,
            interaction: 0,
            budget: 0,
            ee: new EventEmitter()
        }

        this.timeout = 0
        this.timeoutTime = 300
    }

    componentDidMount() {
        this.state.ee.emit('turnoverParameter', 0)
        this.state.ee.emit('interactionParameter', 0)
        api.offers.get('max_budget').then((res) => {
            this.setState({ max_budget: res.data.max_budget })
        })
    }

  handleChangeGraph = (e, { value }) => {
      this.setState({ graph: value })
  };

  handleChange = (event, { name, value }) => {
      if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value })
      }
  };

  handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex })
  };

  onChange = (e) => {
      this.state.ee.emit(e.target.name, e.target.value)
      const { state } = this
      state[e.target.name] = e.target.value
      this.setState(state)
  };

  handleChangeCA = (sliderValue) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ ca: sliderValue })
          this.state.ee.emit('turnoverParameter', sliderValue)
      }, this.timeoutTime)
  };

  handleChangeInteraction = (sliderValue) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ interaction: sliderValue })
          this.state.ee.emit('interactionParameter', sliderValue)
      }, this.timeoutTime)
  };

  handleChangeBudget = (sliderValue) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ budget: sliderValue })
          this.state.ee.emit('budget', sliderValue)
      }, this.timeoutTime)
  };

  render() {
      const handle = (sentence1, sentence2) => (props) => {
          const { value, dragging, index, ...restProps } = props
          return (
              <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={sentence1 + value + sentence2}
                  visible={dragging}
                  placement="top"
                  key={index}
              >
                  <Handle value={value} {...restProps} />
              </Tooltip>
          )
      }
      const handlePriority = l => (props) => {
          const { value, dragging, index, ...restProps } = props
          return (
              <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={l[value + 2]}
                  visible={dragging}
                  placement="top"
                  key={index}
              >
                  <Handle value={value} {...restProps} />
              </Tooltip>
          )
      }
      return (
          <Grid>
              <Grid.Row>
                  <Grid.Column width={4}>
                      <Container
                          fluid
                          className="my_container"
                          style={{
                              boxShadow: '1px 0px 10px 1px rgba(0,0,0,0.1)',
                              padding: '10px'
                          }}
                      >
                          <Container fluid className="my_container">
                              <Header
                                  textAlign="center"
                                  as="h2"
                                  style={{ paddingTop: '10px' }}
                              >
                                  <Header.Content>
                                      <Icon size={'small'} name="paper plane" />
                    Lancer une offre
                                  </Header.Content>
                              </Header>
                              <Form>
                                  <Form.Field
                                      name="offerName"
                                      value={this.state.offerName}
                                      onChange={this.handleChange}
                                      control={Input}
                                      label="Nom de l'offre"
                                      placeholder="Nom de l'offre"
                                  />
                                  <p> Priorisation des boutiques synergiques </p>
                                  <Slider
                                      style={{ width: '100%' }}
                                      color="blue"
                                      inverted={false}
                                      handle={handlePriority([
                                          'Très forte discriminiation des boutiques synergiques',
                                          'Forte discriminiation des boutiques synergiques',
                                          'Aucune priorisation',
                                          'Forte priorisation des boutiques synergiques',
                                          'Très forte priorisation des boutiques synergiques'
                                      ])}
                                      min={-2}
                                      max={2}
                                      onChange={this.handleChangeInteraction}
                                      defaultValue={this.state.interaction}
                                  />
                                  <p> Priorisation des boutiques à grand CA </p>
                                  <Slider
                                      style={{ width: '100%' }}
                                      color="blue"
                                      handle={handlePriority([
                                          'Très forte discriminiation des boutiques à grand CA',
                                          'Forte discriminiation des boutiques à grand CA',
                                          'Aucune priorisation',
                                          'Forte priorisation des boutiques à grand CA',
                                          'Très forte priorisation des boutiques à grand CA'
                                      ])}
                                      min={-2}
                                      max={2}
                                      onChange={this.handleChangeCA}
                                      inverted={false}
                                      defaultValue={this.state.ca}
                                  />
                                  <br />

                                  <div style={{ borderTop: '1px solid #4b74ff' }}>
                                      <br />
                                      <p> Budget total des offres </p>
                                      <Slider
                                          style={{
                                              width: '100%'
                                          }}
                                          color="blue"
                                          min={0}
                                          max={this.state.max_budget}
                                          handle={handle('', '€ de budget maximum')}
                                          onChange={this.handleChangeBudget}
                                          inverted={false}
                                          defaultValue={this.state.budget}
                                      />
                                  </div>
                              </Form>

                              <br />
                              <Container>
                                  <Button
                                      icon="right arrow"
                                      id="sendOfferButton"
                                      labelPosition="right"
                                      content="Lancer l'offre"
                                  />
                              </Container>
                          </Container>
                      </Container>
                      <Container
                          fluid
                          className="my_container"
                          style={{
                              boxShadow: '1px 0px 10px 1px rgba(0,0,0,0.1)',
                              padding: '10px',
                              marginTop: '30px'
                          }}
                      >
                          <Header textAlign="center" as="h2" style={{ paddingTop: '10px' }}>
                              <Header.Content>Choix du graphique</Header.Content>
                          </Header>
                          <Form>
                              <Form.Field
                                  control={Select}
                                  label="Type du graphique"
                                  options={graph}
                                  onChange={this.handleChangeGraph}
                                  value={this.state.graph}
                                  placeholder="Type du graphique"
                              />
                          </Form>
                      </Container>
                  </Grid.Column>
                  <Grid.Column width={12}>
                      <StatsOffer
                          event_emitter={this.state.ee}
                          turnoverParameter={this.state.turnoverParameter}
                          graph={this.state.graph}
                      />
                  </Grid.Column>
              </Grid.Row>
          </Grid>
      )
  }
}

export default Automatic
