import PropTypes from 'prop-types'
import { Form } from 'semantic-ui-react'
import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
    color: #021041 !important;
    text-align: left;
    font-size: 14px !important;
    font-weight: 900 !important;
    margin-bottom: 12px !important;
`
const InputForm = styled.input`
    width: 100% !important;
    height: 40px !important;
    border: 1px solid #00000073 !important;
`

const FormField = styled(Form.Field)`
  width: 22% !important;
`


const CustomerSupportInput = ({ label, placeholder, onChange, clearOtherInputValues, type, value, className, name }) => {

    return (
        <FormField>
            <Label> {label}</Label>
            <InputForm
                placeholder={placeholder}
                type={type}
                className={className}
                value={value}
                onChange={onChange}
                onClick={(e) => clearOtherInputValues(e)}
                name={name}
            />
        </FormField>

    )
}

CustomerSupportInput.propTypes = {
  className: PropTypes.string,
  clearOtherInputValues: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default CustomerSupportInput
