import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import dateFormat from 'dateformat'
import { Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import axios from 'axios'

import CustomerSupportDetailsTable from './CustomerSupportDetailsTable'
import eyeIcon from '../../../img/oeil.svg'
import { fetchInfoFromId, fetchIbanByMgPayOutId, deleteState, fetchShoppingHubName } from '../../../redux/customerInfo'
import i18n from '../../../i18n'
import CustomerSupportErrorModal from "./Modal/CustomerSupportErrorModal"
import i18next from "i18next"

const DivPage = styled.div`
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
`

const DivContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #F9FBFF;
    padding: 45px;
    padding-bottom: 15px;
    margin-bottom: 30px
`

const DivContainerError = styled.div`
    width: 100%;
    height: 100%;
`

const Title = styled.h1`
    margin: 0;
`

const DivCustomerId = styled.div`
    &:hover{
        cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    width: 110%;
    font-weight: 400;
`

const DivUnderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px;
`

const DivContainerCustomer = styled.div`
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const DivDataLeft = styled.div`
    height: 150px;
    display: flex;
    width: 35%;
    justify-content: space-between;
    align-items: center;
`

const DivDataRight = styled.div`
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SpanNumber = styled.span`
    background-color: #515B80;
    padding: 10px 41px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 900;
    color: white;
`

const SpanBlack = styled(SpanNumber)`
    background-color: #202435;
`

const SpanBlueDark = styled(SpanNumber)`
    background-color: #021041;
`

const DivSpan = styled.div`
    width: 100%;
    font-weight: bold;
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    height: 90px;
`

const CustomerIdP = styled.p`
    &:hover{
        cursor: pointer;
    }
    margin: 0 !important;
    font-weight: initial
`

const SpanTitle = styled.span`
    color: #202432;
    font-weight: bold;
    margin: 5px 0;
`

const SpanData = styled.span`
    color: #00000073;
    margin: 5px 0;
`

const SpanDataEmail = styled(SpanData)`
    &:hover{
        cursor: pointer;
    }
    display: flex;
    justify-content: space-around;
    align-items: center;
`
const SpanDataPhone = styled(SpanData)`
  width: 140%;
  text-align: left;
`

const DivContainerTable = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 45px;
    margin-bottom: 30px;
`

const DivLoader = styled.div`
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center
`

const SelectStore = styled.select`
    border: none;
    background-color: #F9FBFF;
`

const DivDataBasic = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
`

const NewSearch = styled.a`
    &:hover{
        color: #515B80;
        cursor: pointer
    }
        color: #202532;
        text-align: left;
        font-weight: bold;
        font-size: 13px;
        margin: 20px 0 0 20px;
        align-self: flex-start;
`

class CustomerSupportDetailsHome extends Component {
    static propTypes = {
        customerInfo: PropTypes.objectOf(PropTypes.shape({
            isFetchingPhoneNumber: PropTypes.string,
            isFetchingCustomerInfo: PropTypes.bool,
            phoneNumber: PropTypes.number,
            countryCode: PropTypes.number,
            email: PropTypes.string,
            ibanUpdateURL: PropTypes.string,
            subscribeDate: PropTypes.string,
            bankConnections: PropTypes.objectOf(PropTypes.array),
            scans: PropTypes.objectOf(PropTypes.array),
            cashback: PropTypes.objectOf(PropTypes.array),
            infoCustomer: PropTypes.object,
            isFetchingCustomerIdList: PropTypes.bool,
            customerIdLst: PropTypes.array,
            bankConnectionSyncro: PropTypes.object,
            waiting: PropTypes.bool,
            isFetchingIban: PropTypes.bool,
            receipt: PropTypes.object,
            transactionsList: PropTypes.object,
            rewards: PropTypes.objectOf(PropTypes.array)
        })).isRequired,
        fetchInfoFromId: PropTypes.func.isRequired,
        fetchIbanByMgPayOutId: PropTypes.func.isRequired,
    }

    state = {
        phoneNumber: '',
        customerId: this.props.customerId,
        countryCode: 33,
        bankConnectionAttempt: [],
        scansAccepted: null,
        scansNotAccepted: null,
        email: '',
        createdAt: null,
        ibanUpdateURL: null,
        bankConnectionSyncro: [],
        bankReward: [],
        transactions: [],
        done: null,
        rewardsValue: '0',
        isOpen: false,
        isOpenEmail: false,
        isOpenIban: false,
        showIbanData: '',
        payoutId: [],
        blockedPassword: false,
        error: false,
        errorMessage: null,
    }

    componentWillMount = async () => {
        await this.loadingInformations()
    }

    onCellClicked = async (e) => {
        // If the user want to see the iban of a customer
        if (e.colDef.headerName === 'IBAN') {
            try {
                await this.props.fetchIbanByMgPayOutId(e.data.payOutId)
                const { payoutId } = this.state
                payoutId.push(e.data.payOutId)
                this.setState({ showIbanData: this.props.customerInfo.iban.iban, payoutId })
                // We refresh the data inside the table
                const params = { force: true }
                this.gridApi.refreshCells(params)
            } catch (err) {
                const { payoutId } = this.state
                payoutId.push(e.data.payOutId)
                // If there's no iban to show we display an error message
                this.setState({ showIbanData: 'No iban available', payoutId })
                const params = { force: true }
                this.gridApi.refreshCells(params)
                console.log(err)
            }
        }
    }

    getTotalCashBack = () => {
        const {cashback} = this.props.customerInfo
        let total = 0
        if (cashback) {
            for (let i = 0; i < cashback.length; i++) {
                if (cashback[i].rewardState === 'RFND') {
                    total += parseFloat(cashback[i].rewardValue)
                }
            }
        }
        this.setState({ rewardsValue: total.toFixed(2) })
    }

    /*
        Check date format of parameter date
     */
    isValidDate = (date) => isNaN(Date.parse(date)) ? false :  true

    setTabParameter = () => {
        const TabConnectionSyncro = this.state.bankConnectionSyncro.map(bankConnection => ({
            bankName: bankConnection.name,
            syncronisation: dateFormat(bankConnection.createdAt, 'dd/mm/yyyy HH:MM'),
            desyncronisation: dateFormat(bankConnection.lastUpdate, 'dd/mm/yyyy HH:MM'),
            etat: (bankConnection.error ? bankConnection.error : 'OK'),
        }))

        const TabTentativeSyncro = this.state.bankConnectionAttempt.map(bankTentative => ({
            bank: bankTentative.name,
            creation: bankTentative.creation ? i18next.t('common.yes') : i18next.t('common.no'),
            responseCode: bankTentative.responseCode,
            reason: bankTentative.errorCode || bankTentative.providerErrorCode || '-',
            date: dateFormat(bankTentative.createdat, 'dd/mm/yyyy HH:MM')
        }))

        const TabRewards = this.state.bankReward.map((reward) => {
            const { challenge } = reward
            return {
                offerName: challenge && challenge.teaser ? challenge.teaser : 'N/A',
                offerStart: challenge && challenge.startDate && this.isValidDate(challenge.startDate) ? dateFormat(challenge.startDate, 'dd/mm/yyyy') : 'N/A',
                offerEnd: challenge && challenge.endDate && this.isValidDate(challenge.endDate) ? dateFormat(challenge.endDate, 'dd/mm/yyyy') : 'N/A',
                date: dateFormat(reward.updatedAt, 'dd/mm/yyyy HH:MM'),
                label: challenge && challenge.conditionLabel ? challenge.conditionLabel : 'N/A',
                status: reward.rewardState,
                amount: `${reward.rewardValue} €`,
                payOutId: reward.cashbackId
            }
        })

        const TransactionsList = this.state.transactions
            .filter((transaction) => transaction.storeName && transaction)
            .map((transaction) => {
                let currency = 'N/D'

                if (transaction.currency === 'EUR') {
                    currency = '€'
                } else if (transaction.currency === 'GBP') {
                    currency = '£'
                } else if (transaction.currency === 'USD') {
                    currency = '$'
                }
                return {
                    date: transaction.transactionDate,
                    amount: `${transaction.amount}  ${currency}`,
                    storeName: transaction.storeName,
                    bankname: transaction.bankName
                }

            })

        this.setState({
            done: true,
            bankConnectionSyncro: TabConnectionSyncro,
            bankConnectionAttempt: TabTentativeSyncro,
            bankReward: TabRewards,
            transactions: TransactionsList
        })
    }

    // We load and set all the information about a customer with customer ID
    loadingInformations = async () => {
        const {customerId, fetchInfoFromId, fetchShoppingHubName} = this.props;
        if (customerId) {
            try {
                if (!this.props.customerInfo.customerInfo) { // prevent from double api call
                    await fetchInfoFromId(customerId)
                }

                const { customerInfo } = this.props;
                const { phone, countryCallingCode, email, createdAt, registrationDate, ibanUpdateURL, passwordNbAttempt } = customerInfo.customerInfo.infoCustomer
                const { validated, totalscan } = customerInfo.customerInfo.countReceipt
                const { bankConnection, bankConnectionAttempt } = customerInfo.bankConnection
                const rewards = customerInfo.cashback

                await fetchShoppingHubName(customerInfo.transactionsList.customerHubId)

                // We put the data we receive from the API inside the state
                this.setState({
                    phoneNumber: phone || 'N/A',
                    countryCode : countryCallingCode,
                    email: email || 'N/A',
                    createdAt,
                    ibanUpdateURL: ibanUpdateURL || 'N/A',
                    registrationDate,
                    scansAccepted: validated,
                    scansNotAccepted: totalscan,
                    bankConnectionSyncro: bankConnection,
                    bankConnectionAttempt: bankConnectionAttempt || [],
                    rewardValue: rewards,
                    bankReward: rewards,
                    transactions: customerInfo.transactionsList.transactions,
                    blockedPassword: passwordNbAttempt >= 3,
                })
            } catch (err) {
                console.log(err)
            }
            // We put the data inside the tables
            this.setTabParameter()

            // We calcul the total cashback amount
            this.getTotalCashBack()
        }
    }

    // If there is more than one shopp and the user want to change it
    changeShopp = async (event) => {
        const { selectedIndex } = event.target.options
        // We get the id of the hub selected
        const id = event.target.options[selectedIndex].getAttribute('data-key')

        await this.setState({ customerId: id })

        // We reload the information
        await this.loadingInformations()
    }

    // Open the "copy" pop-up
    handleOpenPopUp = () => {
        this.setState({ isOpen: true })
        this.timeout = setTimeout(() => {
            this.setState({ isOpen: false })
        }, 600)
    }
    handleOpenEmailPopUp = () => {
        this.setState({ isOpenEmail: true })
        this.timeout = setTimeout(() => {
            this.setState({ isOpenEmail: false })
        }, 600)
    }
    handleOpenIbanPopUp = () => {
        this.setState({ isOpenIban: true })
        this.timeout = setTimeout(() => {
            this.setState({ isOpenIban: false })
        }, 600)
    }

    // Close the "Copié" pop-up
    handleClosePopUp = () => {
        this.setState({ isOpen: false })
        clearTimeout(this.timeout)
    }
    handleCloseEmailPopUp = () => {
        this.setState({ isOpenEmail: false })
        clearTimeout(this.timeout)
    }
    handleCloseIbanPopUp = () => {
        this.setState({ isOpenIban: false })
        clearTimeout(this.timeout)
    }
    closeErrorModal = () => {
        this.setState({ error: false })
    }

    unlockPassword = async (event) => {
        const component = this
        await axios.post(`${process.env.REACT_APP_TC_HOST}/backoffice/support/customer/unlockPassword`,
            {
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                    customerId: this.state.customerId,
                },
            })
            .then(() => {
                component.setState({ blockedPassword: false })
            })
            .catch((err) => {
                component.setState({ blockedPassword: true })
                return this.setState({ error: true, errorMessage: i18next.t('common.errorMessage') })
            })
    }

    render() {
        const { email, customerId, error } = this.state
        const { customerInfo } = this.props
        return (
            <div
                className="customer-id-page"
                onKeyPress={(event) => {
                    if (event.key === 'Enter' && error) {
                        this.closeErrorModal()
                    }
                }
                }
            >
                <DivContainerError onClick={() => this.setState({ error: null })} >
                    <CustomerSupportErrorModal
                        openError={error}
                        errorMessage={this.state.errorMessage}
                        closeModal={this.closeErrorModal}

                    />
                </DivContainerError>
                {
                    // If the api finish catching data we display the data else we display a loader
                    this.state.done
                        ?
                        <DivPage>
                            <DivContainer>
                                <DivUnderContainer>
                                    <NewSearch onClick={() => {
                                        this.props.deleteState()
                                        this.props.cleanState()
                                    }}
                                    >
                                        <Icon name="long arrow alternate left" />
                                        {i18n.t(('customerSupport.infoPage.header.newSearch'))}
                                    </NewSearch>
                                    <DivContainerCustomer className="container-customer-id">
                                        <Title>{i18n.t(('customerSupport.infoPage.header.title'))}</Title>

                                    </DivContainerCustomer>
                                    {customerInfo.customerIdList && customerInfo.customerIdList.length > 1
                                        ?
                                        <SelectStore onChange={event => this.changeShopp(event)}>{
                                            customerInfo.customerIdList.map(list =>
                                                ((list.id === customerId) ?
                                                    <option selected key={list.ShoppingHub.name} data-key={list.id}>{list.ShoppingHub.name}</option>
                                                    :
                                                    <option key={list.ShoppingHub.name} data-key={list.id}>{list.ShoppingHub.name}</option>
                                                )
                                            )
                                        }
                                        </SelectStore>
                                        : null}
                                </DivUnderContainer>
                                <DivUnderContainer>
                                    <DivDataLeft>
                                        <DivDataBasic style={{ width: '40%', position: 'relative' }}>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.customerId'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.phone'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.email'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.registrationDate'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.activationDate'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.shoppingHub'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.lockedPassword'))}</SpanTitle>
                                            <SpanTitle>{i18n.t(('customerSupport.infoPage.header.ibanUpdateURL'))}</SpanTitle>
                                        </DivDataBasic>
                                        <DivDataBasic style={{ width: '60%' }}>
                                            <SpanData>
                                                <Popup
                                                    trigger={
                                                        <DivCustomerId>
                                                            <CopyToClipboard text={customerId}>
                                                                <DivCustomerId>
                                                                    <CustomerIdP>{customerId.toLowerCase()}</CustomerIdP>
                                                                    <div className="ui green circular empty label label-custmomer-id" />
                                                                    <Icon style={{ marginBottom: '3px' }} name="copy outline" />
                                                                </DivCustomerId>
                                                            </CopyToClipboard>

                                                        </DivCustomerId>
                                                    }
                                                    content={i18n.t(('customerSupport.infoPage.header.popUpCopyText'))}
                                                    on="click"
                                                    open={this.state.isOpen}
                                                    onClose={this.handleClosePopUp}
                                                    onOpen={this.handleOpenPopUp}
                                                    position="top center"
                                                    size="tiny"
                                                />
                                            </SpanData>
                                            <SpanDataPhone>({this.props.customerInfo.customerInfo.infoCustomer.countryCallingCode}){this.state.phoneNumber}</SpanDataPhone>

                                            <SpanData>
                                                <Popup
                                                    trigger={
                                                        <DivCustomerId>
                                                            <CopyToClipboard text={email}>
                                                                <DivCustomerId>
                                                                    <CustomerIdP>{email}</CustomerIdP>
                                                                    <Icon style={{ marginBottom: '3px' }} name="copy outline" />
                                                                </DivCustomerId>
                                                            </CopyToClipboard>

                                                        </DivCustomerId>
                                                    }
                                                    content={i18n.t(('customerSupport.infoPage.header.popUpCopyText'))}
                                                    on="click"
                                                    open={this.state.isOpenEmail}
                                                    onClose={this.handleCloseEmailPopUp}
                                                    onOpen={this.handleOpenEmailPopUp}
                                                    position="top center"
                                                    size="tiny"
                                                />
                                            </SpanData>
                                            <SpanData>{this.state.createdAt ? dateFormat(this.state.createdAt, 'dd/mm/yyyy HH:MM') : 'N/A'}</SpanData>
                                            <SpanData>{this.state.registrationDate ? dateFormat(this.state.registrationDate, 'dd/mm/yyyy HH:MM') : 'N/A'}</SpanData>
                                            <SpanData>{this.props.customerInfo.shoppingHubName}</SpanData>
                                            <SpanData>
                                                {this.state.blockedPassword ?
                                                    <div><span>{i18n.t('common.yes')}</span><span className="clickable" onClick={this.unlockPassword}>{i18n.t('customerSupport.infoPage.header.lockedPasswordAction')}</span></div>
                                                    : <div><span>{i18n.t('common.no')}</span></div>
                                                }
                                            </SpanData>
                                            <SpanData>
                                                <Popup
                                                    trigger={
                                                        <DivCustomerId>
                                                            <CopyToClipboard text={this.state.ibanUpdateURL}>
                                                                <DivCustomerId>
                                                                    <CustomerIdP>{this.state.ibanUpdateURL}</CustomerIdP>
                                                                    <Icon style={{ marginBottom: '3px' }} name="copy outline" />
                                                                </DivCustomerId>
                                                            </CopyToClipboard>

                                                        </DivCustomerId>
                                                    }
                                                    content={i18n.t(('customerSupport.infoPage.header.popUpCopyText'))}
                                                    on="click"
                                                    open={this.state.isOpenIban}
                                                    onClose={this.handleCloseIbanPopUp}
                                                    onOpen={this.handleOpenIbanPopUp}
                                                    position="top center"
                                                    size="tiny"
                                                />
                                            </SpanData>
                                        </DivDataBasic>
                                    </DivDataLeft>
                                    <DivDataRight>
                                        <DivSpan>
                                            <p>{i18n.t(('customerSupport.infoPage.header.numberOfScansPart1'))}
                                                <br />
                                                {i18n.t(('customerSupport.infoPage.header.numberOfScansPart2'))}
                                            </p>
                                            <SpanBlack>{parseInt(this.state.scansAccepted, 10)} / {parseInt(this.state.scansNotAccepted, 10) + parseInt(this.state.scansAccepted, 10)}</SpanBlack>
                                        </DivSpan>
                                        <DivSpan>
                                            <p>
                                                {i18n.t(('customerSupport.infoPage.header.cashbackTotalPart1'))}
                                                <br />
                                                {i18n.t(('customerSupport.infoPage.header.cashbackTotalPart2'))}
                                            </p>
                                            <SpanBlueDark>{this.state.rewardsValue} €</SpanBlueDark>
                                        </DivSpan>
                                    </DivDataRight>
                                </DivUnderContainer>
                            </DivContainer>
                            <DivContainerTable className="container-table-row-1">
                                <CustomerSupportDetailsTable
                                    customerInfo={customerInfo}
                                    rowData={this.state.bankConnectionSyncro}
                                    columnDefs={[
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.syncroConnectionTable.colmunTitleBankName')),
                                            field: 'bankName',
                                            width: 180
                                        },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.syncroConnectionTable.colmunTitleSyncro')),
                                            field: 'syncronisation',
                                            width: 120
                                        },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.syncroConnectionTable.colmunTitleState')),
                                            field: 'etat',
                                            cellClass: 'statutColumn',
                                            width: 120,
                                            marginLeft: 0,
                                            cellRenderer: (data) => {
                                                let color = null
                                                if (data.value === 'OK') {
                                                    color = 'green'
                                                } else if (data.value === 'IN PROGRESS') {
                                                    color = 'orange'
                                                } else {
                                                    color = 'red'
                                                }
                                                return (
                                                    `<div class="etatColumn"><div class="ui ${color} circular empty label state"></div><span >${data.value}</span></div>`
                                                )
                                            }
                                        },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.syncroConnectionTable.colmunTitleDesync')),
                                            field: 'desyncronisation',
                                            width: 170
                                        }]}
                                    size="100%"
                                    title={i18n.t(('customerSupport.infoPage.table.syncroConnectionTable.titleTable'))}
                                    divContainerSize="half"
                                    overlay={`<p style="color:#00000073">${i18n.t(('customerSupport.infoPage.table.error.syncroConnexion'))}</p>`}
                                />
                                <CustomerSupportDetailsTable
                                    customerInfo={customerInfo}
                                    rowData={this.state.bankConnectionAttempt}
                                    columnDefs={[
                                        { headerName: i18n.t(('customerSupport.infoPage.table.syncroAttempt.colmunTitleBankName')), field: 'bank', width: 180 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.syncroAttempt.colmunTitleCreation')), field: 'creation', width: 90 },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.syncroAttempt.colmunTitleResponseCode')),
                                            field: 'responseCode',
                                            width: 120,
                                            cellRenderer: (data) => {
                                                let color = null
                                                if (data.value < 400) {
                                                    color = 'green'
                                                } else if (data.value < 500) {
                                                    color = 'orange'
                                                } else {
                                                    color = 'red'
                                                }
                                                return (
                                                    `<div class="etatColumn"><div class="ui ${color} circular empty label state"></div><span >${data.value}</span></div>`
                                                )
                                            }
                                        },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.syncroAttempt.colmunTitleDate')), field: 'date', width: 150 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.syncroAttempt.colmunTitleReason')), field: 'reason', width: 180 },
                                    ]}
                                    size="100%"
                                    title={i18n.t(('customerSupport.infoPage.table.syncroAttempt.titleTable'))}
                                    divContainerSize="half"
                                    overlay={`<p style="color:#00000073">${i18n.t(('customerSupport.infoPage.table.error.syncro'))}</p>`}
                                />
                            </DivContainerTable>
                            <DivContainerTable className="container-table-row-2">
                                <CustomerSupportDetailsTable
                                    customerInfo={customerInfo}
                                    rowData={this.state.bankReward}
                                    columnDefs={[
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleOfferName')), field: 'offerName', width: 180 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleOfferStart')), field: 'offerStart', width: 100 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleOfferEnd')), field: 'offerEnd', width: 100 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleDate')), field: 'date', width: 120 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleLabel')), field: 'label', width: 220 },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleIban')),
                                            field: 'iban',
                                            width: 180,
                                            cellRenderer: (value) => {
                                                for (let i = 0; i <= this.state.payoutId.length; i++) {
                                                    if (this.state.showIbanData.length > 0 && this.state.payoutId[i] === value.data.payOutId) {
                                                        return `<p class="p-iban">${this.state.showIbanData}</p>`
                                                    }
                                                }
                                                return `<p class="p-iban" key=${value.data.payOutId} ><img src=${eyeIcon} alt="eye iban" class="icon-eye" /></p>`
                                            }
                                        },
                                        {
                                            headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleState')),
                                            field: 'status',
                                            width: 100,
                                            cellRenderer: (data) => {
                                                let color = null
                                                if (data.value === 'RFND' || data.value === 'TRAN') {
                                                    color = 'green'
                                                } else if (data.value === 'TERM') {
                                                    color = 'orange'
                                                } else {
                                                    color = 'red'
                                                }
                                                return `<div class="statutColumn"><div class="ui ${color} circular empty label state"></div><span>${data.value}</span></div>`
                                            }
                                        },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.rewards.colmunTitleAmount')), field: 'amount', width: 120 },
                                    ]}
                                    size="100%"
                                    title={i18n.t(('customerSupport.infoPage.table.rewards.titleTable'))}
                                    divContainerSize="full"
                                    overlay={`<p style="color:#00000073; font-size: 13px;">${i18n.t(('customerSupport.infoPage.table.error.rewards'))}</p>`}
                                    onCellClicked={this.onCellClicked}
                                    onGridReady={params => this.gridApi = params.api}
                                />
                            </DivContainerTable>
                            <DivContainerTable className="container-table-row-3">
                                <CustomerSupportDetailsTable
                                    customerInfo={customerInfo}
                                    rowData={this.state.transactions}
                                    columnDefs={[
                                        { headerName: i18n.t(('customerSupport.infoPage.table.transactions.colmunTitleAmount')), field: 'amount', width: 180 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.transactions.colmunTitleDate')), field: 'date', width: 180 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.transactions.colmunTitleStoreName')), field: 'storeName', width: 180 },
                                        { headerName: i18n.t(('customerSupport.infoPage.table.transactions.colmunTitleBankName')), field: 'bankname', width: 180 }
                                    ]}
                                    size="100%"
                                    title={i18n.t(('customerSupport.infoPage.table.transactions.titleTable'))}
                                    divContainerSize="full"
                                    overlay={`<p style="color:#00000073; font-size: 13px;">${i18n.t(('customerSupport.infoPage.table.error.transactions'))}</p>`}
                                />
                            </DivContainerTable>
                        </DivPage>
                        :
                        // If the api is still catching the data we display a loader
                        <DivLoader>
                            <Loader
                                type="Oval"
                                color="#2185d0"
                                height="50"
                                width="50"
                            />
                        </DivLoader>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerInfo: state.customerInfo
})

export default connect(mapStateToProps, { fetchInfoFromId, fetchIbanByMgPayOutId, deleteState, fetchShoppingHubName })(CustomerSupportDetailsHome)
