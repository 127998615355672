import React from 'react'
import { Header } from 'semantic-ui-react'
import EntityManagement from '../EntityManagement'
import './StoreRetailerManagementPage.css'

const EntityManagementPage = () => (
    <section className="ui container support-page">
        <Header as="h2" attached="top" className="transactions-assignment" >
            Store & retailer management
        </Header>
        <section >
            <EntityManagement />
        </section>
    </section>
)

export default EntityManagementPage
