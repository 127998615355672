import * as d3 from 'd3'

export default function simulation(nodes, links, nodesDict, width, height) {
    const simulation = d3
    .forceSimulation(nodes)
    .force(
        'link',
        d3
        .forceLink(links)
        .id(d => d.id)
        .strength((d) => {
            if (nodesDict[d.source.id] === nodesDict[d.target.id]) {
                return (d.value + 1) ** 2 / 400
            }
            return d.value / 500
        })
    )
    .force('charge', d3.forceManyBody().strength(-200))
    .force('x', d3.forceX())
    .force('y', d3.forceY())
    .force('center', d3.forceCenter(width / 2, height / 2.5))

    return simulation
}
