import React from 'react'
import { Button, Modal, Header, Form, Message, Loader, Transition } from 'semantic-ui-react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import moment from 'moment'
import {
    fetchUnaffectedTransactionsInfo, fetchStoresList, sendTransactionAssociationInfo
} from '../../../../actions/hubSetup'


function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].name
        obj.text = arr[i].name
        delete obj.hasInitialStoreTransaction
        newArr.push(obj)
    }
    return newArr
}


class ManualAssociationTable extends React.PureComponent {
    state = { filtered: [], activeItem: 0, transactions: [], storeName: '', storeId: '', storesList: [], selectedTransaction: '', transactionSubmitted: false, errors: {}, hasInitialStoreTransaction: false, loading: true, visible: false }
    transactionSelection = (transactionInfo) => {
        this.setState({ modalOpen: true, selectedTransaction: transactionInfo, visible: true })
    }


    postTransactionAssociation = async () => {
        try {
            if (!this.state.selectedTransaction.TransactionId || !this.state.storeId) {
                return this.setState({ errors: { missingParameter: 'Parameter missing' }, loading: false })
            }
            await this.props.sendTransactionAssociationInfo(this.state.storeId, this.state.selectedTransaction.TransactionId)
            this.setState(prevState => ({
                    transactions: prevState.transactions.filter(transaction => {
                        return transaction.id !== this.state.selectedTransaction.TransactionId
                    }),
                    storeId: '',
                    storeName: '',
                    modalOpen: false,
                    visible: false,
                    transactionSubmitted: true,
                    errors: {} }
            ))
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
        }
    }
    handleClose = () => {
        this.setState({ modalOpen: false, selectedTransaction: '', errors: {}, visible: false })
    }
    getStores = async () => {
        try {
            await this.props.fetchStoresList(localStorage.getItem('hubId'))
            const storesList = manageArr(this.props.stores)
            this.setState({ storesList })
        } catch (err) {
            this.setState({ loading: false })
            console.log('Cannot get stores list', err)
        }
    }
    getTransactions = async () => {
        try {
            const response = await this.props.fetchUnaffectedTransactionsInfo(localStorage.getItem('hubId'))
            this.setState({ transactions: response.unaffectedTransactionsInfo, loading: false })
        } catch (err) {
            console.warn(err)
            const { code } = err.response.data
            if (code === 'transactionsNotFound') {
                localStorage.removeItem('hubId')
                localStorage.removeItem('hubName')
                return this.setState({ errors: { getTransactions: 'There is no unassigned transaction for this shopping hub' }, loading: false, modalOpen: true })
            }
        }
    }

    componentWillMount = async () => {
        try {
            await this.getStores()
            await this.getTransactions()
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
            const { code } = err.response.data
            if (code === 'transactionsNotFound') {
                localStorage.removeItem('hubId')
                localStorage.removeItem('hubName')
                return this.setState({ errors: { getTransactions: 'There is no unassigned transaction for this shopping hub' }, loading: false, modalOpen: true })
            }
        }
    }
    handleItemClick = (e, { index }) => {
        this.setState({ activeItem: index })
    }
    handleChange = (e, { name, value }) => {
        this.state.storesList.forEach((store) => {
            if (value === store.name) {
                this.setState({
                    storeId: store.id,
                    [name]: value
                })
            }
        })
    }

    displayOneValue = (oneValue) => {
        if ((oneValue === null || oneValue === undefined)) {
            return 'N/A'
        } else if (oneValue === true) {
            return 'true'
        } else if (oneValue === false) {
            return 'false'
        }
        return oneValue
    }
    displayTwoValues = (firstValue, secondValue) => ((firstValue === null || firstValue === undefined || secondValue === null || secondValue === undefined) ? 'N/A' : `${firstValue} / ${secondValue}`)

    filterCaseInsensitive = (filter, row) => {
        const { id } = filter
        if (row[id] !== null && row[id] !== undefined) {
            return (
                String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            )
        }
        return 0
    }
    displayTransactionsInfo() {
        const columns = [

            {
                Header: 'General informations',
                columns: [
                    {
                        Header: 'Has hint',
                        accessor: 'Hint',
                        id: 'Hint',
                        Cell: ({ original: value }) => (
                            value.Hint ? 'Yes' : 'No'
                        ),
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }
                            if (filter.value === 'hasHint') {
                                return row.Hint
                            }
                            return !row.Hint
                        },
                        Filter: ({ filter, onChange }) =>
                            (<select
                                onChange={(event) => {
                                    onChange(event.target.value)
                                }}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="hasHint">Has hint</option>
                                <option>Has no hint</option>
                            </select>)
                    },
                    {
                        Header: 'Is potentially in hub',
                        accessor: 'IsPotentiallyInHub',
                        Cell: ({ original: value }) =>
                            (
                                value.IsPotentiallyInHub ? 'Yes' : 'No'
                            ),
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }
                            if (filter.value === 'isPotentiallyInHub') {
                                return row.IsPotentiallyInHub
                            }
                            return !row.IsPotentiallyInHub
                        },
                        Filter: ({ filter, onChange }) =>
                            (<select
                                onChange={(event) => {
                                    onChange(event.target.value)
                                }}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="isPotentiallyInHub">Yes</option>
                                <option>No</option>

                            </select>)
                    },
                    {
                        Header: 'Exclude retailers out of mall',
                        accessor: 'IsRetailerInHub',
                        Cell: ({ original: value }) => (
                            value.IsRetailerInHub || value.Retailer === 'N/A' ? 'Yes' : 'No'
                        ),
                        filterMethod: (filter, row) => {
                            if (filter.value === 'all') {
                                return true
                            }
                            if (filter.value === 'isRetailerInHub') {
                                return row.IsRetailerInHub || row.Retailer === 'N/A'
                            }
                            return !row.IsRetailerInHub
                        },
                        Filter: ({ filter, onChange }) =>
                            (<select
                                onChange={(event) => {
                                    onChange(event.target.value)
                                }}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="isRetailerInHub">Yes</option>
                                <option>No</option>

                            </select>)
                    },
                    { Header: 'Transaction ID', accessor: 'TransactionId'/* filterMethod: (filter, row) => this.searchWords(filter, row) */ },
                    { Header: 'Customer ID', accessor: 'CustomerId' },
                    { Header: 'Date', accessor: 'Date' },
                    {
                        Header: 'Created at',
                        accessor: 'Created at',
                        filterMethod: (filter, row) => moment(row[filter.id]).isAfter(moment(filter.value))
                    },
                    { Header: 'Label',
                        accessor: 'Label',
                        minWidth: 340,
                        filterMethod: (filter, row) => {
                            const filterValue = filter.value.replace(/[\s\-/]/g, '[\\s\\-/]')
                            return row[filter.id].match(new RegExp(`.*${filterValue}.*`, 'gi'))
                        },

                    },
                    { Header: 'Bank name', accessor: 'Bank name'/* , filterMethod: (filter, row) => this.searchWords(filter, row) */ },
                    {
                        Header: 'Amount',
                        accessor: 'Amount',
                        sortMethod: (a, b) => parseInt(a, 10) - parseInt(b, 10)
                    },
                ]
            },
            {
                Header: 'Hints',
                foldable: true,
                columns: [
                    { Header: 'Mall', accessor: 'Mall' },
                    { Header: 'Retailer', accessor: 'Retailer' },
                    { Header: 'Geographical area', accessor: 'Geographical area' },
                    { Header: 'Store(s) in the city w/o strong label / total', accessor: 'Store(s) in the city w/o strong label / total' },
                    { Header: 'Store(s) nearby w/o strong label / total', accessor: 'Store(s) nearby w/o strong label / total' },
                    { Header: 'Transactions in the hub / total the same day', accessor: 'Transactions in the hub / total the same day' },
                    { Header: 'Transactions in the city / total the same day', accessor: 'Transactions in the city / total the same day' },
                    { Header: 'Same label paired with a hub journey / total', accessor: 'Same label paired with a hub journey / total' },
                    { Header: 'Same label paired with a city journey / total', accessor: 'Same label paired with a city journey / total' },
                    { Header: 'Same label within the 2 days preceding subscription', accessor: 'Same label within the 2 days preceding subscription' },
                    { Header: 'Same label paired with other customers\' hub journeys / total', accessor: 'Same label paired with other customers\' hub journeys / total' },
                    { Header: '# of claims for this label', accessor: '# of claims for this label' }
                ]
            },

            { Header: 'Transaction association',
                accessor: 'Transaction association',
                sortable: false,
                filterable: false,
                Cell: ({ row }) => (

                    <Button
                        color="blue"
                        size="tiny"
                        style={{ width: '100%' }}
                        onClick={() => this.transactionSelection(row)}
                    >Associate</Button>
                ) },
        ]
        let data = []
        data = this.state.transactions.map((transaction) => {
            const hasHint = !!transaction.hasHint
            let retailerName
            if (transaction.retailerName !== null) {
                retailerName = transaction.retailerName
            } else if (hasHint && transaction.retailerName) {
                retailerName = this.displayOneValue(transaction.retailerName)
            } else {
                retailerName = 'N/A'
            }

            return {
                Hint: hasHint,
                IsPotentiallyInHub: hasHint,
                IsRetailerInHub: !!transaction.isRetailerInHub,
                TransactionId: this.displayOneValue(transaction.id),
                CustomerId: this.displayOneValue(transaction.customerId),
                Date: this.displayOneValue(transaction.date),
                'Created at': this.displayOneValue(transaction.createdAt),
                Label: this.displayOneValue(transaction.label),
                'Bank name': this.displayOneValue(transaction.bankName),
                Amount: `${this.displayOneValue(transaction.amount)} €`,
                Mall: hasHint && transaction.shoppingHubName ? this.displayOneValue(transaction.shoppingHubName) : 'N/A',
                Retailer: retailerName,
                'Geographical area': hasHint && transaction.cityName ? this.displayOneValue(transaction.cityName) : 'N/A',
                'Store(s) in the city w/o strong label / total': hasHint ? this.displayOneValue(transaction.nbInCityWithoutStrongPattern) : 'N/A',
                'Store(s) nearby w/o strong label / total': hasHint ? this.displayOneValue(transaction.nbInDepartmentWithoutStrongPattern) : 'N/A',
                'Transactions in the hub / total the same day': hasHint ? this.displayTwoValues(transaction.nbSpendingInHubSameDay, transaction.totalTransactionOfTheDay) : 'N/A',
                'Transactions in the city / total the same day': hasHint ? this.displayTwoValues(transaction.nbSpendingInCitySameDay, transaction.totalTransactionOfTheDay) : 'N/A',
                'Same label paired with a hub journey / total': hasHint ? this.displayTwoValues(transaction.nbLabelInHubJourneyForTheCustomer, transaction.nbLabelForTheCustomer) : 'N/A',
                'Same label paired with a city journey / total': hasHint ? this.displayTwoValues(transaction.bLabelInCityJourneyForTheCustomer, transaction.nbLabelForTheCustomer) : 'N/A',
                'Same label within the 2 days preceding subscription': hasHint ? this.displayOneValue(transaction.inTwoPreviousDay) : 'N/A',
                'Same label paired with other customers\' hub journeys / total': hasHint ? this.displayTwoValues(transaction.nbLabelInAnotherCustomerHubJourney, transaction.nbLabelForProgram) : 'N/A',
                '# of claims for this label': hasHint ? this.displayOneValue(transaction.nbClaim) : 'N/A'
            }
        })
        return (
            <div>
                <ReactTable
                    style={{
                        height: '70vh', // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    data={data}
                    loading={this.state.loading}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    noDataText="No transaction"
                    filterable
                    columns={columns}

                />
            </div>
        )
    }
    getFilteredData = (filteredDataLength) => {
        this.setState({ filteredDataLength })
    }
    showMessage() {
        window.setTimeout(() => {
            this.setState({ transactionSubmitted: false })
        }, 6000)
        if (this.state.transactionSubmitted) {
            return (
                this.state.transactionSubmitted && (
                    <Message attached info color="green"><p>The transaction has been successfully associated</p></Message>
                )
            )
        }
    }
    render() {
        return (
            <div>
                {
                    this.state.transactionSubmitted
                        ? this.showMessage()
                        : <Message attached info><p>You can now link an unassigned transaction to a store</p></Message>
                }
                <p>{this.props.fieldValues.hubName}</p>
                {
                    (() => {
                        if (this.state.errors.getTransactions) {
                            return (
                                <Message negative><p>No transaction found for this hub</p></Message>
                            )
                        } else if (this.state.transactions.length > 0)  {
                            return (
                                <div id="table-scroll">
                                    {this.displayTransactionsInfo()}
                                    <Transition visible={this.state.visible} animation="fade up" duration={350}>
                                        <Modal
                                            size="tiny"
                                            open={this.state.modalOpen}
                                            className="transaction-validation-modal"
                                        >
                                            <Header content={'Transaction association'} />
                                            <Modal.Content className="validate-association">
                                                <p><span>Mall: </span>{this.props.fieldValues.hubName}</p>
                                                <p><span>Transaction date: </span>{this.state.selectedTransaction.Date}</p>
                                                <p><span>Label: </span>{this.state.selectedTransaction.Label}</p>
                                                <Form.Group>
                                                    <Form.Select
                                                        name="storeName"
                                                        selection
                                                        value={this.state.storeName}
                                                        onChange={this.handleChange}
                                                        label="Store: "
                                                        options={this.state.storesList}
                                                        placeholder="Choose a store"
                                                        search
                                                        required
                                                    />
                                                </Form.Group>

                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button color="red" size="mini" onClick={() => this.handleClose()}>
                                                    Back
                                                </Button>
                                                <Button color="green" size="mini" onClick={() => this.postTransactionAssociation()}>
                                                    Validate
                                                </Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </Transition>
                                </div>

                            )
                        }
                        return (
                            <div className="loader-container">
                                <Loader size="tiny" active>Loading</Loader>
                            </div>
                        )
                    })()
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    stores: state.hubSetup.stores,
    unaffectedTransactions: state.hubSetup.unaffectedTransactionsInfo,
    result: state.hubSetup.result
})


export default connect(mapStateToProps, {
    fetchStoresList, fetchUnaffectedTransactionsInfo, sendTransactionAssociationInfo
})(ManualAssociationTable)

