import {
    SHOPPING_HUB_LIST_FETCHED, STORES_LIST_FETCHED, GIFT_CARD_TRANSACTIONS_FETCHED,
    BACK_USER_LOGGED_OUT, UNAFFECTED_TRANSACTIONS_FETCHED, TRANSACTIONS_ASSOCIATION_SENT
} from '../types'


export default function hubSetup(state = {}, action = {}) {
    switch (action.type) {
        case SHOPPING_HUB_LIST_FETCHED:
            return action.shoppingHubsList
        case STORES_LIST_FETCHED:
            return { ...state, stores: action.stores }
        case GIFT_CARD_TRANSACTIONS_FETCHED:
            return { ...state, transactionsInfo: action.transactionsInfo }
        case UNAFFECTED_TRANSACTIONS_FETCHED:
            return { ...state, unaffectedTransactionsInfo: action.unaffectedTransactionsInfo }
        case TRANSACTIONS_ASSOCIATION_SENT:
            return { ...state, result: action.result }
        case BACK_USER_LOGGED_OUT:
            return {}
        default: return state
    }
}
