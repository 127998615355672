import React from 'react'
import 'react-phone-number-input/style.css'
import { Button, Form, Message, Modal, Transition, Header, Checkbox, Icon } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import downloadCsv from 'download-csv'
import PropTypes from 'prop-types'
import { fetchDecryptCustomer } from '../../../redux/customerSupport'
import { ToastContainer, toast, Flip } from 'react-toastify'
import i18n from "../../../i18n";
import BackOfficeHeader from "../../Layout/BackOfficeHeader";

const Drag = styled.div`
&:hover{
    cursor: pointer;
    background-color: #ECECEC;
}
    height: 400px;
    width: 50%;
    border: 4px dashed silver;
    display: flex;
    justify-content: center;
    align-items: center
    margin: auto;
    margin-top: 15px;
    background-color: #F8F8F8;
    flex-direction: column;
`

const DragTitle = styled.p`
    font-size: 35px;
    color: #808080;
`

const Field = styled(Form.Field)`
    margin-top: 10px !important;
    padding: 0 !important;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
        background: gainsboro;
      }
    `

const FieldLabel = styled.label`
    margin-right: 5px;
    font-size: 14px !important;
    margin: 5px 0 15px 0;
`

const LabelInput = styled.label`
    font-size: 14px !important;
`

const FieldInput = styled.input`
    width: 70% !important;
`

const FormGroup = styled(Form.Group)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    width: 100%;
    margin: 0 !important;
`

const FieldText = styled(Form.Field)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ModalContent = styled(Modal.Content)`
    padding: 3rem!important
`

const TransitionForm = styled(Transition)`
    width: 40%!important;
`

const LoaderDecrypt = styled.div`
    margin-top: 20vh;
`

const IconCloud = styled(Icon)`
    color: #808080`

class DecryptCustomer extends React.Component {
    static propTypes = {
        decryptData: PropTypes.shape({
            decryptCustomer: PropTypes.array
        }).isRequired,
    }
    state = {
        errors: '',
        accepted: false,
        formInfoErrors: '',
        idCheckBox: true,
        firstNameCheckbox: true,
        lastNameCheckbox: true,
        phoneNumberCheckbox: true,
        creationDateCheckbox: true,
        emailCheckbox: true,
        registrationDate: true,
        desyncronizationDate: true,
        referralCode: true,
        resumeRegistrationLink:false,
        loginLink:false,
        newPasswordLink:false,
        myActivitiesLink:false,
        myConnectionsLink:false,
        addConnectionLink:false,
        cashbackMethodLink:false,
        myOffersLink:false,
        sponsorshipLink:false,
        claimFormLink:false,
        queryString: '',
        needShortURL: false,
        uploadFile: null,
        csvData: null,
        extractedFields: [
            {
                nameInput: 'idCheckBox',
                label: 'Id'
            },
            {
                nameInput: 'firstNameCheckbox',
                label: 'First Name'
            },
            {
                nameInput: 'lastNameCheckbox',
                label: 'Last Name'
            },
            {
                nameInput: 'emailCheckbox',
                label: 'Email'
            },
            {
                nameInput: 'phoneNumberCheckbox',
                label: 'Phone Number'
            },
            {
                nameInput: 'creationDateCheckbox',
                label: 'Creation Date'
            },
            {
                nameInput: 'registrationDate',
                label: 'Registration Date'
            },
            {
                nameInput: 'desyncronizationDate',
                label: 'Desyncronization Date'
            },
            {
                nameInput: 'referralCode',
                label: 'Referral Code'
            },
            {
                nameInput: 'resumeRegistrationLink',
                label: 'Resume Registration Link'
            },
            {
                nameInput: 'loginLink',
                label: 'Customer Area Login Link'
            },
            {
                nameInput: 'newPasswordLink',
                label: 'Change Password Link'
            },
            {
                nameInput: 'myActivitiesLink',
                label: 'My Activities Link'
            },
            {
                nameInput: 'myConnectionsLink',
                label: 'My Connections Link'
            },
            {
                nameInput: 'addConnectionLink',
                label: 'Add Connection Link'
            },
            {
                nameInput: 'cashbackMethodLink',
                label: 'Cashback Method Link'
            },
            {
                nameInput: 'myOffersLink',
                label: 'My Offers Link'
            },
            {
                nameInput: 'sponsorshipLink',
                label: 'Sponsorship Link'
            },
            {
                nameInput: 'claimFormLink',
                label: 'Claim Form Link'
            },
            {
                nameInput: 'buyNowPayLaterLink',
                label: 'Buy Now Pay Later Link'
            },
            {
                nameInput: 'shoppingBudgetLink',
                label: 'Shopping budget Link'
            }
        ]
    }

    // If the user click on the checkbox then we change it's state
    handleChange = (e, { name }) => { this.setState(prevState => ({ [name]: !prevState[name] })) }

    //  If the user fill the input text we put it in the state
    handleQueryStringChange = (e) => { this.setState({ queryString: e.target.value }) }

    // When a user drop a file
    async handleDrop(acceptedFiles) {
        if (!acceptedFiles) {
            return
        }
        if (acceptedFiles.length > 1) { // If the user drop more than 1 file we send back an error
            this.setState({ errors: 'You can\'t drop more than one file' })
        } else if (acceptedFiles[0].type !== 'text/csv') { // We check if the file is a csv
            this.setState({ errors: 'The file must be a CSV' })
        } else if (acceptedFiles[0].size >= 1e+7) { // We check if the file's size isn't bigger than 10Mo
            this.setState({ errors: 'The file\'s size is to big' })
        }
        if (!this.state.errors.length > 0) {
            await this.setState({ uploadFile: acceptedFiles }) // We put the file in the state
            this.setState({ accepted: true }) // Display the pop up form
        }
    }


    deleteData = (parameterToDelete) => { // Delete data in the object's function
        for (let i = 0; i < this.state.extractedFields.length; i++) {
            delete this.state.extractedFields[i][parameterToDelete]
        }
    }

    notify = (type, message) => {
        toast[type](<div>
            <p>{message}</p>
        </div>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4000
        })
    }


    // When the user confirm the form
    handleSubmit = async () => {
        this.setState({ formInfoErrors: false })
        // We check if every checkbox are false than we dislpay an error

        const fieldsState = []
        for (let i = 0; i < this.state.extractedFields.length; i++) {
            fieldsState.push(this.state[this.state.extractedFields[i].nameInput])
        }

        if (!(fieldsState.includes(true))) return this.setState({ formInfoErrors: true })


        const acceptedFiles = this.state.uploadFile
        const parameter = [
            'id',
            'firstName',
            'email',
            'lastName',
            'phone',
            'creationDate',
            'registrationDate',
            'desyncronizationDate',
            'referralCode',
            'resumeRegistrationLink',
            'loginLink',
            'newPasswordLink',
            'myActivitiesLink',
            'myConnectionsLink',
            'addConnectionLink',
            'cashbackMethodLink',
            'myOffersLink',
            'sponsorshipLink',
            'claimFormLink',
            'buyNowPayLaterLink',
            'shoppingBudgetLink',
        ]

        this.setState({ csvData: 'progress', accepted: false }) // We hide the form & we display the loading component

        // We check if the user doesn't want info, if yes we delete them
        for (let i = 0; i < parameter.length; i++) {
            if (!this.state[this.state.extractedFields[i].nameInput]) {
                this.deleteData(parameter[i])
            }
        }

        const formData = new FormData()
        formData.append('image', acceptedFiles[0], acceptedFiles[0].name) // We format the data
        formData.append('queryString', this.state.queryString) // We format the data
        formData.append('needShortURL', this.state.needShortURL) // We format the data

        // We check what we send to the api if the user doesn't want some information
        for (let i = 0; i < parameter.length; i++) {
            if (this.state[this.state.extractedFields[i].nameInput]) {
                formData.append('attributes', parameter[i]) // We format the data
            }
        }

        try {
            await this.props.fetchDecryptCustomer(formData) // Send data to the api
            this.setState({ csvData: this.props.decryptData.decryptCustomer, formInfoErrors: '' })// We hide error message we put the data we get from the api inside the state

            // This function download the csv
            downloadCsv(this.state.csvData, null, `Decrypt-${this.state.uploadFile[0].name}`)

            // We put back the value by default on true
            this.notify('success', `🚀 File decrypted successfully`)
        } catch (err) {
            this.setState({ csvData: '' }) // We stop the loader if we got an error
            this.notify('error', err.response && err.response.data ? err.response.data.message : err.message)
            if (!err.response) {
                this.setState({ errors: 'Error server' }) // If the server is the problem we tell the user
            } else if (err.status === 400) {
                this.setState({ errors: 'File is not a valid format for decrypting customers' }) // If the api can't decrypt the file we send it
            }
        }
    }

    render() {
        return (
            <div>
                <BackOfficeHeader
                    title={i18n.t(('customerSupport.decrypt.title'))}
                    subtitle={i18n.t(('customerSupport.decrypt.subtitle'))}
                />
                {
                    this.state.errors // If the file is wrong (not a csv or to big etc...) we send an error message
                        ? <Message attached error>{this.state.errors}</Message>
                        : null
                }
                {
                    this.state.csvData === 'progress' // While we get result from the api we display a loader
                        ? <LoaderDecrypt>
                            <Loader
                                type="Oval"
                                color="#2185d0"
                                height="50"
                                width="50"
                            />
                        </LoaderDecrypt>
                        :
                        this.state.accepted // If the file is accepted then we show the form pop-up
                            ? <TransitionForm visible={this.state.accepted} animation="fade up" duration={350}>
                                <Modal
                                    size={'large'}
                                    open={this.state.accepted}
                                >
                                    <Header icon="unordered list" content="Select the properties to extract for each customer" />
                                    <ModalContent className="decrypt-form">
                                        {
                                            this.state.formInfoErrors // If the user didn't select at last one element we send him and error message
                                                ? <Message attached error>Error: select a least one element</Message>
                                                : null
                                        }
                                        <Form onSubmit={this.handleSubmit} >
                                            <FormGroup className={'form-group-decrypt-checkbox'}>
                                                {
                                                    this.state.extractedFields.map(state =>
                                                        (
                                                            <Field key={state.label}>
                                                                <FieldLabel >{state.label}</FieldLabel>
                                                                <Checkbox className="black-switch" toggle checked={this.state[state.nameInput]} name={state.nameInput} onChange={this.handleChange} />
                                                            </Field>
                                                        )
                                                    )
                                                }
                                            </FormGroup>
                                            <FieldText className="form-decrypt-input-text">
                                                <LabelInput>Query String</LabelInput>
                                                <FieldInput placeholder="utm_source=user-tracking&utm_medium=qr-code&utm_campaign=black-friday" onChange={this.handleQueryStringChange} />
                                            </FieldText>
                                            <FieldText className="form-decrypt-input-text">
                                                <LabelInput>Shorten URL? (useful for SMS campaigns)</LabelInput>
                                                <Checkbox className="black-switch" toggle name="needShortURL" onChange={this.handleChange} />
                                            </FieldText>
                                        </Form>
                                    </ModalContent>
                                    <Modal.Actions>
                                        <Button size="mini" onClick={() => this.setState({ accepted: false })}>
                                            Cancel
                                        </Button>
                                        <Button
                                            color="blue"
                                            size="mini"
                                            onClick={this.handleSubmit}
                                        >
                                            Download CSV
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </TransitionForm>
                            :
                            <Dropzone onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <Drag {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <DragTitle>Drag and drop a CSV file here or click</DragTitle>
                                            <IconCloud name="cloud upload" size="huge" />
                                        </Drag>
                                    </section>
                                )}
                            </Dropzone>
                }
                <ToastContainer
                    newestOnTop
                    draggable
                    pauseOnHover
                    transition={Flip}
                />
            </div>
        )
    }
}


const mapStateToProps = state => ({
    decryptData: state.customerSupport,
})

const mapDispatchToProps = dispatch => ({
    fetchDecryptCustomer: (...args) => dispatch(fetchDecryptCustomer(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(DecryptCustomer)
