import React, { Component } from 'react'
import {
    Statistic,
    Accordion,
    Icon,
    Grid,
    Popup,
    Loader
} from 'semantic-ui-react'
import Slider, { Range } from 'rc-slider'
import Tooltip from 'rc-tooltip'
import 'rc-slider/assets/index.css'
import './customerFiltering.scss'
import { CSVLink } from 'react-csv'

import api from '../../../../api'

const Handle = Slider.Handle

const DEFAULT_ABANDON = [0, 100]
const DEFAULT_FREQUENCE = [0, 100]
const DEFAULT_PANIER = [0, 100]
const DEFAULT_PARCOURS = [0, 100]

class CustomerFiltering extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rangeAbandon: DEFAULT_ABANDON,
            rangeFrequence: DEFAULT_FREQUENCE,
            rangePanier: DEFAULT_PANIER,
            rangeParcours: DEFAULT_PARCOURS,
            selectedCustomer: [],
            nbToDisplay: 0,
            nbCustomer: 1,
            isLoading: true,
            csvData: []
        }
        this.timeout = 0
        this.timeoutTime = 800
    }

    componentDidMount() {
        this.getFilteredCustomers()
    }

    getFilteredCustomers() {
        return api.offers.get('get_filtered_customer/', {
            params: {
                range_abandon: {
                    min: this.state.rangeAbandon[0],
                    max: this.state.rangeAbandon[1]
                },
                range_frequence: {
                    min: this.state.rangeFrequence[0],
                    max: this.state.rangeFrequence[1]
                },
                range_panier: {
                    min: this.state.rangePanier[0],
                    max: this.state.rangePanier[1]
                },
                range_parcours: {
                    min: this.state.rangeParcours[0],
                    max: this.state.rangeParcours[1]
                }
            }
        }).then((res) => {
            this.makeCsvData(res.data.status)
            this.setState({
                isLoading: false,
                selectedCustomer: res.data.status,
                nbToDisplay: res.data.status.length,
                nbCustomer: res.data.all_customer
            })
        })
    }

    makeCsvData(selectedCustomer) {
        const csvData = [['customerId']]
        selectedCustomer.forEach((row) => {
            csvData.push([row[0]])
        })
        this.setState({
            csvData
        })
    }

  handleAbandonChange = (e) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ rangeAbandon: e }, () => this.getFilteredCustomers())
      }, this.timeoutTime)
  };

  handleFrequenceChange = (e) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ rangeFrequence: e }, () => this.getFilteredCustomers())
      }, this.timeoutTime)
  };

  handlePanierChange = (e) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ rangePanier: e }, () => this.getFilteredCustomers())
      }, this.timeoutTime)
  };

  handleParcoursChange = (e) => {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
          this.setState({ rangeParcours: e }, () => this.getFilteredCustomers())
      }, this.timeoutTime)
  };

  requestFiltredCustomer = () => {
      this.setState({ selectedCustomer: [] })
      this.getFilteredCustomers()
  };

  render() {
      const handle = (sentence1, sentence2) => (props) => {
          const { value, dragging, index, ...restProps } = props
          return (
              <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={sentence1 + value + sentence2}
                  visible={dragging}
                  placement="top"
                  key={index}
              >
                  <Handle value={value} {...restProps} />
              </Tooltip>
          )
      }

      return (
          <Accordion.Content active={this.props.active}>
              <Grid style={{ margin: '15px' }}>
                  <Grid.Row style={{ paddingBottom: 0 }}>
                      <Grid.Column width={3} />
                      <Grid.Column width={13}>
                          <p>
                              {' '}
                Clients abandonnistes{' '}
                              <Popup
                                  trigger={<Icon name="info circle" />}
                                  content="Clients détectés au vu de leurs dernières visites comme étant le plus susceptible de ne plus venir au centre commercial"
                              />{' '}
                          </p>
                      </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ paddingBottom: 0 }} textAlign="center">
                      <Grid.Column width={3}>Moins abandonnistes</Grid.Column>

                      <Grid.Column width={10}>
                          <Range
                              name="rangeAbandon"
                              min={0}
                              max={100}
                              defaultValue={DEFAULT_ABANDON}
                              handle={handle('', '%')}
                              onChange={this.handleAbandonChange}
                          />
                      </Grid.Column>
                      <Grid.Column width={3}>Plus abandonnistes</Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ paddingBottom: 0 }}>
                      <Grid.Column width={3} />
                      <Grid.Column width={13}>
                          <p>
                              {' '}
                Fréquence de visites avec achat
                              <Popup
                                  trigger={<Icon name="info circle" />}
                                  content="Nombre de fois que les clients viennent en moyenne par mois faire un achat au centre commercial."
                              />
                          </p>
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="center">
                      <Grid.Column width={3}>Moins fréquentes</Grid.Column>
                      <Grid.Column width={10}>
                          <Range
                              name="rangeFrequence"
                              min={0}
                              max={100}
                              defaultValue={DEFAULT_FREQUENCE}
                              handle={handle('', '%')}
                              onChange={this.handleFrequenceChange}
                          />
                      </Grid.Column>
                      <Grid.Column width={3}>Plus fréquentes</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingBottom: 0 }}>
                      <Grid.Column width={3} />
                      <Grid.Column width={13}>
                          <p>
                              {' '}
                Panier moyen{' '}
                              <Popup
                                  trigger={<Icon name="info circle" />}
                                  content="Panier moyen du client"
                              />{' '}
                          </p>
                      </Grid.Column>
                  </Grid.Row>

                  <Grid.Row textAlign="center">
                      <Grid.Column width={3}>Bas</Grid.Column>
                      <Grid.Column width={10}>
                          <Range
                              name="rangePanier"
                              min={0}
                              max={100}
                              defaultValue={DEFAULT_PANIER}
                              handle={handle('', '%')}
                              onChange={this.handlePanierChange}
                          />
                      </Grid.Column>
                      <Grid.Column width={3}>Haut</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingBottom: 0 }}>
                      <Grid.Column width={3} />
                      <Grid.Column width={13}>
                          <p>
                              {' '}
                Nombre de visites avec achat par parcours client{' '}
                              <Popup
                                  trigger={<Icon name="info circle" />}
                                  content="Magasins en moyenne visités au cours d'un parcours par le client"
                              />{' '}
                          </p>
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="center">
                      <Grid.Column width={3}>Faible</Grid.Column>

                      <Grid.Column width={10}>
                          <Range
                              name="rangeParcours"
                              min={0}
                              max={100}
                              defaultValue={DEFAULT_PARCOURS}
                              handle={handle('', '%')}
                              onChange={this.handleParcoursChange}
                          />
                      </Grid.Column>
                      <Grid.Column width={3}>Important</Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                      <Grid.Column textAlign="center">
                          {this.state.isLoading ? (
                              <Loader active />
                          ) : (
                              <Statistic size="small" color="grey">
                                  <Statistic.Value>
                                      {this.state.nbToDisplay} (
                                      {Math.round(
                                          (100 * this.state.nbToDisplay) / this.state.nbCustomer,
                                          1
                                      )}
                    %)
                                  </Statistic.Value>
                                  <Statistic.Label>Clients filtrés</Statistic.Label>
                                  <CSVLink data={this.state.csvData}>Download me</CSVLink>
                              </Statistic>
                          )}
                      </Grid.Column>
                  </Grid.Row>
              </Grid>
          </Accordion.Content>
      )
  }
}

export default CustomerFiltering
