import axios from 'axios'

export default {
    offers: {
        get: (path, params) =>
            axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/offers/${path}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                },
                params: params ? params.params : null,
            }),
    },
    backUser: {
        login: async ({ email, password }) => {
            try {
                return await axios.post(`${process.env.REACT_APP_TC_HOST}/backoffice/auth/login`, { email, password })
            } catch (e) {
                throw e
            }
        },
    },
    customer: {
        fetchCustomerTransactions: async (customerId) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/customers/${customerId}/transactions`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                const { data } = response
                const { transactions, customerHubId } = data

                return { transactions, customerHubId }
            } catch (e) {
                throw e
            }
        },
        fetchCustomerByInfo: async (info) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/support/searchCustomer`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        email: info.email,
                        phoneNumber: info.phoneNumber,
                        countryCode: info.countryCode,
                    },
                })
                const { data: transactions } = response
                return transactions
            } catch (e) {
                throw e
            }
        },
        fetchPhoneCountries: async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/country/phoneCountries`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                    },
                })
                const { data: countries } = response
                return countries
            } catch (e) {
                throw e
            }
        },
    },
    cities: {
        fetchCitiesList: async () => {
            const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/cities`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                },
            })
            const { data: cities } = response
            return cities
        },
    },
    shoppingHubsOwners: {
        fetchHubsOwnersList: async () => {
            const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubOwners`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                },
            })
            const { data: shoppingHubsOwnersList } = response
            return shoppingHubsOwnersList
        },
        create: async (shoppingHubRequestObject) => {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubOwners`,
                    { ...shoppingHubRequestObject },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
    },
    shoppingHubs: {
        fetchShoppingHub: async (shoppingHubId) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHub/${shoppingHubId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        },
                    }
                )
                const { data: shoppingHub } = response
                return shoppingHub
            } catch (e) {
                throw e
            }
        },
        fetchShoppingHubsList: async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubs`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                    },
                })
                const { data: shoppingHubsList } = response
                return shoppingHubsList
            } catch (e) {
                throw e
            }
        },
        fetchStoresList: async (shoppingHubId) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubs/stores?limit=1000`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                            shoppingHubId,
                        },
                    }
                )
                const { data: stores } = response
                return stores.stores
            } catch (e) {
                throw e
            }
        },
        fetchGiftCardTransactions: async (cardNumber) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/giftCards/transactions`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        cardNumber,
                    },
                })
                const { data: giftCardTransactions } = response

                return giftCardTransactions
            } catch (e) {
                throw e
            }
        },
        sendGiftCardTransaction: async (cardNumber, storeId, label, amount, dateTime) => {
            const data = { cardNumber, storeId, label, amount, dateTime }
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/giftCards/transactions`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        },
                    }
                )
                const { data: unaffectedTransactions } = response

                return unaffectedTransactions
            } catch (e) {
                throw e
            }
        },
        fetchUnaffectedTransactions: async (shoppinghubId) => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/transactions/unaffected`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                            shoppinghubId,
                        },
                    }
                )
                const { data: unaffectedTransactions } = response

                return unaffectedTransactions
            } catch (e) {
                throw e
            }
        },

        sendTransactionAssociation: async (storeId, transactionId) => {
            const data = { storeId, transactionId }
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/transactions/association`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        },
                    }
                )
                const { data: unaffectedTransactions } = response

                return unaffectedTransactions
            } catch (e) {
                throw e
            }
        },
        sendTransactionDissociation: async (transactionId) => {
            const data = { transactionId }
            try {
                return await axios.post(`${process.env.REACT_APP_TC_HOST}/backoffice/transactions/dissociation`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                    },
                })
            } catch (e) {
                throw e
            }
        },
        create: async (shoppingHubRequestObject) => {
            try {
                const requestObject = {
                    dataRetrievalMethod: [],
                    ...shoppingHubRequestObject
                }

                if (shoppingHubRequestObject.scanEnable) {
                    requestObject.dataRetrievalMethod.push('scan')
                }

                const { data } = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubs`,
                    { ...requestObject },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        update: async (shoppingHubRequestObject) => {
            try {
                const { data } = await axios.patch(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/shoppingHubs`,
                    { ...shoppingHubRequestObject },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
    },
    userManagement: {
        fetchBackUsers: async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/admin/users`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                    },
                })
                return data
            } catch (e) {
                throw e
            }
        },
    },
    stores: {
        fetchStoreDetail: async (storeId) => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/stores/${storeId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                })
                return data
            } catch (e) {
                throw e
            }
        },
        fetchStoreTransactions: async (storeId) => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/stores/${storeId}/transactions`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        edit: async (storeId, attributesToUpdate) => {
            try {
                const { data } = await axios.patch(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/stores/${storeId}`,
                    { ...attributesToUpdate },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        delete: async (storeId) => {
            try {
                const { data } = await axios.delete(`${process.env.REACT_APP_TC_HOST}/backoffice/stores/${storeId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                })
                return data
            } catch (e) {
                throw e
            }
        },
        create: async (storeRequestObject) => {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/stores`,
                    { ...storeRequestObject },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
    },
    patterns: {
        add: async (entityId, pattern, patternType) => {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/patterns`,
                    {
                        id: entityId,
                        patternType,
                        pattern,
                    },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        update: async (patternId, pattern, patternType) => {
            try {
                const { data } = await axios.patch(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/patterns`,
                    {
                        patternId,
                        patternType,
                        pattern,
                    },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        delete: async (patternId, patternType) => {
            try {
                const { data } = await axios.delete(`${process.env.REACT_APP_TC_HOST}/backoffice/patterns`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    data: { patternId, patternType },
                })
                return data
            } catch (e) {
                throw e
            }
        },
        test: async (pattern) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/patterns/transactions`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                        pattern,
                    },
                })
                const { data: patternMatchingTransactions } = response
                return patternMatchingTransactions
            } catch (e) {
                throw e
            }
        },
    },
    retailers: {
        fetchRetailersList: async () => {
            try {
                const {
                    data: { retailersList },
                } = await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/retailers`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                })
                return retailersList
            } catch (e) {
                throw e
            }
        },
        fetchRetailerPatterns: async (retailerId, url, Authorization) => {
            return axios.get(`${url}patterns`, { headers: { Authorization } }).then((data) => data.data)
        },
        fetchRetailerStore: async (retailerId, offset, limit, url, Authorization) => {
            return axios.get(`${url}stores`, { headers: { Authorization, limit, offset } }).then((data) => data.data)
        },
        fetchRetailersTransactions: (retailerId, offset, limit, url, Authorization) => {
            return axios
                .get(`${url}transactions`, { headers: { Authorization, limit, offset } })
                .then((data) => data.data)
        },
        fetchRetailerGeneralInformation: (retailerId, url, Authorization) => {
            return axios.get(url, { headers: { Authorization } }).then((data) => data.data)
        },
        async fetchRetailerDetail(retailerId, offset = 0, limit = 1, type = null) {
            const url = `${process.env.REACT_APP_TC_HOST}/backoffice/retailers/${retailerId}/`
            const authorization = `Bearer ${localStorage.getItem('backuserJWT')}`
            try {
                if (type === 'transaction') {
                    return await this.fetchRetailersTransactions(retailerId, offset, limit, url, authorization)
                } else if (type === 'stores') {
                    return await this.fetchRetailerStore(retailerId, offset, limit, url, authorization)
                }

                const retailerInformations = await axios.all([
                    this.fetchRetailerGeneralInformation(retailerId, url, authorization),
                    this.fetchRetailerPatterns(retailerId, url, authorization),
                    this.fetchRetailerStore(retailerId, offset, limit, url, authorization),
                    this.fetchRetailersTransactions(retailerId, offset, limit, url, authorization),
                ])
                return {
                    ...retailerInformations[0],
                    patterns: retailerInformations[1],
                    stores: retailerInformations[2],
                    transactions: retailerInformations[3],
                }
            } catch (e) {
                throw e
            }
        },
        create: async (retailerRequestObject) => {
            const { data } = await axios.post(
                `${process.env.REACT_APP_TC_HOST}/backoffice/retailers`,
                { ...retailerRequestObject },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                }
            )
            try {
                return data
            } catch (e) {
                throw e
            }
        },
        edit: async (retailerId, attributesToUpdate) => {
            try {
                const { data } = await axios.patch(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/retailers/${retailerId}`,
                    { ...attributesToUpdate },
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
        delete: async (retailerId) => {
            try {
                const { data } = await axios.delete(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/retailers/${retailerId}`,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}` },
                    }
                )
                return data
            } catch (e) {
                throw e
            }
        },
    },
    stats: {
        fetchAllStats: async (ShoppingHubs) => {
            try {
                return await axios.get(`${process.env.REACT_APP_TC_HOST}/backoffice/statistics/customerInfoByHub`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`, ShoppingHubs },
                })
            } catch (e) {
                throw e
            }
        },
        fetchDailyStats: async (ShoppingHubs, startDate, endDate) => {
            try {
                return await axios.get(
                    `${process.env.REACT_APP_TC_HOST}/backoffice/statistics/customerDailyInfoByHub`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('backuserJWT')}`,
                            ShoppingHubs,
                            startDate,
                            endDate,
                        },
                    }
                )
            } catch (e) {
                throw e
            }
        },
    },
}
