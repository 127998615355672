import { CUSTOMER_INFO_FETCHED, RESET_CUSTOMER_INFO, CUSTOMER_ID_FETCHED } from '../types'


export default function customer(state = {}, action = {}) {
    switch (action.type) {
    case CUSTOMER_INFO_FETCHED:
        return { ...state, customerTransactions: action.customerInfo }
    case CUSTOMER_ID_FETCHED:
        return { ...state, customerIdAssociate: action.customerInfo }
    case RESET_CUSTOMER_INFO:
        return {}
    default: return state
    }
}
