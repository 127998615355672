import { BACK_USER_LOGGED_IN, BACK_USER_LOGGED_OUT } from '../types'
import api from '../api'

export const backUserLoggedIn = backUser => ({
    type: BACK_USER_LOGGED_IN,
    backUser
})

export const backUserLoggedOut = () => ({
    type: BACK_USER_LOGGED_OUT
})

/**
 * This function returns a other function that will call our API
 * first make api request, get data and dispatch action that will change via reducers to the redux store
 * @param credentials
 */
export const login = credentials => async (dispatch) => {
    try {
        const response = await api.backUser.login(credentials)
        if (response.data && response.data.accessToken) {
            localStorage.backuserJWT = response.data.accessToken
            localStorage.email = response.data.backUserEmail
            localStorage.backUserId = response.data.backUserId
            dispatch(backUserLoggedIn(response.data))
            return response
        }
        return response
    } catch (error) {
        throw error
    }
}


/**
 * This function returns a other function that will call our API
 * first make api request, get data and dispatch action that will change via reducers to the redux store
 */
export const logout = () => (dispatch) => {
    localStorage.clear()
    return dispatch(backUserLoggedOut())
}
