import React, { Component } from 'react'
import 'react-dates/initialize'
import { Form, Button, Table, Message, Modal, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'react-select/dist/react-select.css'
import 'react-dates/lib/css/_datepicker.css'

import { fetchStoresList, fetchGiftCardTransactionsInfo, sendGiftCardTransactionInfo } from '../../../../../actions/hubSetup'

function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].name
        obj.text = arr[i].name
        obj.disabled = arr[i].hasInitialStoreTransaction
        delete obj.hasInitialStoreTransaction
        newArr.push(obj)
    }
    return newArr
}


class StoreFields extends Component {
    state = { storeName: '', submittedStore: '', storesList: [], storeId: null, transactions: false, modalOpen: false, selectedTransaction: '', transactionSubmitted: false, errors: {}, hasInitialStoreTransaction: false }
    handleChange = (e, { name, value }) => this.setState({ [name]: value })
    handleSubmit = async () => {
        this.state.storesList.forEach((store) => {
            if (this.state.storeName === store.name) {
                localStorage.setItem('storeId', store.id)
                this.props.saveValues({ storeName: store.name })
                this.setState({
                    submittedStore: store,
                    storeId: store.id,
                    hasInitialStoreTransaction: store.hasInitialStoreTransaction
                })
            }
        })
        try {
            const response = await this.props.fetchGiftCardTransactionsInfo(localStorage.getItem('cardNumber'))
            if (response) {
                this.setState({ transactions: true })
            }
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
            const { status } = err.response

            if (status === 404) {
                return this.setState({
                    errors: { getTransactions: 'Error: no transaction goes back up, please check your gift card number and restart the process.' },
                    loading: false
                })
            }
            if (status === 500) {
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
            return 1
        }
    }
    handleClose = () => {
        this.setState({ modalOpen: false, selectedTransaction: '', errors: {} })
    }

    getStores = async () => {
        await this.props.fetchStoresList(localStorage.getItem('hubId'))
        const storesList = manageArr(this.props.stores)
        this.setState({ storesList })
    }

    componentWillMount = async () => {
        try {
            await this.getStores()
        } catch (err) {
            console.error(`Unable to fetch stores list items ${err}`)
        }
    }

    transactionSelection = (transactionInfo) => {
        this.setState({ selectedTransaction: transactionInfo, modalOpen: true })
    }

    changeStore() {
        this.setState({ submittedStore: '', storeId: '', storeName: '', modalOpen: false, selectedTransaction: '', transactions: '', transactionSubmitted: false })
        localStorage.removeItem('storeId')
    }

    postGiftCardTransaction = async () => {
        try {
            await this.props.sendGiftCardTransactionInfo(
                this.state.selectedTransaction.cardNumber[0],
                localStorage.getItem('storeId'),
                this.state.selectedTransaction.movementLabel[0],
                this.state.selectedTransaction.curAmount[0] / 100,
                this.state.selectedTransaction.serverDate[0]
            )

            this.setState({ storesList: [], submittedStore: '', storeId: '', storeName: '', modalOpen: false, selectedTransaction: '', transactions: '', transactionSubmitted: true })

            window.setTimeout(() => {
                this.setState({ transactionSubmitted: false })
            }, 4000)
            localStorage.removeItem('storeId')
            return await this.getStores()
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { serverError: 'An error has occurred' }, loading: false })
            }
            const { code } = err.response.data
            if (code === 'transactionExists') {
                return this.setState({ errors: { submitTransaction: 'The transaction has already been associated, please choose another one' }, loading: false })
            }
            return 1
        }
    }


    displayTransactionsInfo(transactionsInfo) {
        const transactionsRows = transactionsInfo.map(transaction => (

            <Table.Row key={transaction.serverDate}>
                <Table.Cell>{transaction.movementLabel}</Table.Cell>
                <Table.Cell>{`${transaction.curAmount / 100}€`}</Table.Cell>
                <Table.Cell>{moment(transaction.serverDate[0]).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                <Table.Cell>
                    <Button
                        color={'green'}
                        size="tiny"
                        style={{ width: '100%' }}
                        onClick={() => this.transactionSelection(transaction)}
                    >
                        Associate
                    </Button>
                </Table.Cell>
            </Table.Row>
        ))
        return (
            <div>
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Label</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Date/time transaction</Table.HeaderCell>
                            <Table.HeaderCell>Association</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{transactionsRows}</Table.Body>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <article>
                <Form onSubmit={this.handleSubmit} size={'mini'}>
                    {
                        !Object.keys(this.state.transactions).length ? (
                            <Message attached info>
                                <p>Select the store where you have just made a purchase</p>
                            </Message>

                        ) : <Message attached info>
                            <p>Select the transaction that corresponds to the last visited store.</p>
                        </Message>
                    }

                    <Form.Group>
                        <Form.Select
                            name="storeName"
                            size={'mini'}
                            value={this.state.storeName}
                            selection
                            label="Which store ?"
                            options={this.state.storesList}
                            onChange={this.handleChange}
                            placeholder="Choose a store"
                            search
                            required
                            width={4}
                            disabled={!!this.state.submittedStore}
                        />
                        {
                            this.state.submittedStore
                                ? <Button type="submit" color="blue" size={'mini'} onClick={() => this.changeStore()}>Change store</Button>
                                : <Button type="submit" color="green" size={'mini'}  disabled={this.state.storeName.length === 0}>Next</Button>
                        }
                    </Form.Group>
                </Form>

                {this.state.transactions && !this.state.transactionSubmitted && localStorage.getItem('storeId') && this.displayTransactionsInfo(this.props.transactionsInfo)}
                {
                    !this.state.errors.submitTransaction ? (
                        <Modal
                            size="tiny"
                            open={this.state.modalOpen}
                            className="transaction-validation-modal"
                        >
                            <Header content={`Associate the store ${this.state.storeName} to the transaction below?`} />
                            <Modal.Content>
                                <p><span>Mall: </span>{localStorage.getItem('hubName')}</p>
                                <p><span>Card number: </span>{this.state.selectedTransaction.cardNumber && this.state.selectedTransaction.cardNumber[0]}</p>
                                <p><span>Amount: </span>{this.state.selectedTransaction.euroAmount && `${this.state.selectedTransaction.euroAmount / 100}€`}</p>
                                <p><span>Label: </span>{this.state.selectedTransaction.movementLabel && this.state.selectedTransaction.movementLabel}</p>
                                <p><span>Date/time: </span>{this.state.selectedTransaction.serverDate && moment(this.state.selectedTransaction.serverDate[0]).format('DD/MM/YYYY HH:mm')}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="red" onClick={() => this.handleClose()}>
                                    Back
                                </Button>
                                <Button color="green" onClick={() => this.postGiftCardTransaction()}>
                                    Validate
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    ) : (
                        <Modal
                            size="tiny"
                            open={this.state.modalOpen}
                            className="transaction-validation-modal"
                        >
                            <Header content="Error" />
                            <Modal.Content>
                                <p>{this.state.errors.submitTransaction}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="red" onClick={() => this.handleClose()}>
                                    Back
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    )
                }
                {

                    !!this.state.errors.getTransactions &&
                    <Message warning>
                        <Message.Header>Error</Message.Header>
                        <p>{this.state.errors.getTransactions}</p>
                        <Link to="/home">
                            <Button
                                type="submit"
                                color="red"
                                onClick={() => {
                                    localStorage.removeItem('storeId')
                                    localStorage.removeItem('hubName')
                                    localStorage.removeItem('hubId')
                                    localStorage.removeItem('cardNumber')
                                }}
                            >restart process</Button>
                        </Link>
                    </Message>

                }
                {
                    this.state.transactionSubmitted && <Message
                        success
                        header="Your transaction has been successfully associated to the store"
                    />
                }
            </article>
        )
    }
}


const mapStateToProps = state => ({
    stores: state.hubSetup.stores,
    transactionsInfo: state.hubSetup.transactionsInfo
})


export default connect(mapStateToProps, {
    fetchStoresList,
    fetchGiftCardTransactionsInfo,
    sendGiftCardTransactionInfo
})(StoreFields)
