import React, { Component } from 'react'
import { Modal, Button, Icon, Grid, List } from 'semantic-ui-react'
import './dangerModal.scss'
import _ from 'lodash'

export default class DangerModal extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            showModalDanger: null,
            risk: null
        }
    }

    componentDidMount() {
        this.props.ee.on('dangerSelected', (danger) => {
            this.setState({
                showModalDanger: danger,
                risk: _.find(this.props.risks, o => _.kebabCase(o.name) === _.kebabCase(danger))
            })
        })
    }

  handleClose = () => this.setState({ showModalDanger: null, risk: null });

  render() {
      return (
          <Modal
              className="dangerModal"
              open={!!this.state.showModalDanger}
          >
              {this.state.risk && (
          <>
            <Modal.Header style={{ color: '#CC2029' }}>
                <Grid columns={2}>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column textAlign="left">
                            <Icon
                                name="exclamation triangle"
                                className="icon"
                                color="red"
                                size="big"
                            />
                            {this.state.risk && _.upperCase(this.state.risk.name)}
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Icon
                                name="times"
                                color="red"
                                size="big"
                                onClick={this.handleClose}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <List relaxed size={'huge'}>
                        <List.Item style={{ color: this.state.risk.amount.color }}>
                            <List.Icon
                                name="shopping basket"
                                size="large"
                                verticalAlign="middle"
                            />
                            <List.Content>
                                <List.Header
                                    style={{ color: this.state.risk.amount.color }}
                                >
                                    {this.state.risk.amount.variation > 0
                                        ? 'Panier moyen en hausse'
                                        : 'Panier moyen en baisse'}
                                </List.Header>
                                <List.Description
                                    style={{ color: this.state.risk.amount.color }}
                                >
                                    {this.state.risk.amount.variation > 0
                                        ? `En hausse de ${
                                            this.state.risk.amount.variation
                                        } % par rapport au mois précédent`
                                        : `En baisse de ${-this.state.risk.amount
                                        .variation} % par
                        rapport au mois précédent`}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        {
                            // potentiel de cross-sell déplacé...
                            /* <List.Item
                    style={{ color: this.state.risk.interaction.couleur }}
                  >
                    <List.Icon
                      name="exchange"
                      size="large"
                      verticalAlign="middle"
                    />
                    <List.Content>
                      <List.Header
                        style={{ color: this.state.risk.interaction.couleur }}
                      >
                        {this.state.risk.interaction.couleur === "green"
                          ? "Bon potentiel de cross-cell"
                          : this.state.risk.interaction.couleur === "#CC2029"
                          ? "Faible potentiel ce cross-cell"
                          : "Potentiel de cross-cell correct"}
                      </List.Header>
                      <List.Description
                        style={{ color: this.state.risk.interaction.couleur }}
                      >
                        {this.state.risk.interaction.valeur}ème moins bon
                        magasin en terme de potentiel de cross-cell dans le
                        centre
                      </List.Description>
                    </List.Content>
                  </List.Item> */
                        }
                        <List.Item style={{ color: this.state.risk.visits.color }}>
                            <List.Icon
                                name="users"
                                size="large"
                                verticalAlign="middle"
                            />
                            <List.Content>
                                <List.Header
                                    style={{ color: this.state.risk.visits.color }}
                                >
                        Nombre de visites en baisse
                                </List.Header>
                                <List.Description
                                    style={{ color: this.state.risk.visits.color }}
                                >
                                    {' '}
                                    {this.state.risk.visits.variation < 0
                                        ? `En baisse de
                        ${-this.state.risk.visits.variation}
                        % par rapport au mois précédent`
                                        : `En hausse de
                        ${this.state.risk.visits.variation}
                        % par rapport au mois précédent`}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item
                            style={{ color: this.state.risk.return_rate.color }}
                        >
                            <List.Icon
                                name="sync alternate"
                                size="large"
                                verticalAlign="middle"
                            />
                            <List.Content>
                                <List.Header
                                    style={{ color: this.state.risk.return_rate.color }}
                                >
                                    {this.state.risk.return_rate.color === 'green'
                                        ? 'Bon taux de retour'
                                        : this.state.risk.return_rate.color === '#CC2029'
                                            ? 'Faible taux de retour'
                                            : 'Taux de retour correct'}
                                </List.Header>
                                <List.Description
                                    style={{ color: this.state.risk.return_rate.color }}
                                >
                                    {this.state.risk.return_rate.rank}ème moins bon magasin
                        en terme de taux de retour dans le centre
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className="actions">
                <Button
                    icon
                    labelPosition="right"
                    className="button"
                    onClick={this.handleClose}
                >
                Soumettre une offre
                    <Icon name="arrow alternate circle right" size={'large'} />
                </Button>
            </Modal.Actions>
          </>
              )}
          </Modal>
      )
  }
}
