import React from 'react'
import Rollbar from 'rollbar'
import ReactDOM from 'react-dom'
import jwtDecode from 'jwt-decode'

import { Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux' // high order component that connect redux and react
import 'semantic-ui-css/semantic.min.css'
import App from './App'
import { backUserLoggedIn, logout } from './actions/auth'
import './index.css'
import configureStore from './store/configureStore'
import history from './store/history'



// Rollbar settings
new Rollbar({
    accessToken: '237988981a494dac8061eb89350dc186',
    handleUncaughtExceptions: true,
    handleUnhandledRejections: true,
    enabled: process.env.REACT_APP_NODE_ENV !== 'development',
    scrubTelemetryInputs: true,
    payload: {
        environment: process.env.REACT_APP_NODE_ENV, // Set dynamic environment
        autoInstrument: true,
        client: {
            javascript: {
                source_map_enabled: true,
                guess_uncaught_frames: true,
                code_version: 'c55f5bed501a98ceb66254abbbff46e723708135', // git SHA of the current revision
            }
        },
        server: {
            host: process.env.REACT_APP_TC_HOST
        }
    }
})


const store = configureStore()

if (localStorage.backuserJWT !== undefined) {
    const backUser = { accessToken: localStorage.backuserJWT }
    const timeToExpire = jwtDecode(backUser.accessToken).exp

    if (timeToExpire < Date.now() / 1000) {
        store.dispatch(logout())
    } else {
        store.dispatch(backUserLoggedIn(backUser))
    }
}

ReactDOM.render(
    <Router history={history}>
        <Provider store={store}>
            <Route component={App} />
        </Provider>
    </Router>,
    document.getElementById('root')
)
