// action types
import { RETAILER_DETAIL_FETCHED, RESET_CUSTOMER_INFO } from '../types'
// api calls
import api from '../api'

// Action Creators
export const retailerDetailFetched = retailerDetail => ({
    type: RETAILER_DETAIL_FETCHED,
    retailerDetail
})

export const clearCustomerInfo = () => ({
    type: RESET_CUSTOMER_INFO
})
/**
 * This function returns a other function that will call our API,
 * Redux Thunk middleware allows you to write action creators that return a function instead of an action.
 * i first make api request, get data and dispatch action that will change via reducers to the redux store
 * @param retailerId
 * @param offset
 * @param limit
 * @param type
 */
export const fetchRetailerDetail = (retailerId, offset, limit, type) => dispatch => api.retailers.fetchRetailerDetail(retailerId, offset, limit, type)
    .then(retailerDetail => dispatch(retailerDetailFetched(retailerDetail)))

export const refreshRetailer = retailerDetail => (dispatch) => {
    dispatch(retailerDetailFetched(retailerDetail))
}

