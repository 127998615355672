import { combineReducers } from 'redux'
import backUser from './backUser'
import customer from './customer'
import shoppingHubStats from './shoppingHubStats'
import hubSetup from './hubSetup'
import store from './store'
import retailer from './retailer'
import userManagement from './userManagement'
import customerStats from './stats'
import receipts from '../redux/receipts'
import stores from '../redux/stores'
import customerSupport from '../redux/customerSupport'
import customerInfo from '../redux/customerInfo'


// reducers are functions that takes states and return new states
const appReducer = combineReducers({
    backUser,
    stores,
    customer,
    shoppingHubStats,
    hubSetup,
    store,
    retailer,
    userManagement,
    customerStats,
    receipts,
    customerSupport,
    customerInfo
})
const rootReducer = (state, action) => {
    if (action.type === 'BACK_USER_LOGGED_OUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer
