import PropTypes from 'prop-types'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import React from 'react'
import styled from 'styled-components'
import i18n from '../../../../i18n'

const ModalContentError = styled(Modal.Content)`
    height: 100% !important
`

const ModalError = styled(Modal)`
    height: 35%;
    width: 30% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;   
    box-shadow: 2px 0px 5px #00000073;
    margin: auto !important;
    position: relative;
`

const ModalDescription = styled(Modal.Description)`
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important
`

const ModalHeader = styled(Header)`
    font-weight: 900 !important;
    color: #021041 !important;
    font-size: 24px !important;
    text-align: center
    margin: 20px auto 0px auto !important
`

const ModalP = styled.p`
    color: #00000073 !important;
    font-size: 14px !important;
    text-align: center
    margin: 40px auto !important
`

const ButtonSearch = styled(Button)`
    &:hover{
        padding-right: 40px !important;
    }
    width: 300px;
    background-color: #021041 !important;
    color: white !important;
    font-size: 15px !important;
    padding: 17px !important;
    border-radius: 25px !important;
    padding-left: 0 !important;
    transition: all .2s ease 0s !important;
`

const ButtonError = styled(ButtonSearch)`
    &:hover{
        padding-right: 15px !important;
    }
    width: 300px;
    background-color: #021041 !important;
    color: white !important;
    font-size: 15px !important;
    padding: 17px !important;
    border-radius: 25px !important;
    padding-left: 0 !important;
    transition: padding-right .3s ease 0s !important;
`

const IconSubmit = styled(Icon)`
    color: white;
    opacity: 1 !important;
`

const IconClose = styled(Icon)`
    position: absolute;
    top: -7px;
    right: -11px;
    background-color: #021041 !important;
    border-radius: 50%;
    color: white;
    padding: 11px;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-weight: 900 !important;
    :before {
      font-size: 11px!important;
    }
`



const CustomerSupportErrorModal = ({ openError, errorMessage, closeModal }) => {

    return (
        <ModalError
            open={openError}
            className="error-form-container customer-support-error-modal"
        >
            <ModalContentError className="error-form-message">
                <ModalDescription>
                    <ModalHeader>{i18n.t(('common.error'))}</ModalHeader>
                    <ModalP>{errorMessage}</ModalP>
                    <ButtonError
                        animated="fade"
                        size="mini"
                        onClick={() => closeModal()}
                        className="error-button"
                    >
                        {i18n.t(('common.ok'))}
                        <Button.Content
                            hidden
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingRight: '45px',
                                top: '22px'
                            }}
                        >
                            <IconSubmit className="hide-button-arrow" name="long arrow alternate right" size="large" />
                        </Button.Content>
                    </ButtonError>
                    <IconClose name="close" onClick={() => closeModal()} />
                </ModalDescription>
            </ModalContentError>
        </ModalError>

    )
}

CustomerSupportErrorModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    openError: PropTypes.bool.isRequired
}

export default CustomerSupportErrorModal
