import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import styled from 'styled-components'
import propTypes from 'prop-types'

const DivContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column
`
const TitleTable = styled.h2`
    font-size: 17px;
    text-align: left;
    width: 100%;
`

const CustomerSupportDetailsTable = ({ size, title, divContainerSize, marginRight, rowData, overlay, columnDefs, onCellClicked, onGridReady  }) => {
    return (
        <DivContainer style={{ width: divContainerSize === 'full' ? '100%' : divContainerSize === 'half'  ? '50%' : '100%', marginRight }}>
            <TitleTable>{title}</TitleTable>
            <div className={'tab-customer-support'}>
                <div
                    className="ag-theme-balham"
                    style={{
                        height: '300px',
                        width: size,
                        background: 'none',
                        border: 'none',
                        textAlign: 'left'
                    }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        overlayNoRowsTemplate={overlay}
                        cellClass={'no-border'}
                        onCellClicked={onCellClicked}
                        onGridReady={onGridReady}
                    />
                </div>
            </div>
        </DivContainer>
    )
}

CustomerSupportDetailsTable.propTypes = {
    divContainerSize: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    rowData: propTypes.arrayOf(propTypes.object).isRequired,
    size: propTypes.string.isRequired,
    columnDefs: propTypes.arrayOf(propTypes.object).isRequired,
    overlay: propTypes.string.isRequired,
    marginRight: propTypes.string,
    onCellClicked: propTypes.func,
    onGridReady: propTypes.func
}


CustomerSupportDetailsTable.defaultProps = {
    onGridReady: null,
    onCellClicked: null,
    marginRight: null
}

export default CustomerSupportDetailsTable
