import React from 'react'
import { Header } from 'semantic-ui-react'
import ManualAssociationPageForm from '../forms/ManualAssociationForm'
import './ManualAssociation.css'

function ManualAssociationPage() {
    return (
        <section className="ui container support-page">
            <Header as='h2' attached="top" className="transactions-assignment">
                Manual association
            </Header>
            <ManualAssociationPageForm />
        </section>
    )
}

export default ManualAssociationPage
