import React, { Component } from 'react'
import { Grid, Container, Loader, Icon, Menu } from 'semantic-ui-react'

import ChooseGraph from './ChooseGraph/ChooseGraph'
import Statistics from '../Statistics/Statistics'
import ForceGraph from './DynamicGraph/DynamicGraph'
import DangerModal from '../Statistics/RisksGraph/dangerModal'
import _ from 'lodash'

import '../Statistics/statistics.scss'


import api from '../../../api'

import GenerateOffer from '../../Offers/SegmentedOffers/GenerateOffer/GenerateOffer'

const EventEmitter = require('events')

class Visualisation extends Component {
    constructor(props, context) {
        super(props, context)
        this.graphBox = React.createRef()
        this._ismounted = true
        this.state = {
            graphWidth: null,
            store: null,
            store_data: null,
            data: null,
            risks: null,
            potentials: null,
            ee: new EventEmitter()
        }
        this.handleGraphClick.bind(this)
        this.selectNode.bind(this)
        this.requestData.bind(this)
    }

    async componentDidMount() {
        const default_levier = 'sharedCustomer'
        const default_sizeOption = 'avgCa'
        const data = await this.requestData(default_levier)
        await this.getStoreData()
        await this.getPotentials()
        if (this._ismounted) {
            this.setState({
                graphWidth: this.graphBox.current.offsetWidth,
                levier: default_levier,
                data,
                sizeOption: default_sizeOption
            })
        }
    }

    componentWillUnmount() {
        this._ismounted = false
    }

  selectNode = (e) => {
      this.setState({ store: e })
  };

  async getStoreData() {
      return api.offers.get('store_data/').then((res) => {
          this.setState({ store_data: res.data, risks: res.data.slice(0, 5) })
      })
  }

  async getPotentials() {
      return api.offers.get('potentials/').then((res) => {
          this.setState({
              potentials: res.data
          })
      })
  }

  resetShop() {
      this.setState({ store: null })
  }

  requestData(levier) {
      if (levier === 'sessions') {
          return api.offers.get('get_graph/', {
              params: {
                  graphName: 'session'
              }
          }).then((res) => {
              this.setState({ data: res.data }, () => {
                  this.state.ee.emit('graphdata', res.data.nodes)
              })
              return res.data
          })
      } else if (levier === 'sharedCustomer') {
          return api.offers.get('get_graph/', {
              params: {
                  graphName: 'jaccard'
              }
          }).then((res) => {
              this.setState({ data: res.data }, () => {
                  this.state.ee.emit('graphdata', res.data.nodes)
              })
              return res.data
          })
      }
  }

  handleGraphClick = (e) => {
      this.setState({ store: e.id })
  };

  chooseLevier = (e) => {
      this.requestData(e).then((res) => {
          this.setState({ levier: e, data: res, store: null })
      })
  };

  chooseSizeOption = (e) => {
      this.setState({ sizeOption: e })
  };

  render() {
      return (
      <>
        <DangerModal ee={this.state.ee} risks={this.state.risks} />

        <Container fluid>
            <Grid columns="equal">
                <Grid.Column
                    style={{
                        background: '#fefefe',
                        padding: '10px',
                        margin: '0px',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        scrollBehavior: 'auto'
                    }}
                >
                    <Container
                        fluid
                        className="my_container"
                        style={{
                            boxShadow: '1px 0px 10px 1px rgba(0,0,0,0.1)',
                            padding: '10px'
                        }}
                    >
                        <Statistics
                            selected={this.selectNode}
                            event_emitter={this.state.ee}
                            links={this.state.data && this.state.data.links}
                            store_data={
                                this.state.store_data &&
                    _.find(this.state.store_data, {
                        name: this.state.store
                    })
                            }
                            nb_magasins={
                                this.state.store_data && this.state.store_data.length
                            }
                            levier={this.state.levier}
                            risks={this.state.risks}
                            store={this.state.store}
                            allStoresData={this.state.store_data}
                        />
                    </Container>
                    <br />
                    <Container
                        fluid
                        className="my_container"
                        style={{
                            boxShadow: '1px 0px 10px 1px rgba(0,0,0,0.1)',
                            padding: '10px'
                        }}
                    >
                        <GenerateOffer event_emitter={this.state.ee} />
                    </Container>
                </Grid.Column>
                <Grid.Column
                    computer={8}
                    tablet={16}
                    style={{
                        padding: '10px 0px'
                    }}
                >
                    <Container fluid className="my_container">
                        <ChooseGraph
                            selected={this.selectNode}
                            event_emitter={this.state.ee}
                            levier={this.chooseLevier}
                            choosedLevier={this.state.levier}
                            sizeOption={this.chooseSizeOption}
                            choosedOption={this.state.sizeOption}
                        />
                        <Grid>
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Grid.Column width={14}>
                                    <div ref={this.graphBox}>
                                        {!this.state.graphWidth ||
                        !this.state.data ||
                        !this.state.risks ||
                        !this.state.potentials ? (
                                                <Loader active />
                                            ) : (
                                                <ForceGraph
                                                    potentials={this.state.potentials.slice(0, 5)}
                                                    risks={this.state.risks}
                                                    event_emitter={this.state.ee}
                                                    id="ForceGraph"
                                                    data={this.state.data}
                                                    levier={this.state.levier}
                                                    width={this.state.graphWidth}
                                                    onClick={this.handleGraphClick}
                                                    sizeOption={this.state.sizeOption}
                                                />
                                            )}
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <Menu icon vertical>
                                        <Menu.Item name="zoom in" id="zoomin">
                                            <Icon name="zoom in" />
                                        </Menu.Item>
                                        <Menu.Item name="zoom out" id="zoomout">
                                            <Icon name="zoom out" />
                                        </Menu.Item>
                                        <Menu.Item name="resetzoom" id="resetzoom">
                                            <Icon name="expand arrows alternate" />
                                        </Menu.Item>
                                        <Menu.Item
                                            name="reset"
                                            id="reset"
                                            onClick={() => this.resetShop()}
                                        >
                                            <Icon name="sync alternate" />
                                        </Menu.Item>
                                    </Menu>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Grid.Column>
            </Grid>
        </Container>
      </>
      )
  }
}

export default Visualisation
