import { BACK_USER_LOGGED_IN, BACK_USER_LOGGED_OUT } from '../types'


export default function backUser(state = {}, action = {}) {
    switch (action.type) {
    case BACK_USER_LOGGED_IN:
        return action.backUser

    case BACK_USER_LOGGED_OUT:
        return {}

    default: return state
    }
}
