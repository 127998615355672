import React, { Component } from 'react'
import 'react-dates/initialize'
import { Header } from 'semantic-ui-react'

import GiftCardForm from '../forms/GiftCardForm'


export default class HubSetupPage extends Component {
    state = {

    }
    render() {
        return (
            <section className="ui container transactions-records">
                <Header as="h2" attached="top" className="transactions-records-form">
                    Transaction history
                </Header>
                <GiftCardForm />
            </section>
        )
    }
}
