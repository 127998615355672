import React from 'react'
import { Button, Form, Header, Modal, Transition, Dropdown, Label, Message } from 'semantic-ui-react'


class CreateUserModal extends React.Component {
    render() {
        return (
            <Transition visible={this.props.visible} animation="fade up" duration={350}>
                <Modal
                    size={'tiny'}
                    open={this.props.visible}
                >
                    <Header icon="plus" content={'Create a user'} />
                    <Modal.Content >
                        {
                            !!(this.props.formError && this.props.formError.serverError) && (
                                <Message negative attached>
                                    <Message.Header>{this.props.formError.serverError}</Message.Header>
                                </Message>
                            )
                        }
                        <Form onSubmit={this.props.onSubmit} >
                            <Form.Field>
                                <label>Email</label>
                                <Form.Input
                                    size="small"
                                    required
                                    error={!!(this.props.inputError && this.props.inputError.email)}
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    onChange={this.props.onChange}
                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.email) &&
                                    <Label pointing color='red'>{this.props.inputError.email}</Label>
                                }
                                {
                                    !!(this.props.formError && this.props.formError.emailAlreadyExists) &&
                                    <Label pointing color='red'>{this.props.formError.emailAlreadyExists}</Label>
                                }
                            </Form.Field>
                            <Form.Field>
                                <label>Password</label>
                                <Form.Input
                                    size="small"
                                    onChange={this.props.onChange}
                                    error={!!(this.props.inputError && this.props.inputError.password)}
                                    required
                                    autoComplete="new-password"
                                    type={"password"}
                                    name="password"
                                    placeholder="Password"
                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.password) &&
                                    <Label pointing color='red'>{this.props.inputError.password}</Label>
                                }
                            </Form.Field>
                            <Form.Field>
                                <label>Confirm password</label>
                                <Form.Input
                                    size="small"
                                    onChange={this.props.onChange}
                                    error={!!(this.props.inputError && this.props.inputError.passwordConfirm)}
                                    required
                                    autoComplete="new-password"
                                    type={"password"}
                                    name="passwordConfirm"
                                    placeholder="Confirm password"
                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.passwordConfirm) &&
                                    <Label pointing color='red'>{this.props.inputError.passwordConfirm}</Label>
                                }
                            </Form.Field>
                            <Form.Field>
                                <label>Functionalities</label>
                                <Dropdown
                                    placeholder="Functionalities"
                                    name="functionalities"
                                    onChange={this.props.onChange}
                                    fluid
                                    multiple
                                    search
                                    selection
                                    options={this.props.functionalitiesOptions}
                                    error={!!(this.props.inputError && this.props.inputError.functionalities)}

                                />
                                {
                                    !!(this.props.inputError && this.props.inputError.functionalities) &&
                                    <Label pointing color='red'>{this.props.inputError.functionalities}</Label>
                                }
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="mini" onClick={() => this.props.closeModal()}>
                            Back
                        </Button>
                        <Button
                            color="blue"
                            size="mini"
                            loading={this.props.loadingBtn}
                            type="submit"
                            onClick={() => this.props.onSubmit()}
                        >
                            Create
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>
        )
    }
}

export default CreateUserModal
