import PropTypes from 'prop-types'
import React from "react";
import styled from "styled-components";
import {Header, Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

const LinkNotBlue = styled(Link)`
    color: inherit;
    &:hover {
    color: inherit;
    }
`
const DivHeader = styled.div`
    padding: 80px 0 45px 45px
    width: 100%;
    background-color: #F9FBFF
`

const HeaderCust = styled(Header)`
    font-weight: 900;
    font-size: 30px
    color: #021041 !important;
    text-align: left;
`

const HeaderP = styled.p`
    font-weight: 300;
    margin-top: 20px;
    font-size: 17px !important;
    color: #00000073;
    text-align: left;
`

const CtaButton = styled.div`
    position: absolute;
    right: 2rem;
    top: 6rem;
`

const BackOfficeHeader = ({ title, subtitle, cta, onClick, disabled, to  }) => {

        return (
            <DivHeader>
                <HeaderCust as="h2" attached="top" className="transactions-assignment">
                    {title}
                </HeaderCust>

                <HeaderP>
                   {subtitle}
                </HeaderP>
                    {
                            cta ?
                                <CtaButton>
                                        {
                                                to ?
                                                    <LinkNotBlue to={to}>
                                                            <Button secondary disabled={disabled}>{cta}</Button>
                                                    </LinkNotBlue>
                                                    :
                                                    <Button onClick={onClick} secondary disabled={disabled}>{cta}</Button>
                                        }
                                </CtaButton>

                                : ''
                    }

            </DivHeader>
        )
}
BackOfficeHeader.propTypes = {
        subtitle: PropTypes.string,
        title: PropTypes.string.isRequired,
        cta: PropTypes.string,
        disabled: PropTypes.bool,
        to: PropTypes.string
}

export default BackOfficeHeader

