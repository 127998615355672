import moment from 'moment'
import { CUSTOMER_ALL_STATS, CUSTOMER_DAILY_STATS, CLEAR_DAILY_STATS } from '../types'
import api from '../api'

export const customerStatsFetched = (customerStats, type) => ({
    type: type,
    payload: customerStats
})

export const clearDailyStats = () => async (dispatch) => {
    dispatch(customerStatsFetched(null, CLEAR_DAILY_STATS))
}

export const fetchAllStats = () => async (dispatch) => {
    let stats = null
    if (localStorage.getItem('customerStats') === null) {
        stats = await api.stats.fetchAllStats('sp,pr,r2,4t')
        localStorage.setItem('customerStats', JSON.stringify({ timestamp: moment().format('DD-HH-mm'), stats: stats.data }))
        stats = JSON.parse(localStorage.getItem('customerStats'))
    } else {
        stats = JSON.parse(localStorage.getItem('customerStats'))
        let time = JSON.stringify(moment())
        time = JSON.parse(time)
        const oldTime = Date.parse(stats.timestamp)
        time = (Date.parse(time))
        if ((oldTime + 1800000) < time) {
            stats = await api.stats.fetchAllStats('sp,pr,r2,4t,sq', '')
            localStorage.setItem('customerStats', JSON.stringify({ timestamp: moment().format('DD-HH-mm'), stats: stats.data }))
            stats = JSON.parse(localStorage.getItem('customerStats'))
        }
    }
    dispatch(customerStatsFetched(stats, CUSTOMER_ALL_STATS))
}
export const fetchDailyStats = (startDate, endDate, reset) => async (dispatch) => {
    let stats = null
    let startDateVar = startDate
    let endDateVar = endDate

    if (!startDate) {
        startDateVar = moment().subtract(1, 'month').format('YYYY-MM-DD')
    }
    if (!endDate) {
        endDateVar = moment().format('YYYY-MM-DD')
    }
    if (localStorage.getItem('dailyStats') === null || reset === true) {
        stats = await api.stats.fetchDailyStats('sp,pr,r2,4t,sq', startDateVar, endDateVar)
        localStorage.setItem('dailyStats', JSON.stringify({ timestamp: moment(), stats: stats.data }))
        stats = JSON.parse(localStorage.getItem('dailyStats'))
    } else {
        stats = JSON.parse(localStorage.getItem('dailyStats'))
        let time = JSON.stringify(moment())
        time = JSON.parse(time)
        const oldTime = Date.parse(stats.timestamp)
        time = (Date.parse(time))
        if ((oldTime + 1800000) < time) {
            stats = await api.stats.fetchAllStats('sp,pr,r2,4t,sq')
            localStorage.setItem('dailyStats', JSON.stringify({ timestamp: moment().format('DD-HH-mm'), stats: stats.data }))
            stats = JSON.parse(localStorage.getItem('dailyStats'))
        }
    }
    dispatch(customerStatsFetched(stats, CUSTOMER_DAILY_STATS))
}
