import React from 'react'

import { Statistic, Grid, Icon } from 'semantic-ui-react'

import PropTypes from 'prop-types'

import MediaQuery from 'react-responsive'

import './statsfigures.scss'

Statfigures.propTypes = {
    figure: PropTypes.any.isRequired,
    variation: PropTypes.number,
    label: PropTypes.string,
    horizontal: PropTypes.bool,
    icon: PropTypes.element
}

export default function Statfigures({
    figure,
    variation,
    label,
    horizontal,
    icon,
    subfigure,
    ...props
}) {
    const up = variation > 0

    return (
    <>
      <Grid columns="equal">
          <Grid.Column textAlign="center">
              <Statistic
                  size="small"
                  style={{ display: 'inline' }}
                  {...props}
                  horizontal={horizontal}
              >
                  <MediaQuery minWidth={993} maxWidth={1100}>
                      {matches => (matches ? (
                          <Statistic.Value
                              style={{ display: subfigure && 'inline' }}
                              {...props}
                              size="tiny"
                          >
                              {figure}
                          </Statistic.Value>
                      ) : (
                          <Statistic.Value
                              style={{ display: subfigure && 'inline' }}
                              {...props}
                          >
                              {figure}
                          </Statistic.Value>
                      ))}
                  </MediaQuery>
                  {subfigure && (
                      <div style={{ display: 'inline-block' }}>{subfigure}</div>
                  )}
                  <Statistic.Label>
                      {icon}
                      {label}
                  </Statistic.Label>
                  {variation && (
                      <Statistic.Label>
                          <Icon
                              className={up ? 'gly-rotate-45' : 'gly-rotate-minus45'}
                              name={up ? 'arrow up' : 'arrow down'}
                              size="big"
                              color={up ? 'green' : 'red'}
                          />

                          <div style={{ display: 'inline', color: up ? 'green' : 'red' }}>
                              {`${variation}%`}
                          </div>
                      </Statistic.Label>
                  )}
              </Statistic>
          </Grid.Column>
      </Grid>
    </>
    )
}
