import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { Menu, Icon } from 'semantic-ui-react'

import { logout } from './actions/auth'

import LoginPage from './components/pages/LoginPage'
import HomePage from './components/pages/HomePage'
import ForbiddenPage from './components/pages/ForbiddenPage'
import HubSetupPage from './components/pages/HubSetupPage'
import ManualAssociationPage from './components/pages/ManualAssociationPage'
import ManualDissociationPage from './components/pages/ManualDissociationPage'
import EntityManagementPage from './components/pages/EntityManagementPage'
import ReceiptsManagementPage from './components/pages/ReceiptsManagementPage'
import BackUserRoute from './components/routes/BackUserRoute'
import GuestUserRoutes from './components/routes/GuestUserRoutes'
import MainNavigation from './components/Navigation/MainNavigation/MainNavigation'
import DecryptCustomerPage from './components/pages/DecryptCustomerPage'
import CustomerSupportPage from './components/pages/CustomerSupportPage'
import HubCreationPage from './components/pages/HubCreationPage'
import HubManagementPage from './components/pages/HubManagementPage'
import HubUpdatePage from './components/pages/HubUpdatePage'
import UserManagementPage from './components/pages/UserManagementPage'
import StatBoardIndex from './components/StatisticBoard/StatBoardIndex'
import ChooseOffer from "./components/Offers/ChooseOffer"
import CustomerSupportManualAssociation from './components/pages/CustomerSupport'


// location for prevent conflict between react router and connect function
class App extends React.Component {
    static propTypes = {
        backUser: PropTypes.shape({
            accessToken: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired,
        logout: PropTypes.func.isRequired
    }
    componentWillUpdate() {
        clearInterval(this.timerID)
    }
    componentDidUpdate = () => {
        if (this.props.backUser.accessToken) {
            this.timerID = setInterval(() => this.checkTokenValidity(), 5000)
        }
    }
    checkTokenValidity = () => {
        const timeToExpire = jwtDecode(this.props.backUser.accessToken).exp
        if (timeToExpire && timeToExpire - new Date().getTime() / 1000 < 0) {
            this.forceUpdate()
            this.props.logout()
        }
    }
    render() {
        const { location, backUser } = this.props
        const url = location.pathname
        const backUserFunctionalities = backUser && backUser.accessToken ? jwtDecode(backUser.accessToken).functionalities.map(functionality => Object.keys(functionality)[0]) : []
        return ( // i use BackUserRoute as HOC to add permission access to the pages
            <section className={url === '/statistic-board' ? 'app-container statistic-board' : 'app-container'}>
                {localStorage.getItem('backuserJWT') && (
                    <div>
                        <Menu className={url === '/statistic-board' ? 'logout-menu statistic-board-logout-menu' : 'logout-menu'}>
                            <span className={url === '/statistic-board' ? 'backuser-email statistic-board-backuser-email' : 'backuser-email'}>{localStorage.getItem('email')}</span>
                            <Menu.Item
                                position="right"
                                name="logout"
                                title="logout"
                                onClick={() => this.props.logout()}
                            >
                                <Icon name="log out" size="large" className={url === '/statistic-board' ? 'statistic-board-logout' : ''} />
                            </Menu.Item>
                        </Menu>
                        <MainNavigation location={location} />
                    </div>
                ) }
                <main>
                    <GuestUserRoutes location={location} path={'/'} exact component={LoginPage} />
                    <BackUserRoute location={location} path={'/home'} exact component={HomePage} />
                    <BackUserRoute location={location} path={'/offers'} exact component={backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1 ? ChooseOffer : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/decrypt-customer'} exact component={backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1 ? DecryptCustomerPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/customer-search-support'} exact component={backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1 ? CustomerSupportPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/customerSupport/manualAssociation'} exact component={backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1 ? CustomerSupportManualAssociation : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/hub-setup-initial-transaction'} exact component={backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1 ? HubSetupPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/receipts-management'} exact component={backUserFunctionalities.indexOf('SUPPORT_LVL_1') > -1 ? ReceiptsManagementPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/manual-association'} exact component={backUserFunctionalities.indexOf('TRANSACTION_PINPOINT') > -1 ? ManualAssociationPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/manual-dissociation'} exact component={backUserFunctionalities.indexOf('TRANSACTION_PINPOINT') > -1 ? ManualDissociationPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/entity-management'} exact component={backUserFunctionalities.indexOf('STORE_RETAILER_MANAGEMENT') > -1 ? EntityManagementPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/user-management'} exact component={backUserFunctionalities.indexOf('ADMIN') > -1 ? UserManagementPage : ForbiddenPage} />
                    {/* // TODO : uncomment this section when statistic-board page fixed
                    <BackUserRoute location={location} path={'/statistic-board'} exact component={backUserFunctionalities.indexOf('STATISTIC_BOARD') > -1 ? StatBoardIndex : ForbiddenPage} /> */}
                    <BackUserRoute location={location} path={'/hub-creation'} exact component={backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1 ? HubCreationPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/hub-management'} exact component={backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1 ? HubManagementPage : ForbiddenPage} />
                    <BackUserRoute location={location} path={'/hub-update/:id'} exact component={backUserFunctionalities.indexOf('HUB_SETUP_INIT') > -1 ? (props)=>HubUpdatePage(props) : ForbiddenPage} />
                </main>
            </section>
        )
    }
}


const mapStateToProps = state => ({
    backUser: state.backUser,
    logout: state.logout
})

export default connect(mapStateToProps, {
    logout
})(App)
