import React from 'react'
import { Header } from 'semantic-ui-react'
import './StoreRetailerManagementPage.css'
import ReceiptsManagement from '../receiptsValidation'

const ReceiptManagementPage = () => (
    <section className="ui container support-page">
        <Header as="h2" attached="top" className="transactions-assignment" >
            Receipts Management
        </Header>
        <ReceiptsManagement />
    </section>
)

export default ReceiptManagementPage
