import React from 'react'
import { Button, Form, Transition, Header, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'


const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hasTransactionPatternMatching: PropTypes.bool.isRequired,
    patternMatchingTransactions: PropTypes.arrayOf(PropTypes.shape({
        accessToken: PropTypes.string
    })),
    isValidRegex: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    newPattern: PropTypes.string.isRequired,
    entity: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    testPattern: PropTypes.func.isRequired,
    addPattern: PropTypes.func.isRequired,
    displayMatchingTransactions: PropTypes.func.isRequired,
}
const AddPatternModal = ({
    isOpen,
    hasTransactionPatternMatching,
    displayMatchingTransactions,
    isValidRegex,
    hasErrors,
    newPattern,
    isLoading,
    patternMatchingTransactions,
    entity,
    entityId,
    handleClose,
    handleChange,
    testPattern,
    addPattern
}) => (
    <Transition visible={isOpen} animation="fade up" duration={350}>
        <Modal
            open={isOpen}
            size={hasTransactionPatternMatching ? 'large' : 'tiny'}
        >
            <Header content={'Add new pattern'} />

            <Modal.Content >
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            width={4}
                            size="small"
                            label="New pattern"
                            placeholder="insert your regular expression here"
                            value={newPattern}
                            name="newPattern"
                            error={!isValidRegex}
                            onChange={handleChange}
                        />
                        <Button
                            className="test-pattern-btn"
                            type={'button'}
                            size="mini"
                            loading={isLoading && !hasErrors}
                            disabled={!newPattern || !isValidRegex}
                            onClick={() => testPattern(newPattern)}
                        >
                            {
                                Object.keys(patternMatchingTransactions).length === 0 ? 'Test regex' : 'Re-test'
                            }
                        </Button>
                        { !isValidRegex ? <p className="invalid-regex">Invalid regex</p> : '' }
                    </Form.Group>
                </Form>
                {
                    Object.keys(patternMatchingTransactions).length > 0 ?
                        displayMatchingTransactions(patternMatchingTransactions)
                        : ''
                }
            </Modal.Content>
            <Modal.Actions>
                <Button size="mini" onClick={() => handleClose()}>
                        Back
                </Button>
                <Button
                    size="mini"
                    color={'blue'}
                    disabled={!newPattern || !isValidRegex || isLoading}
                    onClick={() => addPattern(entityId, newPattern, entity)}
                >
                        Validate
                </Button>
            </Modal.Actions>
        </Modal>
    </Transition>
)

AddPatternModal.propTypes = propTypes
AddPatternModal.defaultProps = { patternMatchingTransactions: undefined }

export default AddPatternModal
