import React, { Component } from 'react'
import 'react-dates/initialize'
import GiftCardFields from './Fields/GiftCardFields'
import HubFields from './Fields/HubFields'
import StoreFields from './Fields/StoreFields'

import './GiftCardForm.css'


let fieldValues = {
    cardNumber: null,
    hubName: null,
    hubId: null,
    storeId: null
}

export default class GiftCardForm extends Component {
    state = { step: 1 }
    nextStep = () => this.setState({ step: this.state.step + 1 })

    saveValues = (fieldValue) => {
        fieldValues = Object.assign({}, fieldValues, fieldValue)
        if (fieldValues.hubName && fieldValues.cardNumber && !localStorage.getItem('hubName') && !localStorage.getItem('cardNumber')) {
            localStorage.setItem('cardNumber', fieldValues.cardNumber)
            localStorage.setItem('hubName', fieldValues.hubName)
        }
    }


    showStep = () => {
        const { step } = this.state
        if (localStorage.getItem('hubId') && (localStorage.getItem('cardNumber'))) {
            return (
                <StoreFields
                    fieldValues={fieldValues}
                    nextStep={this.nextStep}
                    saveValues={this.saveValues}
                />
            )
        }


        switch (step) {
        case 1:
            return (
                <GiftCardFields
                    fieldValues={fieldValues}
                    nextStep={this.nextStep}
                    saveValues={this.saveValues}
                    fieldValue={fieldValues}
                />
            )
        case 2:
            return (
                <HubFields
                    fieldValues={fieldValues}
                    nextStep={this.nextStep}
                    saveValues={this.saveValues}
                />
            )
        case 3:
            return (
                <StoreFields
                    fieldValues={fieldValues}
                    nextStep={this.nextStep}
                    saveValues={this.saveValues}
                />
            )
        default: return true
        }
    }


    render() {
        return (
            <div className="hub-setup-form-container">
                {
                    localStorage.getItem('hubId') && localStorage.getItem('cardNumber') && (
                        <div className="card-hub-setup-info">
                            <p><span>Card number:</span> {localStorage.getItem('cardNumber').substring(1).replace('X')}</p>
                            <p><span>Mall:</span> {localStorage.getItem('hubName')}</p>
                        </div>
                    )
                }
                {
                    localStorage.getItem('hubId') && localStorage.getItem('cardNumber') ? <StoreFields
                        fieldValues={fieldValues}
                        nextStep={this.nextStep}
                        saveValues={this.saveValues}

                    /> : this.showStep()
                }
            </div>

        )
    }
}
