import React, { Component } from 'react'
import { Icon, Popup, Grid, Container, Loader } from 'semantic-ui-react'
import Statfigures from '../StoresStats/StoresFigures/FiguresForStats/FiguresForStats'
import api from '../../../../api'
import StoresShares from '../StoresStats/StoresShares/StoresShares'

class GlobalStatistics extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            avgPanier: 0,
            avgVisitors: 0,
            varPanier: 0,
            varVisitors: 0,
            shares: null
        }
    }

    async componentDidMount() {
        let avgPanier = 0
        let oldPanier = 0
        let avgVisitors = 0
        let oldVisitors = 0
        const stores = this.props.allStoresData
        for (const store_name in stores) {
            avgPanier += stores[store_name].amount.value
            oldPanier +=
        stores[store_name].amount.value *
        (1 + stores[store_name].amount.variation / 100)

            avgVisitors += stores[store_name].visits.value
            oldVisitors +=
        stores[store_name].visits.value *
        (1 + stores[store_name].visits.variation / 100)
        }
        this.setState({
            avgPanier,
            avgVisitors,
            varPanier: ((avgPanier - oldPanier) * 100) / oldPanier,
            varVisitors: ((avgVisitors - oldVisitors) * 100) / oldVisitors
        })
        await this.getShareData()
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            let avgPanier = 0
            let oldPanier = 0
            let avgVisitors = 0
            let oldVisitors = 0
            let nbStores = 1
            const stores = this.props.allStoresData
            if (stores) {
                nbStores = stores.length
            }
            for (const store_name in stores) {
                avgPanier += stores[store_name].amount.value
                oldPanier +=
          stores[store_name].amount.value *
          (1 + stores[store_name].amount.variation / 100)

                avgVisitors += stores[store_name].visits.value
                oldVisitors +=
          stores[store_name].visits.value *
          (1 + stores[store_name].visits.variation / 100)
            }
            this.setState({
                avgPanier: avgPanier / nbStores,
                avgVisitors,
                varPanier: ((avgPanier - oldPanier) * 100) / oldPanier,
                varVisitors: ((avgVisitors - oldVisitors) * 100) / oldVisitors
            })
            await this.getShareData()
        }
    }

    async getShareData() {
        return api.offers.get('get_share_store/', {
            params: {
                storeName: 'AllMall'
            }
        }).then((res) => {
            this.setState({
                shares: res.data
            })
        })
    }

    render() {
        return (
            <Grid>
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column width={10} className="avenir title" textAlign="center">
            STATISTIQUES GENERALES
                    </Grid.Column>
                    <Grid.Column className="avenir title" width={6} textAlign="center">
            CANNIBALISATION
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="middle" style={{ paddingTop: 0 }}>
                    <Grid.Column width={5}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="shopping basket" />}
                                    content="Le panier moyen se défnit comme étant la moyenne des achats des clients à cette boutique."
                                    size="tiny"
                                />
                            }
                            label={'Panier moyen'}
                            figure={`${Math.round(this.state.avgPanier)}€`}
                            variation={Math.round(this.state.varPanier)}
                        />
                    </Grid.Column>

                    <Grid.Column width={5}>
                        <Statfigures
                            icon={
                                <Popup
                                    trigger={<Icon name="users" />}
                                    content="Nombre de visiteurs en moyenne par mois dans la boutique."
                                    size="tiny"
                                />
                            }
                            label={'VISITES AVEC ACHAT'}
                            figure={this.state.avgVisitors}
                            variation={Math.round(this.state.varVisitors)}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {this.state.shares ? (
                            <StoresShares store_shares={this.state.shares} />
                        ) : (
                            <Container className="statloader">
                                <Loader active />
                            </Container>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default GlobalStatistics
