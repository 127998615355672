export default function circleSize(sizeOption, d) {
    if (d === undefined) {
        return 5
    }
    let result = 0
    if (sizeOption === 'avgNbCustomer') {
        result = Math.sqrt(d.avg_nb_customer) * 12
        if (result > 25) {
            result = 25
        }
    } else if (sizeOption === 'avgCa') {
        result = Math.sqrt(d.avg_ca)
        if (result > 25) {
            result = 25
        }
    } else if (sizeOption === 'avgNbTransaction') {
        result = Math.sqrt(d.avg_nb_transaction) * 7
        if (result > 25) {
            result = 25
        }
    }

    return result
}
