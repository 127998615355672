import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import Automatic from './IndividualOffers/Automatic'
import './Statistics/statistics.scss'
import Visualisation from './Visualisation/Visualisation'

class ChooseOffer extends Component {
    render() {
        const panes = [
            {
                menuItem: 'Offre segmentée',
                render: () => (
                    <Tab.Pane className="toptab" attached={false}>
                        <Visualisation />
                    </Tab.Pane>
                )
            },
            {
                menuItem: 'Offre individualisée',
                render: () => (
                    <Tab.Pane className="toptab" attached={false}>
                        <Automatic {...this.props} />
                    </Tab.Pane>
                )
            }
        ]

        return (
            <section className="ui container offer-page">
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </section>
        )
    }
}

export default ChooseOffer
