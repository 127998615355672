import React, { Component } from 'react'
import CustomerManualAssociation from './CustomerManualAssociation'
import CustomerManualAssociationTable from './CustomerManualAssociationTable'

export default class CustomerManualAssociationIndex extends Component {
    state = { step: 1 }

    nextStep = () => this.setState({ step: this.state.step + 1 })

    prevStep = () => this.setState({ step: this.state.step - 1 })

    showStep = () => {
        const { step } = this.state

        switch (step) {
        case 1:
            return (
                <CustomerManualAssociation
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                />
            )
        case 2:
            return (
                <CustomerManualAssociationTable
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                />
            )
        default: return true
        }
    }

    render() {
        return (
            <div>
                {this.showStep()}
            </div>
        )
    }
}
