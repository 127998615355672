// action types
import { SHOPPING_HUB_LIST_FETCHED, STORES_LIST_FETCHED, GIFT_CARD_TRANSACTIONS_FETCHED, GIFT_CARD_TRANSACTIONS_SENT, UNAFFECTED_TRANSACTIONS_FETCHED, TRANSACTIONS_ASSOCIATION_SENT } from '../types'
// api calls
import api from '../api'

// Action Creators
export const shoppingHubListFetched = shoppingHubsList => ({
    type: SHOPPING_HUB_LIST_FETCHED,
    shoppingHubsList
})


// Action Creators
export const storesListFetched = stores => ({
    type: STORES_LIST_FETCHED,
    stores
})


// Action Creators
export const giftCardTransactionsFetched = transactionsInfo => ({
    type: GIFT_CARD_TRANSACTIONS_FETCHED,
    transactionsInfo
})

// Action Creators
export const unaffectedTransactionsFetched = unaffectedTransactionsInfo => ({
    type: UNAFFECTED_TRANSACTIONS_FETCHED,
    unaffectedTransactionsInfo
})

// Action Creators
export const giftCardTransactionsSent = (result) => {
    return {
        type: GIFT_CARD_TRANSACTIONS_SENT,
        result
    }
}

// Action Creators
export const transactionAssociationSent = (result) => {
    return {
        type: TRANSACTIONS_ASSOCIATION_SENT,
        result
    }
}

export const fetchGiftCardTransactionsInfo = cardNumber => dispatch => api.shoppingHubs.fetchGiftCardTransactions(cardNumber).then(giftCardTransactionsInfo => dispatch(giftCardTransactionsFetched(giftCardTransactionsInfo)))

export const sendGiftCardTransactionInfo = (cardNumber, storeId, label, amount, dateTime) => dispatch => api.shoppingHubs.sendGiftCardTransaction(cardNumber, storeId, label, amount, dateTime).then(result => dispatch(giftCardTransactionsSent(result)))

export const fetchShoppingHubsList = () => dispatch => api.shoppingHubs.fetchShoppingHubsList().then(shoppingHubList => dispatch(shoppingHubListFetched(shoppingHubList)))

export const fetchStoresList = hubId => dispatch => api.shoppingHubs.fetchStoresList(hubId).then(stores => dispatch(storesListFetched(stores)))

export const fetchUnaffectedTransactionsInfo = shoppinghubId => dispatch => api.shoppingHubs.fetchUnaffectedTransactions(shoppinghubId).then(unaffectedTransactionsInfo => dispatch(unaffectedTransactionsFetched(unaffectedTransactionsInfo)))

export const sendTransactionAssociationInfo = (transactionId, storeId) => dispatch => api.shoppingHubs.sendTransactionAssociation(transactionId, storeId).then(result => dispatch(transactionAssociationSent(result)))
