import _ from 'lodash'
import React, { Component } from 'react'
import { Search } from 'semantic-ui-react'

class StoresSearchTool extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            isLoading: false,
            results: [],
            value: ''
        }
        this.handleResultSelect.bind(this)
    }

    componentDidMount() {
        this.props.event_emitter.on('graphdata', (data) => {
            this.setState({
                graphdata: _.map(data, 'id')
            })
        })
        this.props.event_emitter.on('inputValue', (value) => {
            if (!value) {
                value = ''
            }
            this.setState({ value })
        })
    }

  resetComponent = () =>
      this.setState({ isLoading: false, results: [], value: '' });

  handleResultSelect = (e, { result }) => {
      this.props.graph === true
          ? this.setState({ value: result.title }, () => {
              this.props.event_emitter.emit('nodeClick', this.state.value)
              this.props.selected(this.state.value)
          })
          : this.setState({ value: result.title }, () => {
              this.props.event_emitter.emit('selectShop', this.state.value)
              this.setState({ value: '' })
          })
  };

  handleSearchChange = (e, { value }) => {
      this.setState({ isLoading: true, value })

      setTimeout(() => {
          if (this.state.value.length < 1) return this.resetComponent()

          const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
          const isMatch = result => re.test(result)

          this.setState({
              isLoading: false,
              // mise en forme obligatoire avec la property title pour le module Search de semantic...
              results: _.filter(this.state.graphdata, isMatch).map(e => ({ title: e }))
          })
      }, 300)
  };

  render() {
      const { isLoading, value, results } = this.state

      const { selected, graph, ...rest } = this.props

      return (
          <Search
              onFocus={() => {
                  this.setState({ value: '' })
              }}
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true
              })}
              results={results}
              value={value}
              {...rest}
          />
      )
  }
}

export default StoresSearchTool
