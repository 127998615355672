import React, { Component } from 'react'
import connect from 'react-redux/es/connect/connect'
import { Header, Segment, Loader } from 'semantic-ui-react'
import './StatBoard.css'
import {
    fetchAllStats
} from '../../actions/stats'

class StatsRegistered extends Component {
    state = { errors: false }

    componentDidMount = async () => {
        try {
            await this.props.fetchAllStats
        } catch (e) {
            this.setState({ errors: true })
        }
    }

    countTotal = () => {
        let total = 0
        for (let i = 0; i < this.props.customerAllStats.stats.totalCustomers.length; i++) {
            const nb = parseInt(this.props.customerAllStats.stats.registeredCustomers[i].count, 10)
            total += nb
        }
        return total
    }

    render() {
        let name
        let count
        let total
        let totalHeader
        let loading

        if (this.props.customerAllStats && this.props.customerAllStats.stats) {
            name = (
                this.props.customerAllStats.stats.registeredCustomers.map(customerAllStats => (
                    <Segment className="segment">{customerAllStats.shortcut.toUpperCase()}</Segment>
                ))
            )

            count = (
                this.props.customerAllStats.stats.registeredCustomers.map(customerAllStats => (
                    <Segment className="segment">{customerAllStats.count}</Segment>
                ))
            )

            totalHeader = (
                <Segment className="segment">{'Total'}</Segment>
            )

            total = (
                <Segment className="segment">{this.countTotal()}</Segment>
            )
        } else if (this.state.errors === true) {
            count = (
                <Segment className="segment">{'api error'}</Segment>
            )
        } else {
            loading = (
                <div className="loading">
                    <Loader active />
                </div>
            )
        }
        return (
            <div className="div-customer">
                <Segment.Group>
                    <Header className="customerReg-header">Customers with payment card</Header>
                    <Segment.Group horizontal className="data">
                        <Segment.Group>
                            {name}
                            {loading}
                            {totalHeader}
                        </Segment.Group>
                        <Segment.Group>
                            {count}
                            {total}
                        </Segment.Group>
                    </Segment.Group>
                </Segment.Group>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerAllStats: state.customerStats.customerAllStats,
    backUser: state.backUser
})

export default connect(mapStateToProps, {
    fetchAllStats
})(StatsRegistered)
